import { EnvironmentEnum } from "./environment.enum";
import { Environment } from "./environment.interface";

export const environment: Environment = {
	isProd: false,
	name: EnvironmentEnum.Staging,
	api_key: "8C4qNeDvtbZT7hGnaUJiWgYFr0o1VXxLBm2ekAzKS3Rj6dqIpOHPylfs9cw5MuE",
	ms_hotels_base_url: 'https://trripi-stg.com/hotels',
	ms_flights_base_url: "https://trripi-stg.com/flights",
	ms_events_base_url: "https://trripi-stg.com/events",
	ms_orders_base_url: "https://trripi-stg.com/orders",
	ms_admin_base_url: "https://trripi-stg.com/admin",
	ms_notifications_base_url: "https://trripi-stg.com/notifications",
	emailjs_public_key: "6k0-HiiHS8iMOxiOO",
    emailjs_service_id: "service_ropig58",
    ms_admin_coupon_url:"https://trripi-stg.com/admin/coupon"
};

