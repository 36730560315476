import { auditTime, combineLatest, map, Observable } from "rxjs";
import { IFiltersMetadata } from "src/app/libs/data-access/store/shared";
import { SortValueEnum } from "src/app/libs/ui/components/top-bar/enums/top-bar.enum";

export type FlightsStreams = [
  Observable<any[]>, // should be Flights
  Observable<any[]>,
  Observable<string>,
  Observable<IFiltersMetadata>,
  Observable<any>,
  Observable<SortValueEnum>,
  Observable<number>,
  Observable<number>,
  Observable<any>,
  Observable<any>,
  Observable<boolean>,
  Observable<boolean>,
  Observable<Error>
];

export interface FlightsViewModel {
  flights: any[];
  active: any[];
  offerRequestId: string;
  filtersMetadata: IFiltersMetadata;
  requestMetadata: any;
  sort: SortValueEnum;
  totalRecordsLeft: number;
  totalRecords: number;
  selectedQuickFilter: any;
  loadMoreActions: any;
  loading: boolean;
  loaded: boolean;
  error: Error;
}

export function makeFlightsViewModel(
  streams: FlightsStreams
): Observable<Readonly<FlightsViewModel>> {
  const toViewModel = ([
    flights,
    active,
    offerRequestId,
    filtersMetadata,
    requestMetadata,
    sort,
    totalRecordsLeft,
    totalRecords,
    selectedQuickFilter,
    loadMoreActions,
    loading,
    loaded,
    error,
  ]: [
    any,
    any,
    string,
    IFiltersMetadata,
    any,
    SortValueEnum,
    number,
    number,
    any,
    any,
    boolean,
    boolean,
    Error
  ]): FlightsViewModel => {
    return {
      flights,
      active,
      offerRequestId,
      filtersMetadata,
      requestMetadata,
      sort,
      totalRecordsLeft,
      totalRecords,
      selectedQuickFilter,
      loadMoreActions,
      loading,
      loaded,
      error,
    };
  };

  return combineLatest(streams).pipe(auditTime(0), map(toViewModel));
}
