import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { DashboardFacade } from '../data-access/services/dashboard.facade';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'tic-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent {
  toggle: boolean = false;
  renderComp: any = 'all-trip';
  @ViewChild('componentContainer', { read: ViewContainerRef }) componentContainer: ViewContainerRef;
  searchString: string = '';
  
  constructor(private dashboardFacade: DashboardFacade,private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.setTab();
  }

  setTab() {
    this.route.queryParams.subscribe(params => {
      const paramValue = params['tab'];
      if(paramValue) {
        this.renderComp = paramValue;
      }
    });
  }

  toggleSidebar(event: any) {
    this.toggle = event;
  }

  selectedMenu(event: any) {
    this.renderComp = event;
  }

  selectedOrder(event: any) {
    this.renderComp = event;
  }

  searchInOrders(filter:string){
    this.searchString=filter;
  }
}
