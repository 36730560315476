import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { DashboardFacade } from '../../../data-access/services/dashboard.facade';
import * as Highcharts from 'highcharts';
import { Subject, takeUntil } from 'rxjs';
import { ViewFilter } from '../../../data-access/model/filters';

@Component({
  selector: 'tic-hotel-detail',
  templateUrl: './hotel-detail.component.html',
  styleUrls: ['./hotel-detail.component.scss']
})
export class HotelDetailComponent {
  requestQueryHotel: any;
  requestQueryHotelIncome: any;
  requestQueryHotelOutcome: any;
  requestQueryHotelProfit:any;
  hotelFilter: any;
  incomeFilter: any;
  outcomeFilter: any;
  profitFilter: any;
  queryChart: string = 'column';
  incomeChart: string = 'column';
  outcomeChart: string = 'column';
  profitChart: string = 'column';
  statusTitle: string = 'Status';
  status = [
    { value: 'none', viewValue: 'None' },
    { value: 'PENDING', viewValue: 'PENDING' },
    { value: 'READY', viewValue: 'READY' },
    { value: 'IN_PROGRESS', viewValue: 'IN PROGRESS' },
    { value: 'COMPLETED ', viewValue: 'COMPLETED ' },
    { value: 'CANCELLED', viewValue: 'CANCELLED' },
  ];
  priceTitle: string = 'Price';
  price = [
    { value: 'originPrice', viewValue: 'Origin Price' },
    { value: 'ourPrice', viewValue: 'Our Price' },
    { value: 'profit', viewValue: 'Profit' },
  ];

  colors = ["#4B49AC", "#98BDFF"];
  colors1 = ["#4B49AC", "#FFC100"];
  colors2 = ["#248AFD"];
  colors3 = ["#FF73A6"];

  Highcharts: typeof Highcharts = Highcharts;
  private destroy$ = new Subject();
  public hotelsChartOptions: Highcharts.Options;
  public incomeChartOptions: Highcharts.Options;
  public outcomeChartOptions: Highcharts.Options;
  public profitChartOptions: Highcharts.Options;
  showSaveViewPopup: boolean=false;
  hotelsView: any;
  Info: any;
  showValidation: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    public dashboardFacade: DashboardFacade
  ) { }

  ngOnInit() {
    this.setInitialState();
  }

  setInitialState() {
    this.dashboardFacade.loadPopularHotels({});
    this.dashboardFacade.loadHotelsIncome({});
    this.dashboardFacade.loadHotelsOutcome({});
    this.dashboardFacade.loadHotelsProfit({});

    this.toggleChart(this.queryChart, "hotels");
    this.toggleChart(this.incomeChart, "income");
    this.toggleChart(this.outcomeChart, "outcome");
    this.toggleChart(this.profitChart, "profit");
    let user = localStorage.getItem('userId');
    this.dashboardFacade.getHotelsView({username: user});
    this.dashboardFacade.HotelsView$.pipe(takeUntil(this.destroy$)).subscribe((view) => {
      this.hotelsView = view;
    }); 
  }

  filterByRange(event: any,type: string) {
    if( type === 'hotels') {
      if( event != 'none') {
        const obj = {
          fromDate: event.startDate,
          toDate: event.endDate 
        }
        this.requestQueryHotel = {
          ...this.requestQueryHotel,
          ...obj
        }
      } else {
        const { fromDate, toDate, ...rest } = this.requestQueryHotel;
        this.requestQueryHotel = rest;
      }
      this.dashboardFacade.loadPopularHotels(this.requestQueryHotel);
    } 
    else if( type === 'income' ) {
      if( event != 'none') {
        const obj = {
          fromDate: event.startDate,
          toDate: event.endDate 
        }
        this.requestQueryHotelIncome = {
          ...this.requestQueryHotelIncome,
          ...obj
        }
      } else {
        const { fromDate, toDate, ...rest } = this.requestQueryHotelIncome;
        this.requestQueryHotelIncome = rest;
      }
      this.dashboardFacade.loadHotelsIncome(this.requestQueryHotelIncome);
    } 
    else if( type === 'outcome' ) {
      if( event != 'none') {
        const obj = {
          fromDate: event.startDate,
          toDate: event.endDate 
        }
        this.requestQueryHotelOutcome = {
          ...this.requestQueryHotelOutcome,
          ...obj
        }
      } else {
        const { fromDate, toDate, ...rest } = this.requestQueryHotelOutcome;
        this.requestQueryHotelOutcome = rest;
      }
      this.dashboardFacade.loadHotelsOutcome(this.requestQueryHotelOutcome);
    }
    else if( type === 'profit' ) {
      if( event != 'none') {
        const obj = {
          fromDate: event.startDate,
          toDate: event.endDate 
        }
        this.requestQueryHotelProfit = {
          ...this.requestQueryHotelProfit,
          ...obj
        }
      } else {
        const { fromDate, toDate, ...rest } = this.requestQueryHotelProfit;
        this.requestQueryHotelProfit = rest;
      }
      this.dashboardFacade.loadHotelsProfit(this.requestQueryHotelProfit);
    }
  }

  dataFilter(event: any,type: string,dropdownType: string) {
    if( type === 'hotels') {
      if( dropdownType === 'status' ) {
        if( event != 'none' ) {
          this.hotelFilter = {
            ...this.hotelFilter,
            status: event
          }
        } else {
          const { status, ...rest } = this.hotelFilter;
          this.hotelFilter = rest;
        }
      } else if( dropdownType === 'price' ) {
        if( event.option != 'none' ) {
          if( event.option ) {
            this.hotelFilter = {
              ...this.hotelFilter,
              priceType: event.option,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          } else {
            this.hotelFilter = {
              ...this.hotelFilter,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          }
        } else {
          const { priceType, minPrice, maxPrice, ...rest } = this.hotelFilter;
          this.hotelFilter = rest;
        }
      }
      this.requestQueryHotel = {
        ...this.requestQueryHotel,
        filters: this.hotelFilter
      }
      this.dashboardFacade.loadPopularHotels(this.requestQueryHotel);
    } else if( type === 'income' ) {
        if( dropdownType === 'status' ) {
          if( event != 'none' ) {
            this.incomeFilter = {
              ...this.incomeFilter,
              status: event
            }
          } else {
            const { status, ...rest } = this.incomeFilter;
            this.incomeFilter = rest;
          }
        } else if( dropdownType === 'price' ) {
          if( event.option != 'none' ) {
            if( event.option ) {
              this.incomeFilter = {
                ...this.incomeFilter,
                priceType: event.option,
                minPrice: event.minPrice,
                maxPrice: event.maxPrice
              }
            } else {
              this.incomeFilter = {
                ...this.incomeFilter,
                minPrice: event.minPrice,
                maxPrice: event.maxPrice
              }
            }
          } else {
            const { priceType, minPrice, maxPrice, ...rest } = this.incomeFilter;
            this.incomeFilter = rest;
          }
        }
        this.requestQueryHotelIncome = {
          ...this.requestQueryHotelIncome,
          filters: this.incomeFilter
        }
        this.dashboardFacade.loadHotelsIncome(this.requestQueryHotelIncome);
    } else if( type === 'outcome' ) {
      if( dropdownType === 'status' ) {
        if( event != 'none' ) {
          this.outcomeFilter = {
            ...this.outcomeFilter,
            status: event
          }
        } else {
          const { status, ...rest } = this.outcomeFilter;
          this.outcomeFilter = rest;
        }
      } else if( dropdownType === 'price' ) {
        if( event.option != 'none' ) {
          if( event.option ) {
            this.outcomeFilter = {
              ...this.outcomeFilter,
              priceType: event.option,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          } else {
            this.outcomeFilter = {
              ...this.outcomeFilter,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          }
        } else {
          const { priceType, minPrice, maxPrice, ...rest } = this.outcomeFilter;
          this.outcomeFilter = rest;
        }
      }
      this.requestQueryHotelOutcome = {
        ...this.requestQueryHotelOutcome,
        filters: this.outcomeFilter
      }
      this.dashboardFacade.loadHotelsOutcome(this.requestQueryHotelOutcome);
    } else if( type === 'profit' ) {
      if( dropdownType === 'status' ) {
        if( event != 'none' ) {
          this.profitFilter = {
            ...this.profitFilter,
            status: event
          }
        } else {
          const { status, ...rest } = this.profitFilter;
          this.profitFilter = rest;
        }
      } else if( dropdownType === 'price' ) {
        if( event.option != 'none' ) {
          if( event.option ) {
            this.profitFilter = {
              ...this.profitFilter,
              priceType: event.option,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          } else {
            this.profitFilter = {
              ...this.profitFilter,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          }
        } else {
          const { priceType, minPrice, maxPrice, ...rest } = this.profitFilter;
          this.profitFilter = rest;
        }
      }
      this.requestQueryHotelProfit = {
        ...this.requestQueryHotelProfit,
        filters: this.profitFilter
      }
      this.dashboardFacade.loadHotelsProfit(this.requestQueryHotelProfit);
    } 
  } 

  toggleChart(event: any, type: string) {
    if (type === "hotels") {
      this.dashboardFacade.popularHotelsInfo$
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (data) {
            this.queryChart = event;
            const hotels = Object.keys(data);
            const seriesData = hotels.map((hotel) => {
              const orderIds = data[hotel].map((item) => item.orderId);
              return {
                name: hotel || 'Unknown', // Use a meaningful name for each category
                y: orderIds.length,
                orderIds: orderIds, // Store all order IDs for tooltip
              };
            });

            this.hotelsChartOptions = {
              series: [
                {
                  type: event, // Use 'column' type for a column chart
                  data: seriesData,
                  showInLegend: true,
                  colorByPoint: true,
                  colors: this.colors,
                },
              ],
              colors: this.colors,
              title: null,
              xAxis: {
                visible: true,
                type: "category",
                categories: hotels.map((hotel) => hotel || 'Unknown'), // Display as 'Package 2', 'Package 3', etc.
                title: {
                  text: "Hotels",
                },
              },
              yAxis: {
                visible: true,
                title: {
                  text: "Orders Count",
                },
              },
              legend: {
                layout: "vertical",
                align: "right",
                verticalAlign: "middle",
                borderWidth: 0,
              },
              credits: {
                enabled: false,
              },
            };
          }
        });
    } else if (type === "income") {
      this.dashboardFacade.hotelsIncomeInfo$
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (data) {
            this.incomeChart = event;
            const categories = Object.keys(data);
            const seriesData = categories.map((category) => {
              const income = data[category].income;
              return {
                name: category || 'Unknown', // Use a meaningful name for each category
                y: income,
                orderIds: income, // Store all order IDs for tooltip
              };
            });

            this.incomeChartOptions = {
              series: [
                {
                  type: event, // Use 'column' type for a column chart
                  data: seriesData,
                  colorByPoint: true,
            colors: this.colors1,
                },
              ],
              colors: this.colors1,
              title: null,
              xAxis: {
                visible: true,
                type: "category",
                categories: categories.map((cat) => cat || 'Unknown'), // Display as 'Package 2', 'Package 3', etc.
                title: {
                  text: "Date",
                },
              },
              yAxis: {
                visible: true,
                title: {
                  text: "Income",
                },labels: {
                  formatter: function () {
                      return '$' + this.value; // Format yAxis labels with $ sign
                  }
              }
              },
              legend: {
                layout: "vertical",
                align: "right",
                verticalAlign: "middle",
                borderWidth: 0,
              },
              credits: {
                enabled: false,
              },
            };
          }
        });
    } else if (type === "outcome") {
      this.dashboardFacade.hotelsOutcomeInfo$
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (data) {
            this.outcomeChart = event;
            const categories = Object.keys(data);
            const seriesData = categories.map((category) => {
              const outcome = data[category].outcome;
              return {
                name: category || 'Unknown', // Use a meaningful name for each category
                y: outcome,
                orderIds: outcome, // Store all order IDs for tooltip
              };
            });

            this.outcomeChartOptions = {
              series: [
                {
                  type: event, // Use 'column' type for a column chart
                  data: seriesData,
                  colors: this.colors2,
                },
              ],
              colors: this.colors2,
              title: null,
              xAxis: {
                visible: true,
                type: "category",
                categories: categories.map((cat) => cat || 'Unknown'), // Display as 'Package 2', 'Package 3', etc.
                title: {
                  text: "Date",
                },
              },
              yAxis: {
                visible: true,
                title: {
                  text: "Outcome",
                },labels: {
                  formatter: function () {
                      return '$' + this.value; // Format yAxis labels with $ sign
                  }
              }
              },
              legend: {
                layout: "vertical",
                align: "right",
                verticalAlign: "middle",
                borderWidth: 0,
              },
              credits: {
                enabled: false,
              },
            };
          }
        });
    } else if (type === "profit") {
      this.dashboardFacade.hotelsProfitInfo$
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (data) {
            this.profitChart = event;
            const categories = Object.keys(data);
            const seriesData = categories.map((category) => {
              const profit = data[category][0].profit;
              return {
                name: category || 'Unknown', // Use a meaningful name for each category
                y: profit,
                orderIds: profit, // Store all order IDs for tooltip
              };
            });

            this.profitChartOptions = {
              series: [
                {
                  type: event, // Use 'column' type for a column chart
                  data: seriesData,
                },
              ],
              colors: this.colors3,
              title: null,
              xAxis: {
                visible: true,
                type: "category",
                categories: categories.map((cat) => cat || 'Unknown'), // Display as 'Package 2', 'Package 3', etc.
                title: {
                  text: "Date",
                },
              },
              yAxis: {
                visible: true,
                title: {
                  text: "Profit",
                },labels: {
                  formatter: function () {
                      return '$' + this.value; // Format yAxis labels with $ sign
                  }
              }
              },
              legend: {
                layout: "vertical",
                align: "right",
                verticalAlign: "middle",
                borderWidth: 0,
              },
              credits: {
                enabled: false,
              },
            };
          }
        });
    }
  }

  saveView(event:any) {
    let name =event.viewName;   
    let username=localStorage.getItem('userId');
    const hotelFilters = {
      requestQueryHotel: this.requestQueryHotel,
      queryChart: this.queryChart,
      requestQueryHotelIncome: this.requestQueryHotelIncome,
      incomeChart: this.incomeChart,
      requestQueryHotelOutcome: this.requestQueryHotelOutcome,
      outcomeChart: this.outcomeChart,
      requestQueryHotelProfit: this.requestQueryHotelProfit,
      profitChart: this.profitChart
    }
    let data: ViewFilter = {
      username:username,
      view:'hotel',
      singleView: {
        name: name,
        param: this.objectToBase64(hotelFilters)
       }
    }
    this.dashboardFacade.saveView(data);
     this.dashboardFacade.SaveViewInfo$.pipe(takeUntil(this.destroy$)).subscribe((info) => {
      this.Info = info;
    });
    this.showValidation = true;
    setTimeout(() => {
      this.showValidation = false;
      this.dashboardFacade.getHotelsView({username: username});
    }, 3000);
    this.showSaveViewPopup = false;   
  }
  
  objectToBase64(obj: any): string {
    const jsonStr = JSON.stringify(obj);
    return btoa(jsonStr);
  }
  

  isObjectNotEmpty(obj: any): boolean {
    return obj && Object.keys(obj).length > 0;
  }

  base64ToObject(base64Str: string): any {
    const jsonStr = atob(base64Str);
    return JSON.parse(jsonStr);
  }

  onSelectionChange(event: any) {
    var hotelFilter;
    if( event.value ) {
      hotelFilter = this.base64ToObject(event.value);
    } else {
      hotelFilter = {};
    }
    if( hotelFilter && hotelFilter.requestQueryHotel ) {
      this.requestQueryHotel = hotelFilter.requestQueryHotel;
      this.dashboardFacade.loadPopularHotels(this.requestQueryHotel);
      if( hotelFilter.requestQueryHotel.filters ) {
        this.queryChart = hotelFilter.requestQueryHotel.filters;
      }
    } else {
      this.dashboardFacade.loadPopularHotels({});
    }
    if( hotelFilter && hotelFilter.requestQueryHotelIncome ) {
      this.requestQueryHotelIncome = hotelFilter.requestQueryHotelIncome;
      this.dashboardFacade.loadHotelsIncome(this.requestQueryHotelIncome);
      if( hotelFilter.requestQueryHotelIncome.filters ) {
        this.incomeFilter = hotelFilter.requestQueryHotelIncome.filters;
      }
    } else {
      this.dashboardFacade.loadHotelsIncome({});
    }
    if( hotelFilter && hotelFilter.requestQueryHotelOutcome ) {
      this.requestQueryHotelOutcome = hotelFilter.requestQueryHotelOutcome;
      this.dashboardFacade.loadHotelsOutcome(this.requestQueryHotelOutcome);
      if( hotelFilter.requestQueryHotelOutcome.filters ) {
        this.outcomeFilter = hotelFilter.requestQueryHotelOutcome.filters;
      }
    } else {
      this.dashboardFacade.loadHotelsOutcome({});
    }
    if( hotelFilter && hotelFilter.requestQueryHotelProfit ) {
      this.requestQueryHotelProfit = hotelFilter.requestQueryHotelProfit;
      this.dashboardFacade.loadHotelsProfit(this.requestQueryHotelProfit);
      if( hotelFilter.requestQueryHotelProfit.filters ) {
        this.profitFilter = hotelFilter.requestQueryHotelProfit.filters;
      }
    } else {
      this.dashboardFacade.loadHotelsProfit({});
    }
    if( hotelFilter && hotelFilter.queryChart ) {
      this.queryChart = hotelFilter.queryChart;
    } else {
      this.queryChart = 'column';
    }
    if( hotelFilter && hotelFilter.incomeChart ) {
      this.incomeChart = hotelFilter.incomeChart;
    } else {
      this.incomeChart = 'column';
    }
    if( hotelFilter && hotelFilter.outcomeChart ) {
      this.outcomeChart = hotelFilter.outcomeChart;
    } else {
      this.outcomeChart = 'column';
    }
    if( hotelFilter && hotelFilter.profitChart ) {
      this.profitChart = hotelFilter.profitChart;
    } else {
      this.profitChart = 'column';
    }
    this.toggleChart(this.queryChart, "hotels");
    this.toggleChart(this.incomeChart, "income");
    this.toggleChart(this.outcomeChart, "outcome");
    this.toggleChart(this.profitChart, "profit");
  }

  openSaveView(){
    this.showSaveViewPopup = true;
  }
  
  closeViewPopup() {
    this.showSaveViewPopup = false;
  }
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
