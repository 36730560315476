<div class="date-selector">
    <span>{{'dashboard.filters.dateFilter.label' | i18n }}</span>
    <div *ngIf="selectedOption != 'custom'">
        <mat-form-field>
            <mat-select [(value)]="selectedOption" (selectionChange)="optionSelected(selectedOption)">
                <mat-option *ngFor="let option of dateOptions" [value]="option.value">{{ option.viewValue | i18n}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="selectedOption === 'custom'" class="date-calendar">
        <mat-form-field class="text-field" appearance="fill">
            <div class="customAction">
            <mat-datepicker-toggle matIconSuffix [for]="picker" class="btnPicker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <button mat-icon-button class="btnBack" (click)="resetInput()"> <mat-icon  class="forward-arrow-icon">arrow_forward</mat-icon></button>
            </div>
            <mat-date-range-input class="dateRange" [rangePicker]="picker" >
                <input matStartDate formControlName="startDate" placeholder="{{'dashboard.filters.dateFilter.startDate' | i18n}}" #startDateInput>
                <input matEndDate formControlName="endDate" placeholder="{{'dashboard.filters.dateFilter.endDate' | i18n}}" #endDateInput (dateChange)="onDateRangeChange(startDateInput,endDateInput)">
            </mat-date-range-input>
        </mat-form-field>
        <p class="text-danger" *ngIf="!validRange">End date must be greater than start date</p>
    </div>
</div>