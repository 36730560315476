<h3 class="font-weight-bold">{{'dashboard.orderViewPage.title' | i18n}}</h3>
<div class="text-end saveView">
    <mat-form-field>
        <mat-select placeholder="{{'dashboard.orderDetailPage.selectView' | i18n}}" (selectionChange)="onSelectionChange($event)">
            <mat-option [value]="null">None</mat-option>
            <mat-option *ngFor="let data of OrdersView.orderViews" [value]="data.param">{{data.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <tic-button class="blue" buttonText="dashboard.buttons.saveView" (btnClick)="openSaveView()"></tic-button>
</div>
<div *ngIf="showValidation">
    <div *ngIf="Info && Info.error && Info.error===true" class="alert alert-danger d-flex align-items-center" role="alert">
        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
        <div>
        {{Info.message}}
        </div>
    </div>    
    <div *ngIf="Info && !Info.error && Info.error===false" class="alert alert-success d-flex align-items-center" role="alert">
        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#exclamation-triangle-fill"/></svg>
        <div>
        {{Info.message}}
        </div>
    </div>
</div>    
<div class="order-view">
    <div class="container-fluid">
        <div class="row rw-1">
            <div class="col-lg-6 col-style">
                <div class="header">
                    <h6>{{'dashboard.orderViewPage.packageType' | i18n}}</h6>
                    <div class="query-opt">
                        <div *ngIf="requestQueryPackage && requestQueryPackage.fromDate && requestQueryPackage.toDate; else datePackage">
                            <tic-date-selector [selectedFrom]="requestQueryPackage.fromDate" [selectedTo]="requestQueryPackage.toDate" (range)="filterByRange($event,'package')" ></tic-date-selector>
                        </div>
                        <ng-template #datePackage>
                            <tic-date-selector (range)="filterByRange($event,'package')" ></tic-date-selector>
                        </ng-template>
                        <tic-chart-type [selected]="packageChart" (chartType)="toggleChart($event,'package')"></tic-chart-type>
                    </div>
                </div>
                <div class="query-opt">
                    <div *ngIf="requestQueryPackage && requestQueryPackage.filters && requestQueryPackage.filters.status; else noStatus">
                        <tic-filters-dropdown [selected]="requestQueryPackage.filters.status"(option)="dataFilter($event,'package','status')" ></tic-filters-dropdown>
                    </div>
                    <ng-template #noStatus>
                        <tic-filters-dropdown  (option)="dataFilter($event,'package','status')" ></tic-filters-dropdown>
                    </ng-template>
                    <div *ngIf="requestQueryPackage && requestQueryPackage.filters && requestQueryPackage.filters.priceType && requestQueryPackage.filters.minPrice && requestQueryPackage.filters.maxPrice; else pricePackage">
                        <tic-price-selector [selected]="requestQueryPackage.filters.priceType" [min]="requestQueryPackage.filters.minPrice" [max]="requestQueryPackage.filters.maxPrice" (range)="dataFilter($event,'package','price')" ></tic-price-selector>
                    </div>
                    <ng-template #pricePackage>
                        <tic-price-selector (range)="dataFilter($event,'package','price')" ></tic-price-selector>
                    </ng-template>
                </div>
                <div *ngIf="(dashboardFacade.packagesInfo$ | async)?.length > 0 as packagesInfoLoaded">
                    <highcharts-chart *ngIf="packagesInfoLoaded" [Highcharts]="Highcharts" [options]="chartOptions"></highcharts-chart>
                </div>
                
            </div>
            <div class="col-lg-6 col-style">
                <div class="header">
                    <h6>{{'dashboard.orderViewPage.noOfReservation' | i18n}}</h6>
                    <div class="query-opt">
                        <div *ngIf="requestQueryNoOfPeoples && requestQueryNoOfPeoples.fromDate && requestQueryNoOfPeoples.toDate; else datePeoples">
                            <tic-date-selector [selectedFrom]="requestQueryNoOfPeoples.fromDate" [selectedTo]="requestQueryNoOfPeoples.toDate" (range)="filterByRange($event,'noOfPeoples')" ></tic-date-selector>
                        </div>
                        <ng-template #datePeoples>
                            <tic-date-selector (range)="filterByRange($event,'noOfPeoples')" ></tic-date-selector>
                        </ng-template>
                        <tic-chart-type [selected]="noOfPeoplesChart" (chartType)="toggleChart($event,'noOfPeoples')"></tic-chart-type>
                    </div>
                </div>
                <div class="query-opt">
                    <div *ngIf="requestQueryNoOfPeoples && requestQueryNoOfPeoples.filters && requestQueryNoOfPeoples.filters.status; else noStatusPeoples">
                        <tic-filters-dropdown [selected]="requestQueryNoOfPeoples.filters.status"   (option)="dataFilter($event,'noOfPeoples','status')" ></tic-filters-dropdown>
                    </div>
                    <ng-template #noStatusPeoples>
                        <tic-filters-dropdown  (option)="dataFilter($event,'noOfPeoples','status')" ></tic-filters-dropdown>
                    </ng-template>
                    <div *ngIf="requestQueryNoOfPeoples && requestQueryNoOfPeoples.filters && requestQueryNoOfPeoples.filters.priceType && requestQueryNoOfPeoples.filters.minPrice && requestQueryNoOfPeoples.filters.maxPrice; else pricePeoples">
                        <tic-price-selector [selected]="requestQueryNoOfPeoples.filters.priceType" [min]="requestQueryNoOfPeoples.filters.minPrice" [max]="requestQueryNoOfPeoples.filters.maxPrice" (range)="dataFilter($event,'noOfPeoples','price')" ></tic-price-selector>
                    </div>
                    <ng-template #pricePeoples>
                        <tic-price-selector (range)="dataFilter($event,'noOfPeoples','price')" ></tic-price-selector>
                    </ng-template>
                </div>
                <div *ngIf="(dashboardFacade.personsInfo$ | async) as personsInfo">
                    <highcharts-chart *ngIf="personsInfo" [Highcharts]="Highcharts" [options]="chartOptions1"></highcharts-chart>
                </div>
            </div>
        </div>
        <div class="row rw-2">
            <div class="col-lg-12 col-style">
                <div class="header">
                    <h6>{{'dashboard.orderViewPage.orders' | i18n}}</h6>
                    <div class="query-opt">
                        <div *ngIf="requestQueryOrders && requestQueryOrders.fromDate && requestQueryOrders.toDate; else dateOrders">
                            <tic-date-selector [selectedFrom]="requestQueryOrders.fromDate" [selectedTo]="requestQueryOrders.toDate" (range)="filterByRange($event,'orders')" ></tic-date-selector>
                        </div>
                        <ng-template #dateOrders>
                            <tic-date-selector (range)="filterByRange($event,'orders')" ></tic-date-selector>
                        </ng-template>
                        <tic-chart-type [selected]="ordersChart" (chartType)="toggleChart($event,'orders')"></tic-chart-type>
                    </div>
                </div>
                <div class="query-opt">
                    <div *ngIf="requestQueryOrders && requestQueryOrders.filters && requestQueryOrders.filters.status; else noStatusOrder">
                        <tic-filters-dropdown [selected]="requestQueryOrders.filters.status"   (option)="dataFilter($event,'orders','status')" ></tic-filters-dropdown>
                    </div>
                    <ng-template #noStatusOrder>
                        <tic-filters-dropdown  (option)="dataFilter($event,'orders','status')" ></tic-filters-dropdown>
                    </ng-template>
                    <div *ngIf="requestQueryOrders && requestQueryOrders.filters && requestQueryOrders.filters.priceType && requestQueryOrders.filters.minPrice && requestQueryOrders.filters.maxPrice; else priceOrders">
                        <tic-price-selector [selected]="requestQueryOrders.filters.priceType" [min]="requestQueryOrders.filters.minPrice" [max]="requestQueryOrders.filters.maxPrice" (range)="dataFilter($event,'orders','price')" ></tic-price-selector>
                    </div>
                    <ng-template #priceOrders>
                        <tic-price-selector [selected]="selctedPriceOption" (range)="dataFilter($event,'orders','price')" ></tic-price-selector>
                    </ng-template>
                </div>
                <div *ngIf="(dashboardFacade.numberOfOrders$ | async) as orderInfo">
                <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions2"></highcharts-chart>
            </div>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="showSaveViewPopup">
    <tic-save-view (close)="closeViewPopup()" (saveView)="saveView($event)"></tic-save-view>
</ng-container>