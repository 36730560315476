import { createActionGroup, emptyProps, props } from "@ngrx/store";

export const CheckoutStateActions = createActionGroup({
  source: "State/API",
  events: {
    "Set Event Data": props<{ events: any[] }>(),
    "Set Flight Data": props<{ flight: any }>(),
    "Set Hotel Data": props<{ hotel: any }>(),
    "Set Room": props<{ room: any }>(),
    "Set Total Price": props<{ totalPrice: number }>(),
    "Set Package Price": props<{ packagePrice: number }>(),
    "Set Event Price": props<{ eventPrice: number }>(),
    "Set Flight Price": props<{ flightPrice: number }>(),
    "Set Hotel Price": props<{ hotelPrice: number }>(),
    "Set Search Params": props<{ searchParams: any }>(),
    "Set Passanger Detail": props<{ passangerDetail: any }>(),
    "Reset Save Order Info": emptyProps(),
    "Save Order": props<{ orderDetail: any }>(),
    "Save Order Success": props<{
      data: {
        saveOrderInfo: any;
      };
    }>(),
    "Save Order Failure": props<{ error: any }>(),
    "Reset Order Info": emptyProps(),
    "Get Order": props<{ orderRequest: any }>(),
    "Get Order Success": props<{
      data: {
        orderInfo: any;
      };
    }>(),
    "Get Order Failure": props<{ error: any }>(),
    // Event Order
    "Reset Save Event Order Info": emptyProps(),
    "Save Event Order": props<{ orderRequest: any }>(),
    "Save Event Order Success": props<{
      data: {
        saveEventOrderInfo: any;
      };
    }>(),
    "Save Event Order Failure": props<{ error: any }>(),

    "Save Multiple Events Order": props<{ orderRequest: any }>(),
    "Save Multiple Events Order Success": props<{
      data: {
        saveMultipleEventOrderInfo: any;
      };
    }>(),
    "Save Multiple Events Order Failure": props<{ error: any }>(),

    "Get Event Order": props<{ orderRequest: any }>(),
    "Get Event Order Success": props<{
      data: {
        eventOrderInfo: any;
      };
    }>(),
    "Get Event Order Failure": props<{ error: any }>(),

    "Get Event Orders Detail": props<{ requestQuery: any }>(),
    "Get Event Orders Detail Success": props<{
      data: {
        eventOrdersDetailInfo: any;
      };
    }>(),
    "Get Event Orders Detail Failure": props<{ error: any }>(),

    'Get Pre Searched Event':props<{ requestQuery: any }>(),
    'Get Pre Searched Event Success':props<{  data: {
      events: any;
    };}>(),
    'Get Pre Searched Event Failure':props<{ error: any  }>(),
    // Hotel Order
    "Reset Save Hotel Order Info": emptyProps(),
    "Save Hotel Order": props<{ orderRequest: any }>(),
    "Save Hotel Order Success": props<{
      data: {
        saveHotelOrderInfo: any;
      };
    }>(),
    "Save Hotel Order Failure": props<{ error: any }>(),
    "Get Hotel Order": props<{ orderRequest: any }>(),
    "Get Hotel Order Success": props<{
      data: {
        hotelOrderInfo: any;
      };
    }>(),
    "Get Hotel Order Failure": props<{ error: any }>(),
    'Get Pre Searched Hotel':props<{ requestQuery: any }>(),
    'Get Pre Searched Hotel Success':props<{  data: {
      hotel: any;
    };}>(),
    'Get Pre Searched Hotel Failure':props<{ error: any  }>(),
    // Flight Order
    "Reset Save Flight Order Info": emptyProps(),
    "Save Flight Order": props<{ orderRequest: any }>(),
    "Save Flight Order Success": props<{
      data: {
        saveFlightOrderInfo: any;
      };
    }>(),
    "Save Flight Order Failure": props<{ error: any }>(),
    "Get Flight Order": props<{ orderRequest: any }>(),
    "Get Flight Order Success": props<{
      data: {
        flightOrderInfo: any;
      };
    }>(),
    "Get Flight Order Failure": props<{ error: any }>(),
    'Get Pre Searched Flight':props<{ requestQuery: any }>(),
    'Get Pre Searched Flight Success':props<{  data: {
      flight: any;
    };}>(),
    'Get Pre Searched Flight Failure':props<{ error: any  }>(),

    'Get Coupon Detail':props<{ requestQuery: any }>(),
    'Get Coupon Detail Success':props<{  data: {
      couponDetail: any;
    };}>(),
    'Get Coupon Detail Failure':props<{ error: any  }>(),
    'Update Coupon Count':props<{ requestQuery: any }>(),
    'Update Coupon Count Success':props<{  data: {
      couponDetail: any;
    };}>(),
    'Update Coupon Count Failure':props<{ error: any  }>(),
  },
});
