import { PassengersTypeEnum } from "src/app/libs/data-access/models/enums/passengers.enum";

export namespace FlightPageUtils {
  const MAX_CONNECTIONS = 1;
  const CABIN_CLASS = "economy";
  export const SEARCH_PARAMS = {
    limit: 10,
    sort: "total_amount",
  };

  export function getRequestQuery(data: any): any {
    //TODO: complete types
    const {
      originLocationCode: origin,
      destinationLocationCode: destination,
      inbound,
      outbound,
      numberOfAdults,
      paxesChildrenAges: childrenArray,
    } = data || {};

    const passengersArray = [];
    for (let i = 0; i < numberOfAdults; i++) {
      passengersArray.push({ type: PassengersTypeEnum.ADULT });
    }
    if (childrenArray !== "null") {
      const childrenarr = JSON.parse(childrenArray);
      for (let i = 0; i < childrenarr.length; i++) {
      passengersArray.push({age:childrenarr[i]});
      }
    } 
    
    const requestQuery = {
      // flightsCriteria: {
      //   createOfferRequest: {
      //     slices: [
      //       {
      //         origin,
      //         destination,
      //         departure_date: inbound.date,
      //         departure_time: inbound.departure_time,
      //         arrival_time: inbound.arrival_time,
      //       },
      //       {
      //         origin: destination,
      //         destination: origin,
      //         departure_date: outbound.date,
      //         departure_time: outbound.departure_time,
      //         arrival_time: outbound.arrival_time,
      //       },
      //     ],
      //     passengers: passengersArray,
      //     cabin_class: CABIN_CLASS,
      //     max_connections: data.max_connections || MAX_CONNECTIONS,
      //   },
      //   searchParams: {
      //     ...SEARCH_PARAMS,
      //     sort: data.sort,
      //     max_connections: data.max_connections,
      //   },
      //   metadata: {
      //     page: 1,
      //     rpp: 1,
      //   },
      //   filters: {
      //     Price: {
      //       minPrice: 1,
      //       maxPrice: 200000,
      //     },
      //     Arrival: {
      //       Inbound: {
      //         start: "0:00",
      //         end: "30:00",
      //       },
      //       Return: {
      //         start: "0:00",
      //         end: "30:10",
      //       },
      //     },
      //     Departure: {
      //       Outbound: {
      //         start: "0:00",
      //         end: "30:00",
      //       },
      //       Return: {
      //         start: "0:00",
      //         end: "30:00",
      //       },
      //     },
      //     Duration: {
      //       minTime: 0,
      //       maxTime: 100,
      //     },
      //     Stops: [1],
      //     Airline: [
      //       {
      //         id: null,
      //         name: "Lufthansa",
      //       },
      //       {
      //         id: null,
      //         name: "Duffel Airways",
      //       },
      //     ],
      //   },
      // },
      flightsCriteria: {
        createOfferRequest: {
          slices: [
            {
              origin,
              destination,
              departure_date: inbound.date,
              departure_time: inbound.departure_time,
              arrival_time: inbound.arrival_time,
            },
            {
              origin: destination,
              destination: origin,
              departure_date: outbound.date,
              departure_time: outbound.departure_time,
              arrival_time: outbound.arrival_time,
            },
          ],
          passengers: passengersArray,
          cabin_class: CABIN_CLASS,
          max_connections: data.max_connections || MAX_CONNECTIONS,
        },
        searchParams: {
          ...SEARCH_PARAMS,
          sort: data.sort,
          max_connections: data.max_connections,
        },
        metadata: {
          page: 1,
          rpp: 2,
        },
      }
    };
    return requestQuery;
  }
}
