import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'tic-price-selector',
  templateUrl: './price-selector.component.html',
  styleUrls: ['./price-selector.component.scss']
})
export class PriceSelectorComponent {
  @ViewChild('minPrice', { static: false }) minPriceInput!: ElementRef;
  @ViewChild('maxPrice', { static: false }) maxPriceInput!: ElementRef;
  @Input() selected: any;
  @Input() min: any;
  @Input() max: any;
  @Output() range = new EventEmitter<any>();
  selectedOption: string = 'none';
  priceObj: any;
  priceOptions = [
    {value: 'none', viewValue: 'dashboard.filters.priceFilter.none'  },
    { value: 'originPrice', viewValue: 'dashboard.filters.priceFilter.originPrice' },
    { value: 'ourPrice', viewValue: 'dashboard.filters.priceFilter.ourPrice' },
    { value: 'profit', viewValue: 'dashboard.filters.priceFilter.profit' },
  ];
  constructor(public datePipe: DatePipe) {}

  ngOnInit() {
    if( this.selected && this.min && this.max ) {
      this.selectedOption = this.selected;
    }
  }

  ngAfterViewInit() {
    if( this.selected && this.min && this.max ) {
      this.minPriceInput.nativeElement.value = this.min;
      this.maxPriceInput.nativeElement.value = this.max;
    }
  }
  
  optionSelected(option: any) {
    this.priceObj = {
      ...this.priceObj,
      option: option
    }; 
  }

  onPriceRangeChange(): void {
    const minPr = parseFloat(this.minPriceInput.nativeElement.value);
    let maxPrStr = this.maxPriceInput.nativeElement.value;
  
    // Sanitize maxPrice directly
    maxPrStr = maxPrStr.replace(/[^0-9.]/g, '');
  
    // Handle empty input
    if (maxPrStr === '') {
      this.maxPriceInput.nativeElement.focus();
      return;
    }
  
    const maxPr = parseFloat(maxPrStr);
  
    // Handle invalid maxPrice
    if (isNaN(maxPr)) {
      this.maxPriceInput.nativeElement.focus();
      return;
    }
  
    // Validate maxPrice against minPrice
    if (maxPr <= minPr) {
      this.maxPriceInput.nativeElement.focus();
      // Optionally reset maxPrice to minPrice + 1
      this.maxPriceInput.nativeElement.value = (minPr + 1).toString();
      return;
    }
  
    this.priceObj = {
      ...this.priceObj,
      minPrice: minPr,
      maxPrice: maxPr
    };
    this.range.emit(this.priceObj);
  }

  sanitizeInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const newValue = inputElement.value.replace(/[^0-9.]/g, ''); // Allow only digits and decimal point
    if (newValue !== '' && parseFloat(newValue) < 0) {
      // Allow zero, prevent further negative input
      if (newValue !== '0') {
        inputElement.value = newValue.slice(0, -1); // Remove the last character (likely the negative sign)
      } else {
        // Allow zero
        return;
      }
    } else {
      // Update the input value with the sanitized version
      inputElement.value = newValue;
    }
  }

  resetInput(){
    this.selectedOption ='none';
    this.range.emit({option: 'none'});
  }

}
