import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tic-filters-dropdown',
  templateUrl: './filters-dropdown.component.html',
  styleUrls: ['./filters-dropdown.component.scss']
})
export class FiltersDropdownComponent {
  @Input() selected: any;
  @Input() label: any;
  @Input() filterOptions: any;
  @Output() option = new EventEmitter<string>();
  selectedOption: string = 'none';
  statusTitle: string = 'dashboard.filters.statusFilter.label';
  status = [
    { value: 'none', viewValue: 'dashboard.filters.statusFilter.none' },
    { value: 'PENDING', viewValue: 'dashboard.filters.statusFilter.pending' },
    { value: 'READY', viewValue: 'dashboard.filters.statusFilter.ready' },
    { value: 'IN_PROGRESS', viewValue: 'dashboard.filters.statusFilter.inProgress' },
    { value: 'COMPLETED ', viewValue: 'dashboard.filters.statusFilter.completed' },
    { value: 'CANCELLED', viewValue: 'dashboard.filters.statusFilter.cancelled' },
  ];

  ngOnInit() {
    if( this.selected ) {
      this.selectedOption = this.selected;
    }
  }

  optionSelected(option: any) {
    this.option.emit(option);
  }

}
