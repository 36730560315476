import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tic-chart-type',
  templateUrl: './chart-type.component.html',
  styleUrls: ['./chart-type.component.scss']
})
export class ChartTypeComponent {
  @Output() chartType = new EventEmitter<string>();
  @Input() selected: any;
  selectedOption: string;
  chartOptions = [
    { value: 'column', viewValue: 'dashboard.filters.chartOptions.barChart' },
    { value: 'line', viewValue: 'dashboard.filters.chartOptions.linechart' },
    { value: 'pie', viewValue: 'dashboard.filters.chartOptions.cakeChart' },
  ];

  ngOnChanges() {
    if(this.selected) {
      this.selectedOption = this.selected
    }
  }

  optionSelected(option: any) {
    this.chartType.emit(option);
  }
}
