<div class="evt-popup">
    <div class="evt-container">
        <div class="close-popup" (click)="closePopup()">
            <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g _ngcontent-nbx-c129="" opacity="0.5">
                <path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path _ngcontent-nbx-c129="" d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g>
            </svg>
        </div>
        <div class="popup-body">
            <div class="user-image">
                <img width="58" height="58" src="assets/images/user.png" >
            </div>
            <div class="comment">
                <div class="user-title">
                    <h2>{{passengerName}}</h2>
                    <form [formGroup]="orderCommentForm" (ngSubmit)="submitOrderCommentForm()">
                        <textarea name="comment" required formControlName="comment" 
                        [class.is-invalid]="comment.invalid && (comment.dirty || comment.touched)"
                        [value]="preComment"></textarea>
                        <div *ngIf="comment.invalid && (comment.dirty || comment.touched)" class="invalid-feedback">
                        <div *ngIf="comment.errors?.['required']">
                            {{'dashboard.couponsPage.couponForm.comment.requiredMessage' | i18n}}
                        </div>
                        </div>
                        <div class="button-group">
                          <button type="button" (click)="closePopup()">{{'dashboard.buttons.cancel' | i18n}}</button>
                          <button type="submit" [disabled]="this.orderCommentForm.invalid">{{'dashboard.buttons.send' | i18n}}</button>
                        </div>
                      </form>
                </div>
            </div>
        </div>
    </div>
</div>