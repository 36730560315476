import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { makeDashboardViewModel, IDashboardFacade, DashboardViewModel } from "../model";
import { DashboardQuery } from "./dashboard.query";

@Injectable({
  providedIn: 'root'
})
export class DashboardFacade implements IDashboardFacade {
  orders$: Observable<any> = this.dashboardQuery.orders$;
  events$: Observable<any> = this.dashboardQuery.events$;
  flights$: Observable<any> = this.dashboardQuery.flights$;
  hotels$: Observable<any> = this.dashboardQuery.hotels$;
  orderDetail$: Observable<any> = this.dashboardQuery.orderDetail$;
  eventDetail$: Observable<any> = this.dashboardQuery.eventDetail$;
  flightDetail$: Observable<any> = this.dashboardQuery.flightDetail$;
  hotelDetail$: Observable<any> = this.dashboardQuery.hotelDetail$;
  packagesInfo$: Observable<any> = this.dashboardQuery.packagesInfo$;
  personsInfo$: Observable<any> = this.dashboardQuery.personsInfo$;
  numberOfOrders$: Observable<any> = this.dashboardQuery.numberOfOrders$;
  popularAirlinesInfo$: Observable<any> = this.dashboardQuery.popularAirlinesInfo$;
  flightsIncomeInfo$: Observable<any> = this.dashboardQuery.flightsIncomeInfo$;
  flightsOutcomeInfo$: Observable<any> = this.dashboardQuery.flightsOutcomeInfo$;
  flightsProfitInfo$: Observable<any> = this.dashboardQuery.flightsProfitInfo$;
  popularHotelsInfo$: Observable<any> = this.dashboardQuery.popularHotelsInfo$;
  hotelsIncomeInfo$: Observable<any> = this.dashboardQuery.hotelsIncomeInfo$;
  hotelsOutcomeInfo$: Observable<any> = this.dashboardQuery.hotelsOutcomeInfo$;
  hotelsProfitInfo$: Observable<any> = this.dashboardQuery.hotelsProfitInfo$;
  popularEventsInfo$: Observable<any> = this.dashboardQuery.popularEventsInfo$;
  eventsIncomeInfo$: Observable<any> = this.dashboardQuery.eventsIncomeInfo$;
  eventsOutcomeInfo$: Observable<any> = this.dashboardQuery.eventsOutcomeInfo$;
  eventsProfitInfo$: Observable<any> = this.dashboardQuery.eventsProfitInfo$;
  reservationIncome$: Observable<any> = this.dashboardQuery.reservationIncome$;
  reservationOutcome$: Observable<any> = this.dashboardQuery.reservationOutcome$;
  emailObj$: Observable<any> = this.dashboardQuery.emailObj$;
  smsObj$: Observable<any> = this.dashboardQuery.smsObj$;
  pushObj$: Observable<any> = this.dashboardQuery.pushObj$;
  coupons$: Observable<any> = this.dashboardQuery.coupons$;
  CouponInfo$:Observable<any> = this.dashboardQuery.CouponInfo$;
  SaveViewInfo$:Observable<any> = this.dashboardQuery.SaveViewInfo$;
  OrdersView$:Observable<any> = this.dashboardQuery.OrdersView$;
  EventsView$:Observable<any> = this.dashboardQuery.EventsView$;
  FlightsView$:Observable<any> = this.dashboardQuery.FlightsView$;
  HotelsView$:Observable<any> = this.dashboardQuery.HotelsView$;
  ReservationsView$:Observable<any> = this.dashboardQuery.ReservationsView$;
  loading$: Observable<boolean> = this.dashboardQuery.loading$; 
  loaded$: Observable<boolean> = this.dashboardQuery.loaded$;
  errors$: Observable<any> = this.dashboardQuery.errors$;

  vm$!: Observable<DashboardViewModel>;

  constructor(
    private dashboardQuery: DashboardQuery,
  ) {
    this.vm$ = makeDashboardViewModel([
      this.orders$,
      this.events$,
      this.flights$,
      this.hotels$,
      this.orderDetail$,
      this.eventDetail$,
      this.flightDetail$,
      this.hotelDetail$,
      this.packagesInfo$,
      this.personsInfo$,
      this.numberOfOrders$,
      this.popularAirlinesInfo$,
      this.flightsIncomeInfo$,
      this.flightsOutcomeInfo$,
      this.flightsProfitInfo$,
      this.popularHotelsInfo$,
      this.hotelsIncomeInfo$,
      this.hotelsOutcomeInfo$,
      this.hotelsProfitInfo$,
      this.popularEventsInfo$,
      this.eventsIncomeInfo$,
      this.eventsOutcomeInfo$,
      this.eventsProfitInfo$,
      this.reservationIncome$,
      this.reservationOutcome$,
      this.emailObj$,
      this.smsObj$,
      this.pushObj$,
      this.coupons$,
      this.CouponInfo$,
      this.SaveViewInfo$,
      this.OrdersView$,
      this.EventsView$,
      this.FlightsView$,
      this.HotelsView$,
      this.ReservationsView$,
      this.loading$,
      this.loaded$,
      this.errors$
    ]);
    
  }

  getOrders(): void {
    this.dashboardQuery.getOrders();
  }

  getEvents(): void {
    this.dashboardQuery.getEvents();
  }

  getFlights(): void {
    this.dashboardQuery.getFlights();
  }

  getHotels(): void {
    this.dashboardQuery.getHotels();
  }

  getOrderDetail(id: string): void {
    this.dashboardQuery.getOrderDetail(id);
  }
  
  getEventDetail(id: string): void {
    this.dashboardQuery.getEventDetail(id);
  }

  getFlightDetail(id: string): void {
    this.dashboardQuery.getFlightDetail(id);
  }

  getHotelDetail(id: string): void {
    this.dashboardQuery.getHotelDetail(id);
  }

  resetOrderDetail() {
    this.dashboardQuery.resetOrderDetail();
  }

  resetEventDetail() {
    this.dashboardQuery.resetEventDetail();
  }

  resetFlightDetail() {
    this.dashboardQuery.resetFlightDetail();
  }

  resetHotelDetail() {
    this.dashboardQuery.resetHotelDetail();
  }

  loadPackages(query: any) {
    this.dashboardQuery.loadPackages(query);
  }

  loadPersons(query: any) {
    this.dashboardQuery.loadPersons(query);
  }

  getNumberOfOrders(query:any) {
    this.dashboardQuery.getNumberOfOrders(query);
  }
  loadPopularAirline(query: any) {
    this.dashboardQuery.loadPopularAirline(query);
  }

  loadFlightsIncome(query: any) {
    this.dashboardQuery.loadFlightsIncome(query);
  }

  loadFlightsOutcome(query: any) {
    this.dashboardQuery.loadFlightsOutcome(query);
  }

  loadFlightsProfit(query: any) {
    this.dashboardQuery.loadFlightsProfit(query);
  }
  loadPopularHotels(query: any) {
    this.dashboardQuery.loadPopularHotels(query);
  }

  loadHotelsIncome(query: any) {
    this.dashboardQuery.loadHotelsIncome(query);
  }

  loadHotelsOutcome(query: any) {
    this.dashboardQuery.loadHotelsOutcome(query);
  }

  loadHotelsProfit(query: any) {
    this.dashboardQuery.loadHotelsProfit(query);
  }
  loadPopularEvents(query: any) {
    this.dashboardQuery.loadPopularEvents(query);
  }

  loadEventsIncome(query: any) {
    this.dashboardQuery.loadEventsIncome(query);
  }

  loadEventsOutcome(query: any) {
    this.dashboardQuery.loadEventsOutcome(query);
  }

  loadEventsProfit(query: any) {
    this.dashboardQuery.loadEventsProfit(query);
  }

  sendSms(query: any) {
    this.dashboardQuery.sendSms(query);
  }

  resetSms() {
    this.dashboardQuery.resetSms();
  }

  sendEmail(query: any) {
    this.dashboardQuery.sendEmail(query);
  }

  resetEmail() {
    this.dashboardQuery.resetEmail();
  }

  sendPushNotification(query: any) {
    this.dashboardQuery.sendPushNotification(query);
  }
  
  resetPushNotification() {
    this.dashboardQuery.resetPushNotification();
  }

  loadReservationIncome(query: any) {
    this.dashboardQuery.loadReservationIncome(query);
  }

  loadReservationOutcome(query: any) {
    this.dashboardQuery.loadReservationOutcome(query);
  }

  updateOrderComment(orderDetail: any): void {
    this.dashboardQuery.updateOrderComment(orderDetail);
  }

  createCoupon(couponDetail: any): void {
    this.dashboardQuery.createCoupon(couponDetail);
  }

  updateCoupon(couponDetail: any): void {
    this.dashboardQuery.updateCoupon(couponDetail);
  }

  getCoupons(): void {
    this.dashboardQuery.getCoupons();
  }

  deleteCoupon(code:string): void {
    this.dashboardQuery.deleteCoupon(code);
  }

  saveView(saveViewDetail: any) {
   this.dashboardQuery.saveView({ saveViewDetail });    
  }

  getOrdersView(user: any):void{
    this.dashboardQuery.getOrdersView(user);
  } 
  getEventsView(user: any):void{
    this.dashboardQuery.getEventsView(user);
  } 
  getFlightsView(user: any):void{
    this.dashboardQuery.getFlightsView(user);
  } 
  getHotelsView(user: any):void{
    this.dashboardQuery.getHotelsView(user);
  } 
  getReservationsView(user: any):void{
    this.dashboardQuery.getReservationsView(user);
  } 
  
}