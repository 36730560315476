import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailNotificationComponent } from './email-notification.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { I18nModule } from 'src/app/core/languages-support/i18n.module';
import { AppSupportedLanguages } from 'src/app/core/languages-support/i18n-config.service';



@NgModule({
  declarations: [EmailNotificationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FroalaEditorModule.forRoot(), 
    FroalaViewModule.forRoot(),
    AngularEditorModule,
    I18nModule.forRoot(AppSupportedLanguages), 
  ],
  exports: [
    EmailNotificationComponent
  ]
})
export class EmailNotificationModule { }
