import { auditTime, combineLatest, map, Observable } from "rxjs";

export type CheckoutStreams = [
  Observable<any[]>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<string[]>,
  Observable<number>,
  Observable<number>,
  Observable<number>,
  Observable<number>,
  Observable<number>,
  Observable<boolean>,
  Observable<boolean>,
  Observable<Error>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any[]>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any[]>,
  Observable<any>,
];

export interface CheckoutViewModel {
  events: any[];
  flight: any;
  hotel: any;
  room: any;
  passangerDetail: any;
  orderInfo: any;
  ids: string[];
  totalPrice: number;
  packagePrice: number;
  eventPrice: number;
  flightPrice: number;
  hotelPrice: number;
  loading: boolean;
  loaded: boolean;
  error: Error;
  saveEventOrderInfo: any;
  eventOrderInfo: any;
  saveHotelOrderInfo: any;
  hotelOrderInfo:any;
  saveFlightOrderInfo:any,
  flightOrderInfo:any,
  saveOrderInfo:any,
  preSearchEvents: any[],
  preSearchFlight: any,
  preSearchHotel: any,
  eventOrdersDetailInfo:any,
  saveMultipleEventOrderInfo: any[];
  couponDetail:any,
}

export function makeCheckoutViewModel(streams: CheckoutStreams): Observable<Readonly<CheckoutViewModel>> {
  const toViewModel = ([ events, flight, hotel, room,passangerDetail,orderInfo, ids, totalPrice, packagePrice, eventPrice, flightPrice, hotelPrice,  loading, loaded ,error,saveEventOrderInfo,eventOrderInfo,saveHotelOrderInfo,hotelOrderInfo,saveFlightOrderInfo,flightOrderInfo,saveOrderInfo, preSearchEvents, preSearchFlight, preSearchHotel,eventOrdersDetailInfo,saveMultipleEventOrderInfo,couponDetail ]: [any[], any, any, any,any,any, string[], number, number, number, number, number, boolean , boolean, Error,any,any,any,any,any,any,any,any[], any, any,any,any[],any]): CheckoutViewModel => {
    return {
      events,
      flight,
      hotel,
      room,
      passangerDetail,
      orderInfo,
      ids,
      totalPrice,
      packagePrice,
      eventPrice,
      flightPrice,
      hotelPrice,
      loading,
      loaded,
      error,
      saveEventOrderInfo,
      eventOrderInfo,
      saveHotelOrderInfo,
      hotelOrderInfo,
      saveFlightOrderInfo,
      flightOrderInfo,
      saveOrderInfo,
      preSearchEvents,
      preSearchFlight,
      preSearchHotel,
      eventOrdersDetailInfo,
      saveMultipleEventOrderInfo,
      couponDetail
    }
  }

  return combineLatest(streams).pipe(auditTime(0), map(toViewModel))
}