<h3 class="font-weight-bold">{{'dashboard.flightsPage.title' | i18n}}</h3>
<div class="text-end saveView">
    <mat-form-field>
        <mat-select placeholder="{{'dashboard.orderDetailPage.selectView' | i18n}}" (selectionChange)="onSelectionChange($event)">
            <mat-option [value]="null">None</mat-option>
            <mat-option *ngFor="let data of flightsView.flightViews" [value]="data.param">{{data.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <tic-button class="blue" buttonText="dashboard.buttons.saveView" (btnClick)="openSaveView()"></tic-button>
</div>
<div *ngIf="showValidation">
    <div *ngIf="Info && Info.error && Info.error===true" class="alert alert-danger d-flex align-items-center" role="alert">
        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
        <div>
        {{Info.message}}
        </div>
    </div>    
    <div *ngIf="Info && !Info.error && Info.error===false" class="alert alert-success d-flex align-items-center" role="alert">
        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#exclamation-triangle-fill"/></svg>
        <div>
        {{Info.message}}
        </div>
    </div>
</div>
<div class="order-view">
    <div class="container-fluid">
        <div class="row rw-1">
            <div class="col-lg-6 col-style">
                <div class="header">
                    <h6>{{'dashboard.flightsPage.airlines' | i18n}}</h6>
                    <div class="query-opt">
                        
                        <div *ngIf="requestQueryAirline && requestQueryAirline.fromDate && requestQueryAirline.toDate; else dateAirline">
                            <tic-date-selector [selectedFrom]="requestQueryAirline.fromDate" [selectedTo]="requestQueryAirline.toDate" (range)="filterByRange($event,'airlines')" ></tic-date-selector>
                        </div>
                        <ng-template #dateAirline>
                            <tic-date-selector (range)="filterByRange($event,'airlines')" ></tic-date-selector>
                        </ng-template>
                        <tic-chart-type [selected]="airlineChart" (chartType)="toggleChart($event,'airlines')"></tic-chart-type>
                    </div>
                </div>
                <div class="query-opt">
                    <div *ngIf="requestQueryAirline && requestQueryAirline.filters && requestQueryAirline.filters.status; else statusAirline">
                        <tic-filters-dropdown [selected]="requestQueryAirline.filters.status" [label]="statusTitle" [filterOptions]="status" (option)="dataFilter($event,'airlines','status')" ></tic-filters-dropdown>
                    </div>
                    <ng-template #statusAirline>
                        <tic-filters-dropdown [label]="statusTitle" [filterOptions]="status" (option)="dataFilter($event,'airlines','status')" ></tic-filters-dropdown>
                    </ng-template>
                    <div *ngIf="requestQueryAirline && requestQueryAirline.filters && requestQueryAirline.filters.priceType && requestQueryAirline.filters.minPrice && requestQueryAirline.filters.maxPrice; else priceAirline">
                        <tic-price-selector [selected]="requestQueryAirline.filters.priceType" [min]="requestQueryAirline.filters.minPrice" [max]="requestQueryAirline.filters.maxPrice" (range)="dataFilter($event,'airlines','price')" ></tic-price-selector>
                    </div>
                    <ng-template #priceAirline>
                        <tic-price-selector (range)="dataFilter($event,'airlines','price')" ></tic-price-selector>
                    </ng-template>
                </div>
                <div *ngIf="airlineChartOptions">
                  <highcharts-chart [Highcharts]="Highcharts" [options]="airlineChartOptions"></highcharts-chart>
                </div> 
            </div>
            <div class="col-lg-6 col-style">
                <div class="header">
                    <h6>{{'dashboard.flightsPage.incomeFromFlights' | i18n}}</h6>
                    <div class="query-opt">
                        <div *ngIf="requestQueryFlightIncome && requestQueryFlightIncome.fromDate && requestQueryFlightIncome.toDate; else dateIncome">
                            <tic-date-selector [selectedFrom]="requestQueryFlightIncome.fromDate" [selectedTo]="requestQueryFlightIncome.toDate" (range)="filterByRange($event,'income')" ></tic-date-selector>
                        </div>
                        <ng-template #dateIncome>
                            <tic-date-selector (range)="filterByRange($event,'income')" ></tic-date-selector>
                        </ng-template>
                        <tic-chart-type [selected]="incomeChart" (chartType)="toggleChart($event,'income')"></tic-chart-type>
                    </div>
                </div>
                <div class="query-opt">
                    <div *ngIf="requestQueryFlightIncome && requestQueryFlightIncome.filters && requestQueryFlightIncome.filters.status; else noStatusIncome">
                        <tic-filters-dropdown [selected]="requestQueryFlightIncome.filters.status" [label]="statusTitle" [filterOptions]="status" (option)="dataFilter($event,'income','status')" ></tic-filters-dropdown>
                    </div>
                    <ng-template #noStatusIncome>
                        <tic-filters-dropdown [label]="statusTitle" [filterOptions]="status" (option)="dataFilter($event,'income','status')" ></tic-filters-dropdown>
                    </ng-template>
                    <div *ngIf="requestQueryFlightIncome && requestQueryFlightIncome.filters && requestQueryFlightIncome.filters.priceType && requestQueryFlightIncome.filters.minPrice && requestQueryFlightIncome.filters.maxPrice; else priceIncome">
                        <tic-price-selector [selected]="requestQueryFlightIncome.filters.priceType" [min]="requestQueryFlightIncome.filters.minPrice" [max]="requestQueryFlightIncome.filters.maxPrice" (range)="dataFilter($event,'income','price')" ></tic-price-selector>
                    </div>
                    <ng-template #priceIncome>
                        <tic-price-selector (range)="dataFilter($event,'income','price')" ></tic-price-selector>
                    </ng-template>
                </div>
                <div *ngIf="incomeChartOptions">
                  <highcharts-chart [Highcharts]="Highcharts" [options]="incomeChartOptions"></highcharts-chart>
                </div>
            </div>
        </div>
        <div class="row rw-2">
            <div class="col-lg-6 col-style">
                <div class="header">
                    <h6>{{'dashboard.flightsPage.outcomeOfFlights' | i18n}}</h6>
                    <div class="query-opt">
                        <div *ngIf="requestQueryFlightOutcome && requestQueryFlightOutcome.fromDate && requestQueryFlightOutcome.toDate; else dateOutcome">
                            <tic-date-selector [selectedFrom]="requestQueryFlightOutcome.fromDate" [selectedTo]="requestQueryFlightOutcome.toDate" (range)="filterByRange($event,'outcome')" ></tic-date-selector>
                        </div>
                        <ng-template #dateOutcome>
                            <tic-date-selector (range)="filterByRange($event,'outcome')" ></tic-date-selector>
                        </ng-template>
                        <tic-chart-type [selected]="outcomeChart" (chartType)="toggleChart($event,'outcome')"></tic-chart-type>
                    </div>
                </div>
                <div class="query-opt">
                    <div *ngIf="requestQueryFlightOutcome && requestQueryFlightOutcome.filters && requestQueryFlightOutcome.filters.status; else noStatusOutcome">
                        <tic-filters-dropdown [selected]="requestQueryFlightOutcome.filters.status" [label]="statusTitle" [filterOptions]="status" (option)="dataFilter($event,'outcome','status')" ></tic-filters-dropdown>
                    </div>
                    <ng-template #noStatusOutcome>
                        <tic-filters-dropdown [label]="statusTitle" [filterOptions]="status" (option)="dataFilter($event,'outcome','status')" ></tic-filters-dropdown>
                    </ng-template>
                    <div *ngIf="requestQueryFlightOutcome && requestQueryFlightOutcome.filters && requestQueryFlightOutcome.filters.priceType && requestQueryFlightOutcome.filters.minPrice && requestQueryFlightOutcome.filters.maxPrice; else priceOutcome">
                        <tic-price-selector [selected]="requestQueryFlightOutcome.filters.priceType" [min]="requestQueryFlightOutcome.filters.minPrice" [max]="requestQueryFlightOutcome.filters.maxPrice"  (range)="dataFilter($event,'outcome','price')" ></tic-price-selector>
                    </div>
                    <ng-template #priceOutcome>
                        <tic-price-selector (range)="dataFilter($event,'outcome','price')" ></tic-price-selector>
                    </ng-template>
                </div>
                <div *ngIf="outcomeChartOptions">
                  <highcharts-chart [Highcharts]="Highcharts" [options]="outcomeChartOptions"></highcharts-chart>
                </div>
            </div>
            <div class="col-lg-6 col-style">
              <div class="header">
                  <h6>{{'dashboard.flightsPage.profitOfFlights' | i18n}}</h6>
                  <div class="query-opt">
                    <div *ngIf="requestQueryFlightProfit && requestQueryFlightProfit.fromDate && requestQueryFlightProfit.toDate; else dateProfit">
                        <tic-date-selector [selectedFrom]="requestQueryFlightProfit.fromDate" [selectedTo]="requestQueryFlightProfit.toDate" (range)="filterByRange($event,'profit')" ></tic-date-selector>
                    </div>
                    <ng-template #dateProfit>
                        <tic-date-selector (range)="filterByRange($event,'profit')" ></tic-date-selector>
                    </ng-template>
                      <tic-chart-type [selected]="profitChart" (chartType)="toggleChart($event,'profit')"></tic-chart-type>
                  </div>
              </div>
              <div class="query-opt">
                <div *ngIf="requestQueryFlightProfit && requestQueryFlightProfit.filters && requestQueryFlightProfit.filters.status; else noStatusProfit">
                    <tic-filters-dropdown [selected]="requestQueryFlightProfit.filters.status" [label]="statusTitle" [filterOptions]="status" (option)="dataFilter($event,'profit','status')" ></tic-filters-dropdown>
                </div>
                <ng-template #noStatusProfit>
                    <tic-filters-dropdown [label]="statusTitle" [filterOptions]="status" (option)="dataFilter($event,'profit','status')" ></tic-filters-dropdown>
                </ng-template>
                <div *ngIf="requestQueryFlightProfit && requestQueryFlightProfit.filters && requestQueryFlightProfit.filters.priceType && requestQueryFlightProfit.filters.minPrice && requestQueryFlightProfit.filters.maxPrice; else priceProfit">
                    <tic-price-selector [selected]="requestQueryFlightProfit.filters.priceType" [min]="requestQueryFlightProfit.filters.minPrice" [max]="requestQueryFlightProfit.filters.maxPrice" (range)="dataFilter($event,'profit','price')" ></tic-price-selector>
                </div>
                <ng-template #priceProfit>
                    <tic-price-selector (range)="dataFilter($event,'profit','price')" ></tic-price-selector>
                </ng-template>
            </div>
              <div *ngIf="profitChartOptions">
               <highcharts-chart [Highcharts]="Highcharts" [options]="profitChartOptions"></highcharts-chart>
              </div>
          </div>
        </div>
    </div>
</div>
<ng-container *ngIf="showSaveViewPopup">
    <tic-save-view (close)="closeViewPopup()" (saveView)="saveView($event)"></tic-save-view>
  </ng-container>