import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonRuleType, ButtonType } from './enums/button.enums';

@Component({
  selector: 'tic-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() buttonText: string = '';
  @Input() className: string = '';
  @Input() ruleType = ButtonRuleType.Primary;
  @Input() buttonType: ButtonType = ButtonType.Submit;
  @Input() disabled: boolean = false;
  @Output() btnClick = new EventEmitter();

  onClick(): void {
    this.btnClick.emit();
  }
}
