export interface orderData {
    orderId: number;
    events: any;
    flight: any;
    hotels: any;
    status: string;
    badge: string;
}
export const orderData:orderData[]=[
    {
        orderId: 1,
        events: 'Jacob',
        flight: '12345',
        hotels: '12345',
        status: 'Pending',
        badge: 'badge-danger'
    },
    {
        orderId: 2,
        events: 'Jacob',
        flight: '12345',
        hotels: '12345',
        status: 'In progress',
        badge: 'badge-warning'
    },
    {
        orderId: 3,
        events: 'Jacob',
        flight: '12345',
        hotels: '12345',
        status: 'Fixed',
        badge: 'badge-info'
    },
    {
        orderId: 4,
        events: 'Jacob',
        flight: '12345',
        hotels: '12345',
        status: 'Completed',
        badge: 'badge-success'
    }
]

export interface MenuItem {
    iconClass: string;
    title: string;
    routerLink?: string;
    subItems?: MenuItem[];
    code?: string;
}
export interface OrderComment {
    orderId: string;
    comment: string;
}

export interface Coupon {
  name: string;
  code: string;
  discountType: string;
  value: string;
  isActive: boolean;
  fromDate: string;
  toDate: string;
  usage: number;
  currency: string;
}