import { auditTime, combineLatest, map, Observable } from "rxjs";
import { IFiltersMetadata, ILoadActionsWithData } from "src/app/libs/data-access/store/shared/entity-state";
import { SortValueEnum } from "src/app/libs/ui/components/top-bar/enums/top-bar.enum";

export type EventsStreams = [
  Observable<any[]>, // should be events
  Observable<any[]>,
  Observable<any[]>,
  Observable<any[]>,
  Observable<string[]>,
  Observable<number>,
  Observable<number>,
  Observable<IFiltersMetadata>,
  Observable<SortValueEnum>,
  Observable<any[]>,
  Observable<any>,
  Observable<any[]>,
  Observable<boolean>,
  Observable<boolean>,
  Observable<Error>
];

export interface EventsViewModel {
  events: any[];
  flights: any;
  hotels: any;
  active: any[];
  ids: string[];
  totalLeftToFetch: number;
  totalRecords: number;
  filtersMetadata: IFiltersMetadata;
  sort: SortValueEnum;
  selectedFilters: any[];
  selectedQuickFilter: any;
  loadMoreActions: any;
  loading: boolean;
  loaded: boolean;
  error: Error;
}

export function makeEventsViewModel(
  streams: EventsStreams
): Observable<Readonly<EventsViewModel>> {
  const toViewModel = ([
    events,
    flights,
    hotels,
    active,
    ids,
    totalLeftToFetch,
    totalRecords,
    filtersMetadata,
    sort,
    selectedFilters,
    selectedQuickFilter,
    loadMoreActions,
    loading,
    loaded,
    error,
  ]: [
    any,
    any,
    any,
    any[],
    string[],
    number,
    number,
    IFiltersMetadata,
    SortValueEnum,
    any,
    any,
    any,
    boolean,
    boolean,
    Error
  ]): EventsViewModel => {
    return {
      events,
      flights,
      hotels,
      active,
      ids,
      totalLeftToFetch,
      totalRecords,
      filtersMetadata,
      sort,
      selectedFilters,
      selectedQuickFilter,
      loadMoreActions,
      loading,
      loaded,
      error,
    };
  };

  return combineLatest(streams).pipe(auditTime(0), map(toViewModel));
}
