  <div class="card" [style.backgroundColor]="backgroundColor">
    <div class="main">      
      <div class="content">
        <p>{{cardName}}</p>
        <h1>{{discountValue}}{{discountType?'%':''}}</h1>
        <span class="code">{{'dashboard.couponsPage.couponCard.code' | i18n}} {{ code }}</span>
      </div>
      <div class="col-img">
        <p [style.Color]="isActive?'green':'red'">{{(this.isActive?'dashboard.couponsPage.couponCard.active':'dashboard.couponsPage.couponCard.inActive') | i18n}}</p>
        <img src="assets/images/gift.png">
        <div class="action">
          <i class="fa fa-edit" style="color: #fff;" (click)="Edit()"></i>
          <i class="fa fa-trash"style="color: #fff;" (click)="Delete()"></i> 
        </div>
      </div>
    </div>
  </div>