<div class="date-selector">
    <span>{{'dashboard.filters.priceFilter.label' | i18n }}</span>
    <div *ngIf="selectedOption === 'none'">
        <mat-form-field>
            <mat-select [(value)]="selectedOption" (selectionChange)="optionSelected(selectedOption)">
                <mat-option *ngFor="let option of priceOptions" [value]="option.value">{{ option.viewValue | i18n}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="selectedOption && selectedOption != 'none'" class="date-calendar">
          <div class="price-range-input">
            <mat-form-field appearance="fill" class="input-field">
              <input matInput type="number" placeholder="Min" #minPrice (input)="sanitizeInput($event)">
            </mat-form-field>
            <mat-form-field appearance="fill" class="input-field" >
              <input matInput type="number" placeholder="Max" #maxPrice (input)="onPriceRangeChange()">
            </mat-form-field>
            <button mat-icon-button class="btnBack" (click)="resetInput()"> <mat-icon  class="forward-arrow-icon">arrow_forward</mat-icon></button>
          </div>
    </div>
</div>