import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CheckoutState } from "../../../models";
import { CheckoutStateFeatureKey } from "./checkout.reducer";

export const checkoutFeatureSelector = createFeatureSelector<CheckoutState>(
  CheckoutStateFeatureKey
);

export const getEvents = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.events
);

export const getFlight = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.flight
);

export const getHotel = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.hotel
);

export const getRoom = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.room
);
export const getPassangerDetail = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.passangerDetail
);
export const getOrderInfo = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.orderInfo
);

export const getIds = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.ids
);

export const getTotalPrice = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.totalPrice
);

export const getPackagePrice = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.packagePrice
);

export const getEventPrice = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.eventPrice
);

export const getFlightPrice = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.flightPrice
);

export const getHotelPrice = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.hotelPrice
);

export const getSearchParams = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.searchParams
);

export const getLoading = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.loading
);

export const getLoaded = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.loaded
);

export const getErrors = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.error
);
export const getSaveOrderInfo = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.saveOrderInfo
);
export const getSaveEventOrderInfo = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.saveEventOrderInfo
);
export const getSaveMultipleEventOrderInfo = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.saveMultipleEventOrderInfo
);
export const getEventOrder = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.eventOrderInfo
);
export const getSaveHotelOrderInfo = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.saveHotelOrderInfo
);
export const getHotelOrder = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.hotelOrderInfo
);

export const getSaveFlightOrderInfo = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.saveFlightOrderInfo
);
export const getFlightOrder = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.flightOrderInfo
);

export const preSearchEvents = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.preSearchEvents
);

export const preSearchFlight = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.preSearchFlight
);
export const preSearchHotel = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.preSearchHotel
);
export const getEventOrdersDetail = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.eventOrdersDetailInfo
);
export const getCouponDetail = createSelector(
  checkoutFeatureSelector,
  (state: CheckoutState) => state?.couponDetail
);

export const CheckoutSelectors = {
  getEvents,
  getFlight,
  getHotel,
  getRoom,
  getPassangerDetail,
  getOrderInfo,
  getIds,
  getTotalPrice,
  getPackagePrice,
  getEventPrice,
  getFlightPrice,
  getHotelPrice,
  getSearchParams,
  getLoading,
  getLoaded,
  getErrors,
  getSaveOrderInfo,
  getSaveMultipleEventOrderInfo,
  getSaveEventOrderInfo,
  getEventOrder,
  getSaveHotelOrderInfo,
  getHotelOrder,
  getSaveFlightOrderInfo,
  getFlightOrder,
  preSearchEvents,
  preSearchFlight,
  preSearchHotel,
  getEventOrdersDetail,
  getCouponDetail
};
