import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DashboardFacade } from 'src/app/pages/admin-dashboard/data-access/services/dashboard.facade';

@Component({
  selector: 'tic-sms-notification',
  templateUrl: './sms-notification.component.html',
  styleUrls: ['./sms-notification.component.scss']
})
export class SmsNotificationComponent {
  @Output() close = new EventEmitter<boolean>();
  @Output() sendSmsNotification = new EventEmitter<any>();
  @Input() phoneList: any;
  smsSuccess = false;
  myForm: FormGroup;
  smsMessage: any;

  constructor(private formBuilder: FormBuilder,public dashboardFacade: DashboardFacade) { }

  ngOnInit(): void {
    this.myForm = this.formBuilder.group({
      //to: ['', [Validators.required,this.phoneValidator()]],
      sms_body: ['', Validators.required]
    });
    this.smsValidation();
  }

  smsValidation() {
    this.dashboardFacade.smsObj$.subscribe(smsObj => {
      this.smsMessage = smsObj
      if (this.smsSuccess) {
        this.smsSuccess = true;
        setTimeout(() => {
          this.smsSuccess = false;
        }, 2000); // Hide the message after 2 seconds
      }
    });
  }

  isObjectNotEmpty(obj: any): boolean {
    return obj && Object.keys(obj).length > 0;
  }

  phoneValidator() {
    return (control: FormControl): ValidationErrors | null => {
      const phoneNumber = control.value;
      
      if (!phoneNumber) {
        return null;
      }
      const isValidPhoneNumber = this.isValidPhone(phoneNumber);
  
      if (!isValidPhoneNumber) {
        return { invalidPhone: true };
      }
  
      return null;
    };
  }
  
  isValidPhone(phone: string): boolean {
    const phonePattern = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
    return phonePattern.test(phone);
  }
  

  closePopup() {
    this.close.emit(true);
  }

  submitForm() {
    if (this.myForm.valid) {
      this.smsSuccess = true;
      this.dashboardFacade.resetSms();
      this.sendSmsNotification.emit(this.myForm.value);
    } else {
      console.error('Form is invalid.');
    }
  }
}