import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PushNotificationComponent } from './push-notification.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from 'src/app/core/languages-support/i18n.module';
import { AppSupportedLanguages } from 'src/app/core/languages-support/i18n-config.service';

@NgModule({
  declarations: [PushNotificationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule.forRoot(AppSupportedLanguages),
  ],
  exports: [
    PushNotificationComponent
  ]
})
export class PushNotificationModule { }
