import { FilterType, ValueType } from "src/app/libs/ui/enums/fiters.enums";
import {
  IFilter
} from "../../../../../../libs/ui/interfaces/filters.interface";

export const EventsFiltersMetaData: IFilter<any>[] = [
  {
    type: FilterType.Slider,
    displayName: "Price",
    id: "price", // Assuming a unique identifier for this filter
    active: true,
    expanded: true,
    options: [
      {
        min: {
          defaultValue: 0,
          type: ValueType.Number,
          value: null,
          id: null,
        },
        max: {
          defaultValue: 5000,
          type: ValueType.Number,
          value: null,
        },
      },
    ],
  },
  {
    type: FilterType.Checkbox,
    displayName: "Location",
    id: "locations", // Assuming a unique identifier for this filter
    active: true,
    expanded: false,
    options: [{ name: "", options: [] }],
  },
  {
    type: FilterType.Checkbox,
    displayName: "Events",
    id: "sportNames", // Assuming a unique identifier for this filter
    active: true,
    expanded: false,
    options: [{ name: "", options: [] }],
  },
  {
    type: FilterType.Checkbox,
    displayName: "Home Team",
    id: "homeTeamNames", // Assuming a unique identifier for this filter
    active: true,
    expanded: false,
    options: [{ name: "", options: [] }],
  },
  {
    type: FilterType.Checkbox,
    displayName: "Away Team",
    id: "awayTeamNames", // Assuming a unique identifier for this filter
    active: true,
    expanded: false,
    options: [{ name: "", options: [] }],
  },
  {
    type: FilterType.Checkbox,
    displayName: "Tournament",
    id: "tournamentNames", // Assuming a unique identifier for this filter
    active: true,
    expanded: false,
    options: [{ name: "", options: [] }],
  },
  {
    type: FilterType.Checkbox,
    displayName: "Venue",
    id: "venueNames", // Assuming a unique identifier for this filter
    active: true,
    expanded: false,
    options: [{ name: "", options: [] }],
  },
];
