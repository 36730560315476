import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { AppSupportedLanguages } from 'src/app/core/languages-support/i18n-config.service';
import { I18nModule } from 'src/app/core/languages-support/i18n.module';
import { DateRangeComponent } from './date-range.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    DateRangeComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatInputModule,
    MatTooltipModule,
    I18nModule.forRoot(AppSupportedLanguages),
  ],
  exports: [
    DateRangeComponent,
  ],
})
export class DateRangeModule { }
