import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AgeFromDateString } from 'age-calculator';

const enum PassengerMaxAgeByTypeEnum {
    CHILD = 18,
    INFANT = 2
}

export function isValueInArrayValidator(items: string[]): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
        const value: string = control.value;
        if (!value) {
            return null;
        }
        if (items.includes(value)){
            return null;
        }
        return { 
            notInArray: true
        };
    }       
}

export function isInfantBirthDate(): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
        const date: string = control.value;
        if (!date) {
            return null;
        }
        let today = new Date();
        if (new Date(date) > today) {
            return { 
                invalidBirthDate: true,
            };
        }
        let age = new AgeFromDateString(date).age;
        if (age < PassengerMaxAgeByTypeEnum.INFANT) {
            return null;
        }
        return { 
            notInfant: true
        };
    }       
}

export function isChildBirthDate(): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
        const date: string = control.value;
        if (!date) {
            return null;
        }
        let today = new Date();
        if (new Date(date) > today) {
            return { 
                invalidBirthDate: true,
            };
        }
        let age = new AgeFromDateString(date).age;
        if (age >= PassengerMaxAgeByTypeEnum.INFANT && age < PassengerMaxAgeByTypeEnum.CHILD) {
            return null;
        }
        return { 
            notChild: true
        };
    }       
}
export function isOrderIdValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const orderId: string = control.value;
      if (!orderId) {
        return null; // If the order ID field is empty, don't perform validation
      }
      // Regular expression to check if the order ID consists of exactly 8 digits
      const regexPattern = /^\d{8}$/;
      if (!regexPattern.test(orderId)) {
        return { invalidOrderId: true }; // Return an error if the order ID doesn't match the pattern
      }
      return null; // Order ID is valid
    };
  }

export function isAdultBirthDate(): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
        const date: string = control.value;
        if (!date) {
            return null;
        }
        let today = new Date();
        if (new Date(date) > today) {
            return { 
                invalidBirthDate: true,
            };
        }
        let age = new AgeFromDateString(date).age;
        if (age >= PassengerMaxAgeByTypeEnum.CHILD) {
            return null;
        }
        return { 
            notAdult: true
        };
    }       
}

export function isPassportExpired(): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
        const expirationDate: string = control.value;
        if (!expirationDate) {
            return null;
        }

        let today = new Date();
        if (new Date(expirationDate) <= today) {
            return { 
                expired: true,
            };
        }
        return null;
    }       
}
export function isMobilePhoneValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const phonePattern = /^[1-9][0-9]{9}$/;
        const phoneNumber: string = control.value;
        if (!phoneNumber || !phonePattern.test(phoneNumber)) {
            return {
                invalidPhoneNumber: true
            };
        }
        return null;
    };
}
export function isEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email: string = control.value;
      if (!email) {
        return null; // If the email field is empty, don't perform validation
      }
      // Regular expression for standard email format
      const regexPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!regexPattern.test(email)) {
        return { invalidEmail: true }; // Return an error if the email doesn't match the pattern
      }
      return null; // Email is valid
    };
  }
