<div class="evt-popup">
    <div class="evt-container">
        <div class="close-popup" (click)="closePopup()">
            <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g _ngcontent-nbx-c129="" opacity="0.5">
                <path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path _ngcontent-nbx-c129="" d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g>
            </svg>
        </div>
        <h3 class="font-weight-bold">{{'dashboard.orderViewPage.sendPushNotification.title' | i18n}}</h3>
        <div *ngIf="dashboardFacade.pushObj$ | async as pushObj">
            <div *ngIf="pushSuccess">
                <div *ngIf="isObjectNotEmpty(pushObj)">
                    <p class="text-success" *ngIf="pushObj.code == 201; else failed">{{'dashboard.orderViewPage.sendPushNotification.sentNotification' | i18n}}</p>
                    <ng-template #failed>
                        <p class="text-danger" *ngIf="pushObj.code != 201">{{'dashboard.orderViewPage.sendPushNotification.failedNotification' | i18n}}</p>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="popup-body">
            <div class="form">
                <form [formGroup]="myForm" (ngSubmit)="submitForm()">
                    <div>
                      <label for="title">{{'dashboard.orderViewPage.sendPushNotification.notificationPlaceHolderTitle' | i18n}}</label>
                      <input type="text" id="title" name="title" formControlName="title" required>
                    </div>
                    <div>
                      <label for="body">{{'dashboard.orderViewPage.sendPushNotification.notificationPlaceHolderBody' | i18n}}</label>
                      <textarea id="body" name="body" formControlName="body" required></textarea>
                    </div>
                    <button type="submit" [disabled]="!myForm.valid">{{'dashboard.buttons.send' | i18n}}</button>
                </form>
            </div>
        </div>
    </div>
</div>