import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DashboardFacade } from '../../../data-access/services/dashboard.facade';

@Component({
  selector: 'tic-hotel-table',
  templateUrl: './hotel-table.component.html',
  styleUrls: ['./hotel-table.component.scss']
})
export class HotelTableComponent {
  @Output() close = new EventEmitter<boolean>();
  @Input() order :any;
  constructor(public dashboardFacade: DashboardFacade) {
  }

  closePopup() {
    this.close.emit(true);
  }
}
