import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { i18nConfigService } from './i18n-config.service';
import { I18nPipe } from './i18n.pipe';
import { I18nService } from './i18n.service';

@NgModule({
  declarations: [
    I18nPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    I18nPipe,
  ]
})
export class I18nModule {

  static forRoot(config: any): ModuleWithProviders<any> {
    return {
      ngModule: I18nModule,
      providers: [
        I18nService,
        {
          provide: i18nConfigService,
          useValue: config,
        }
      ]
    }
  }
}
