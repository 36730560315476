import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, exhaustMap, map, of, switchMap } from "rxjs";
import { EventsService } from "../services/events.service";
import { EventsStateActions } from "./events.actions";

@Injectable()
export class EventsEffects {

  constructor(
    private actions$: Actions,
    private eventsService: EventsService
  ) {}

  loadEventFilterOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsStateActions.loadEventFilterOptions),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.eventsService.getFilterOptions(query.requestQuery).pipe(
          map((res) => {
            const filterOptions = res;

            return EventsStateActions.loadEventFilterOptionsSuccess({
              data: {
                filterOptions,
              },
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              EventsStateActions.loadEventFilterOptionsFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  loadInitialEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsStateActions.loadInitialEventsData),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.eventsService.getEvents(query.requestQuery).pipe(
          map((res) => {
            const { events, sort } = res;
            const { totalRecordsLeft } = res.metadata;

            return EventsStateActions.loadInitialEventsDataSuccess({
              data: {
                events,
                totalLeftToFetch: totalRecordsLeft,
              },
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              EventsStateActions.loadFilteredEventsDataFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  loadFilteredEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsStateActions.loadFilteredEventsData),
      switchMap((query: any) => {
        //TODO: complete types
        return this.eventsService.filterEvents(query.requestQuery).pipe(
          map((res) => {
            const { events, flights, hotels } = res;
            const { totalRecordsLeft,totalRecords } = res.metadata.page;
            return EventsStateActions.loadFilteredEventsDataSuccess({
              data: {
                events,
                ...(flights && { flights }),  // Include flights if it exists
                ...(hotels && { hotels }), 
                totalLeftToFetch: totalRecordsLeft,
                totalRecords: totalRecords
              },
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              EventsStateActions.loadFilteredEventsDataFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  loadMoreEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsStateActions.loadMoreEventsData),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.eventsService.listEvents(query).pipe(
          map((res) => {
            const { events, flights, hotels } = res;
            const { totalRecordsLeft } = res.metadata.page;
            return EventsStateActions.loadMoreEventsDataSuccess({
              data: {
                events,
                ...(flights && { flights }),  // Include flights if it exists
                ...(hotels && { hotels }),    // Include hotels if it exists
                totalLeftToFetch: totalRecordsLeft,
              },
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              EventsStateActions.loadMoreEventsFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );
  
}
