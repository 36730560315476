export namespace LocalStorageUtil {
    // Set a value in local storage
    export function setOption(key: string, value: any): void {
      try {
        const serializedValue = JSON.stringify(value);
        localStorage.setItem(key, serializedValue);
      } catch (e) {
        console.error(`Error saving ${key} in localStorage`, e);
      }
    }
  
    // Get a value from local storage
    export function getOption(key: string): any {
      try {
        const serializedValue = localStorage.getItem(key);
        return serializedValue === null ? undefined : JSON.parse(serializedValue);
      } catch (e) {
        console.error(`Error reading ${key} from localStorage`, e);
        return undefined;
      }
    }
  
    // Remove a specific option from local storage
    export function removeOption(key: string): void {
      try {
        localStorage.removeItem(key);
      } catch (e) {
        console.error(`Error removing ${key} from localStorage`, e);
      }
    }
  
    // Clear all options from local storage
    export function clearOptions(): void {
      try {
        localStorage.clear();
      } catch (e) {
        console.error("Error clearing localStorage", e);
      }
    }
  }
