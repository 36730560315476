import { Component, ElementRef, Renderer2 } from '@angular/core';
import { TextDirectionController } from 'src/app/app.config';
import { AppSupportedLanguages, AppSupportedRtlLanguages } from 'src/app/core/languages-support/i18n-config.service';
import { I18nService } from 'src/app/core/languages-support/i18n.service';

@Component({
  selector: 'tic-language-panel',
  templateUrl: './language-panel.component.html',
  styleUrls: ['./language-panel.component.scss']
})
export class LanguagePanelComponent {
  languageData = AppSupportedLanguages;
  openLang: boolean = false;
  public directionController = new TextDirectionController();

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private i18nService:I18nService
  ) {
    this.setSelectedLanguage();
  }

  ngOnInit(): void {
    this.defaultLanguage();
  }

  defaultLanguage() {
    if( localStorage.getItem('direction') && localStorage.getItem('lang') ){
      const direction= localStorage.getItem('direction')
      this.directionController.textDirection = direction;
      this.renderer.setAttribute(document.body, 'dir', this.directionController.textDirection);
    }
  }

  openLanguage() {
    this.openLang=!this.openLang
    if (this.openLang) {
      document.addEventListener('click', this.closePopupOutside);
    } else {
      document.removeEventListener('click', this.closePopupOutside);
    }
  }

  closePopup() {
    this.openLang = false;
    document.removeEventListener('click', this.closePopupOutside);
  }

  setSelectedLanguage(lang?: any) {
    let selectedLanguage = localStorage.getItem('lang').replace(/"/g, '');
    if( lang ) {
      selectedLanguage = lang.replace(/"/g, '');
    }
    this.languageData = this.languageData.map(language => {
      const code = language.name.toString();
      if (code.normalize() == selectedLanguage.normalize()) {
          return {
              ...language,
              selected: true
          };
      } else {
        return {
          ...language,
          selected: false
        };
      }
    });
  }

  changeDirection(lang: string): void {
    localStorage.setItem('lang', lang);
    this.i18nService.languageChange();
    const isRtlLanguage = AppSupportedRtlLanguages.some(rlang => rlang.name === lang);
    this.directionController.textDirection = isRtlLanguage ? 'rtl' : 'ltr';
    localStorage.setItem('direction', this.directionController.textDirection);
    this.setSelectedLanguage(lang);
    this.renderer.setAttribute(document.body, 'dir', this.directionController.textDirection);
    this.openLang = false;
  }

  closePopupOutside = (event: MouseEvent) => {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.closePopup();
    }
  }
}