export enum AppDirection {
  Rtl = 'rtl',
  Ltr = 'ltr',
}

export class TextDirectionController {
  public textDirection!: string;
  
  constructor() {
    this.CheckDirection();
  }
  
  public CheckDirection(): void {
    const lang = localStorage.getItem('lang');
    this.textDirection = lang === 'HE' ? AppDirection.Rtl : AppDirection.Ltr;
  }
}
