import { createActionGroup, props, ActionCreator, emptyProps } from "@ngrx/store";

export const currencyStateActions = createActionGroup({
  source: 'State/API',
  events: {
    'Get Exchange Rate': emptyProps(),
    'Exchange Rate Success': props<{ data: any }>(),
    'Set Current Currency': props<{ currency: string }>(),
  }
});
