<h3 class="font-weight-bold">{{'dashboard.couponsPage.title' | i18n}}</h3>
<div class="btn-list">
  <tic-button class="blue" (click)="openPopup(false)" buttonText="dashboard.buttons.createCoupon"></tic-button>
</div>
<div *ngIf="CouponInfo">
  <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
    </symbol>
    <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
    </symbol>
    <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
    </symbol>
  </svg>
  <div *ngIf="!CouponInfo.error && CouponInfo.error===false" class="alert alert-success d-flex align-items-center" role="alert">
      <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg>
      <div>
        {{CouponInfo.message}}
      </div>
  </div>                      
</div>
<div class="row gift-cards">
    <div *ngFor="let coupon of couponList; let i = index"  class="gift-card col-lg-4 col-md-6 col-sm-12">
     <tic-coupon-card 
     [backgroundColor]="colors[i % colors.length]"
     [cardName]="coupon.name"
     [code]="coupon.code"
     [discountValue]="coupon.value"
     [discountType]="coupon.discountType === 'percentage'"
     [isActive]="coupon.isActive"
     (EditCoupon)="openPopup(true,coupon)"
     (DeleteCoupon)="DeleteCoupon(coupon.code)"
     ></tic-coupon-card>
    </div>
</div>

<section
  [ngClass]="{ luggage: true, 'coupon-popup': true, 'open': isPopupOpen }"
  appOutside
  (click)="ClickedOutevt($event)"
>
  <div class="luggage-container">
    <div class="close-popup">
      <svg
        (click)="closePopup()"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g _ngcontent-sml-c41="" opacity="0.5">
          <path
            _ngcontent-sml-c41=""
            d="M18 6L6 18"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            _ngcontent-sml-c41=""
            d="M6 6L18 18"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </g>
      </svg>
    </div>
    <div class="event">
      <form [formGroup]="couponForm" (ngSubmit)="onSubmitForm()" #myForm="ngForm">
        <section class="summary-sect">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 chk-frm">
                <div class="contact-details">
                  <h2>{{couponFormTitle | i18n}}</h2>
                  <div *ngIf="CouponInfo">
                    <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                      <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </symbol>
                      <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                      </symbol>
                      <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                      </symbol>
                    </svg>
                    <div *ngIf="CouponInfo.error && CouponInfo.error===true" class="alert alert-danger d-flex align-items-center" role="alert">
                      <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
                      <div>
                        {{CouponInfo.message}}
                      </div>
                    </div>                      
                  </div>
                  <div class="cf-field">
                    <div class="form-field">
                      <div class="cname">
                        <label for="cname">{{'dashboard.couponsPage.couponForm.couponName.name' | i18n}}</label>
                        <input name="cname" id="cname" type="text" formControlName="cnameControl" />
                        <div *ngIf="
                            this.cnameControl.invalid &&
                            (this.cnameControl.dirty || this.cnameControl.touched)
                          " class="errorText">
                          {{ checkError(this.cnameControl,'dashboard.couponsPage.couponForm.couponName.requiredMessage') | i18n}}
                        </div>
                      </div>
                      <div class="cCode">
                        <label for="cCode">{{'dashboard.couponsPage.couponForm.couponCode.name' | i18n}}</label>
                        <input name="cCode" id="cCode" type="text" formControlName="cCodeControl" />
                        <div *ngIf="
                            this.cCodeControl.invalid &&
                            (this.cCodeControl.dirty || this.cCodeControl.touched)
                          " class="errorText">
                          {{ checkError(this.cnameControl,'dashboard.couponsPage.couponForm.couponCode.requiredMessage') | i18n}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cf-field">
                    <div class="form-field">
                      <div class="cdiscountType">
                        <label for="cdiscountType">{{'dashboard.couponsPage.couponForm.discountType.name' | i18n}}</label>
                        <select name="cdiscountType" id="cdiscountType" type="text" formControlName="cDiscountTypeControl">
                          <option>{{'dashboard.couponsPage.couponForm.selectPlaceholderDiscount' | i18n}}</option>
                          <option value="fixed">{{'dashboard.couponsPage.couponForm.fixed' | i18n}}</option>
                          <option value="percentage">{{'dashboard.couponsPage.couponForm.percentage' | i18n}}</option>
                          </select>
                        <div *ngIf="
                            this.cDiscountTypeControl.invalid &&
                            (this.cDiscountTypeControl.dirty || this.cDiscountTypeControl.touched)
                          " class="errorText">
                          {{ checkError(this.cDiscountTypeControl,'dashboard.couponsPage.couponForm.discountType.requiredMessage') | i18n}}
                        </div>
                      </div>
                      <div class="cValue">
                        <label for="cValue">{{'dashboard.couponsPage.couponForm.couponValue.name' | i18n}}</label>
                        <input name="cValue" id="cValue" type="text" formControlName="cValueControl" />
                        <div *ngIf="
                            this.cValueControl.invalid &&
                            (this.cValueControl.dirty || this.cValueControl.touched)
                          " class="errorText">
                          {{ checkError(this.cValueControl,'dashboard.couponsPage.couponForm.couponValue.requiredMessage') | i18n}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cf-field">
                    <div class="form-field">
                      <div class="date-picker-coupon">
                        <label>{{'dashboard.couponsPage.couponForm.startDateControl.name' | i18n}}</label>
                        <mat-form-field class="text-field" appearance="fill">
                          <div class="customAction">
                            <mat-datepicker-toggle matIconSuffix [for]="picker" class="btnPicker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                          </div>
                          <mat-date-range-input class="dateRange" [rangePicker]="picker">
                            <input matStartDate formControlName="startDateControl" placeholder="{{'dashboard.couponsPage.couponForm.startDate' | i18n}}" #startDateInput required>
                            <input matEndDate formControlName="endDateControl" placeholder="{{'dashboard.couponsPage.couponForm.endDate' | i18n}}" #endDateInput required>
                          </mat-date-range-input>
                        </mat-form-field>
                        <div *ngIf="
                            this.startDateControl.invalid &&
                            (this.startDateControl.dirty || this.startDateControl.touched)
                          " class="errorText">
                          {{ checkError(this.startDateControl,'dashboard.couponsPage.couponForm.startDateControl.requiredMessage') | i18n}}
                        </div>
                        <div *ngIf="
                            this.endDateControl.invalid &&
                            (this.endDateControl.dirty || this.endDateControl.touched)
                          " class="errorText">
                          {{ checkError(this.endDateControl,'dashboard.couponsPage.couponForm.endDateControl.requiredMessage') | i18n}}
                        </div>
                      </div>
                      <div class="cUsage">
                        <label for="cUsage">{{'dashboard.couponsPage.couponForm.usage.name' | i18n}}</label>
                        <input name="cUsage" id="cUsage" type="number" [min]="1" formControlName="startDateControl" />
                        <div *ngIf="
                            this.cUsageControl.invalid &&
                            (this.cUsageControl.dirty || this.cUsageControl.touched)
                          " class="errorText">
                          {{ checkError(this.cUsageControl,'dashboard.couponsPage.couponForm.usage.requiredMessage') | i18n}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cf-field">
                    <div class="form-field">
                      <div class="cdiscountType">
                        <label for="cdiscountType">{{'dashboard.couponsPage.couponForm.statusType.name' | i18n}}</label>
                        <select name="cdiscountType" id="cdiscountType" type="text" formControlName="cActiveControl">
                          <option>{{'dashboard.couponsPage.couponForm.selectPlaceholderStatus' | i18n}}</option>
                          <option value="1">{{'dashboard.couponsPage.couponCard.active' | i18n}}</option>
                          <option value="0">{{'dashboard.couponsPage.couponCard.inActive' | i18n}}</option>
                          </select>
                        <div *ngIf="
                            this.cActiveControl.invalid &&
                            (this.cActiveControl.dirty || this.cActiveControl.touched)
                          " class="errorText">
                          {{ checkError(this.cActiveControl,'dashboard.couponsPage.couponForm.statusType.requiredMessage') | i18n}}
                        </div>
                      </div>
                      <div class="cCurrency" *ngIf="this.cDiscountTypeControl.value == 'fixed'">
                        <label for="cCurrency">{{'dashboard.couponsPage.couponForm.currency.name' | i18n}}</label>
                        <select name="cCurrency" id="cCurrency" type="text" formControlName="cCurrencyControl" required>
                          <option value="">{{'dashboard.couponsPage.couponForm.notSelected' | i18n}}</option>  <option *ngFor="let currency of currenciesData" value="{{ currency.name }}">{{ currency.name }}</option>
                        </select>
                        <div *ngIf="
                            this.cCurrencyControl.invalid &&
                            (this.cCurrencyControl.dirty || this.cCurrencyControl.touched)
                          " class="errorText">
                          {{ checkError(this.cCurrencyControl,'dashboard.couponsPage.couponForm.currency.requiredMessage') | i18n}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="cont-btn">
          <button type="submit">{{couponBtnText | i18n }}</button>
        </div>
      </form>
    </div>
  </div>
</section>