import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminDashboardComponent } from './admin-dashboard.component';
import { DashboardHeaderComponent } from './components/dashboard-header/dashboard-header.component';
import { SidePanelComponent } from './components/side-panel/side-panel.component';
import { OrdersTableComponent } from './components/orders-table/orders-table.component';
import { DashboardDataAccessModule } from '../data-access';
import { MatTableModule } from '@angular/material/table';
import { MatBadgeModule } from '@angular/material/badge';
import { FlightDetailComponent } from './components/flight-detail/flight-detail.component';
import { HotelDetailComponent } from './components/hotel-detail/hotel-detail.component';
import { EventDetailComponent } from './components/event-detail/event-detail.component';
import { SingleEventComponent } from './components/single-event/single-event.component';
import { SingleFlightComponent } from './components/single-flight/single-flight.component';
import { SingleHotelComponent } from './components/single-hotel/single-hotel.component';
import { SingleOrderComponent } from './components/single-order/single-order.component';
import { CommentsPopupComponent } from './components/comments-popup/comments-popup.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { OrderViewComponent } from './components/order-view/order-view.component';
import { DateRangeModule } from "../../../libs/ui/components/date-range/date-range.module";
import { DateSelectorModule } from "../../../libs/ui/components/date-selector/date-selector.module";
import { ChartTypeModule } from "../../../libs/ui/components/chart-type/chart-type.module";
import { FiltersDropdownModule } from 'src/app/libs/ui/components/filters-dropdown/filters-dropdown.module';
import { PriceSelectorModule } from "../../../libs/ui/components/price-selector/price-selector.module";
import { ButtonModule } from "../../../libs/ui/components/button/button.module";
import { SmsNotificationModule } from 'src/app/libs/ui/components/sms-notification/sms-notification.module';
import { EmailNotificationModule } from 'src/app/libs/ui/components/email-notification/email-notification.module';
import { PushNotificationModule } from 'src/app/libs/ui/components/push-notification/push-notification.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoaderModule } from "../../../libs/ui/components/loader/loader.module";
import { ReservationDetailComponent } from './components/reservation-detail/reservation-detail.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CouponDetailComponent } from './components/coupon-detail/coupon-detail.component';
import { CouponCardComponent } from './components/coupon-card/coupon-card.component';
import { LanguagePanelModule } from 'src/app/libs/ui/components/language-panel/language-panel.module';
import { EventTableComponent } from './components/event-table/event-table.component';
import { FlightTableComponent } from './components/flight-table/flight-table.component';
import { HotelTableComponent } from './components/hotel-table/hotel-table.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { I18nModule } from 'src/app/core/languages-support/i18n.module';
import { AppSupportedLanguages } from 'src/app/core/languages-support/i18n-config.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SaveViewModule } from 'src/app/libs/ui/components/save-view/save-view.module';
import { MatSelectModule } from '@angular/material/select';
@NgModule({
    declarations: [AdminDashboardComponent, DashboardHeaderComponent, SidePanelComponent, OrdersTableComponent, FlightDetailComponent, HotelDetailComponent, EventDetailComponent, SingleEventComponent, SingleFlightComponent, SingleHotelComponent, SingleOrderComponent, CommentsPopupComponent, OrderViewComponent, ReservationDetailComponent, CouponDetailComponent, CouponCardComponent, EventTableComponent, FlightTableComponent, HotelTableComponent, EventTableComponent, FlightTableComponent, HotelDetailComponent],
    imports: [
        CommonModule,
        DashboardDataAccessModule,
        MatTableModule,
        MatBadgeModule,
        HighchartsChartModule,
        MatCheckboxModule,
        DateRangeModule,
        DateSelectorModule,
        ChartTypeModule,
        FiltersDropdownModule,
        PriceSelectorModule,
        ButtonModule,
        SmsNotificationModule,
        EmailNotificationModule,
        PushNotificationModule,
        LoaderModule,
        ReactiveFormsModule,
        LanguagePanelModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatNativeDateModule,
        I18nModule.forRoot(AppSupportedLanguages),
        MatTooltipModule,
        SaveViewModule,
        MatSelectModule,
    ]
})
export class AdminDashboardModule { }
