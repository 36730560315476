import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ViewOrderComponent } from './view-order.component';
import { ButtonModule } from '../button/button.module';
import { I18nModule } from 'src/app/core/languages-support/i18n.module';
import { AppSupportedLanguages } from 'src/app/core/languages-support/i18n-config.service';

@NgModule({
  declarations: [ViewOrderComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    I18nModule.forRoot(AppSupportedLanguages), 
  ],
  exports:[ViewOrderComponent]
})
export class ViewOrderModule { }
