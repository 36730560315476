import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { dashboardReducer,dashboardStateFeatureKey } from "./store/dashboard.reducer";
import { DashboardEffects } from "./store/dashboard.effect";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(dashboardStateFeatureKey, dashboardReducer),
    EffectsModule.forFeature([DashboardEffects]),
  ],
})
export class DashboardDataAccessModule {}