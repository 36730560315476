<div class="evt-popup">
    <div class="evt-container">
        <div class="close-popup" (click)="closePopup()">
            <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g _ngcontent-nbx-c129="" opacity="0.5">
                <path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path _ngcontent-nbx-c129="" d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g>
            </svg>
        </div>
        <h4>{{'dashboard.orderDetailPage.eventSummary' | i18n}}</h4>
        <div class="card-body">
            <div class="table-responsive" *ngIf="order">
                <table class="event-table">
                    <thead>
                    <tr>
                        <th>{{'dashboard.orderDetailPage.eventId' | i18n}}</th>
                        <th>{{'dashboard.orderDetailPage.eventName' | i18n}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let event of order.ticketsOrders">
                        <td>{{ event.orderId }}</td>
                        <td>{{ event.name }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>