import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DashboardState } from "../model";
import { dashboardStateFeatureKey } from "./dashboard.reducer";

export const dashboardFeatureSelector = createFeatureSelector<DashboardState>(dashboardStateFeatureKey);

export const getOrders = createSelector(
    dashboardFeatureSelector,
  (state: DashboardState) => state?.orders 
);
export const getEvents = createSelector(
  dashboardFeatureSelector,
  (state: DashboardState) => state?.events 
);
export const getFlights = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.flights 
);
export const getHotels = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.hotels 
);
export const getOrderDetail = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.orderDetail 
);
export const getEventDetail = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.eventDetail 
);
export const getFlightDetail = createSelector(
  dashboardFeatureSelector,
  (state: DashboardState) => state?.flightDetail 
);
export const getHotelDetail = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.hotelDetail 
);
export const getPackagesInfo = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.packagesInfo 
);
export const getPersonsInfo = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.personsInfo 
);

export const getPopularAirlineInfo = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.popularAirlinesInfo 
);

export const getFlightsIncomeInfo = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.flightsIncomeInfo 
);

export const getFlightsOutcomeInfo = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.flightsOutcomeInfo 
);

export const getFlightsProfitInfo = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.flightsProfitInfo 
);
export const getPopularHotelsInfo = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.popularHotelsInfo 
);

export const getHotelsIncomeInfo = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.hotelsIncomeInfo 
);

export const getHotelsOutcomeInfo = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.hotelsOutcomeInfo 
);

export const getHotelsProfitInfo = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.hotelsProfitInfo 
);
export const getPopularEventsInfo = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.popularEventsInfo 
);

export const getEventsIncomeInfo = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.eventsIncomeInfo 
);

export const getEventsOutcomeInfo = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.eventsOutcomeInfo 
);

export const getEventsProfitInfo = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.eventsProfitInfo 
);

export const getReservationIncome = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.reservationIncome 
);

export const getReservationOutcome = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.reservationOutcome 
);

export const getEmailObj = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.emailObj 
);

export const getSmsObj = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.smsObj 
);

export const getPushObj = createSelector(
  dashboardFeatureSelector,
(state: DashboardState) => state?.pushObj 
);

export const getLoading = createSelector(
    dashboardFeatureSelector,
    (state: DashboardState) => state?.loading 
);

export const getLoaded = createSelector(
    dashboardFeatureSelector,
    (state: DashboardState) => state?.loaded 
);

export const getError = createSelector(
    dashboardFeatureSelector,
    (state: DashboardState) => state?.error
  );
  
  export const getNumberOfOrders = createSelector(
      dashboardFeatureSelector,
    (state: DashboardState) => state?.numberOfOrders 
  );

  export const getCoupons = createSelector(
    dashboardFeatureSelector,
  (state: DashboardState) => state?.coupons 
);

  export const getCouponInfo = createSelector(
    dashboardFeatureSelector,
  (state: DashboardState) => state?.CouponInfo 
);  

export const getSaveViewInfo = createSelector(
    dashboardFeatureSelector,
  (state: DashboardState) => state?.SaveViewInfo 
);

export const  getOrdersView = createSelector(
    dashboardFeatureSelector,
  (state: DashboardState) => state?.OrdersView 
);

export const  getEventsView = createSelector(
    dashboardFeatureSelector,
  (state: DashboardState) => state?.EventsView
);

export const  getFlightsView = createSelector(
    dashboardFeatureSelector,
  (state: DashboardState) => state?.FlightsView 
);

export const  getHotelsView = createSelector(
    dashboardFeatureSelector,
  (state: DashboardState) => state?.HotelsView 
);

export const  getReservationsView = createSelector(
    dashboardFeatureSelector,
  (state: DashboardState) => state?.ReservationsView 
);

export const DashboardSelectors = {
  getOrders,
  getEvents,
  getFlights,
  getHotels,
  getOrderDetail,
  getEventDetail,
  getFlightDetail,
  getHotelDetail,
  getPackagesInfo,
  getPersonsInfo,
  getNumberOfOrders,
  getPopularAirlineInfo,
  getFlightsIncomeInfo,
  getFlightsOutcomeInfo,
  getFlightsProfitInfo,
  getPopularHotelsInfo,
  getHotelsIncomeInfo,
  getHotelsOutcomeInfo,
  getHotelsProfitInfo,
  getPopularEventsInfo,
  getEventsIncomeInfo,
  getEventsOutcomeInfo,
  getEventsProfitInfo,
  getReservationIncome,
  getReservationOutcome,
  getEmailObj,
  getSmsObj,
  getPushObj,
  getCoupons,
  getCouponInfo,
  getSaveViewInfo,
  getOrdersView,
  getEventsView,
  getFlightsView,
  getHotelsView,
  getReservationsView,
  getLoading,
  getLoaded,
  getError
};