import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatNativeDateModule, NativeDateModule } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from './core/environments/environment';
import { AppSupportedLanguages } from './core/languages-support/i18n-config.service';
import { I18nModule } from './core/languages-support/i18n.module';
import { I18nPipe } from './core/languages-support/i18n.pipe';
import { I18nService } from './core/languages-support/i18n.service';
import { ToolbarModule } from './libs/ui/components/toolbar/toolbar.module';
import { NavbarComponent } from './libs/ui/components/navbar/navbar.component';
import { FooterComponent } from './libs/ui/components/footer/footer.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CurrencyPanelModule } from './libs/ui/components/currency-panel/currency-panel.module';
import { ViewOrderModule } from './libs/ui/components/view-order/view-order.module';
import { ApiInterceptorService } from './core/interceptor/api-interceptor.service';
import { LoginModule } from './pages/login-page/ui/login.module';
import { AdminDashboardModule } from './pages/admin-dashboard/ui/admin-dashboard.module';
import { SignupModule } from './pages/signup-page/ui/signup.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LanguagePanelModule } from './libs/ui/components/language-panel/language-panel.module';

const NgRxLogs = !environment.isProd ? [StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.isProd })] : [];
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NativeDateModule,
    I18nModule.forRoot(AppSupportedLanguages),
    ToolbarModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    MatNativeDateModule,
    HttpClientModule,
    MatDialogModule,
    CurrencyPanelModule,
    ViewOrderModule,
    LoginModule,
    AdminDashboardModule,
    LanguagePanelModule,
    SignupModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: true }),
    ...NgRxLogs,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule { }

export const TranslatePipe = new I18nPipe(new I18nService(AppSupportedLanguages));
