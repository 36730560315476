import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { QueryBase } from 'src/app/libs/data-access/store/shared/query-base.interface';
import { DashboardState } from '../model';
import { DashboardSelectors, dashboardFeatureSelector } from '../store/dashboard.selectors';
import { dashboardStateActions } from '../store/dashboard.action';

@Injectable({
  providedIn: 'root'
})
export class DashboardQuery implements QueryBase {
  orders$ = this.dashboardStore.select(DashboardSelectors.getOrders);  
  events$ = this.dashboardStore.select(DashboardSelectors.getEvents);
  flights$ = this.dashboardStore.select(DashboardSelectors.getFlights);
  hotels$ = this.dashboardStore.select(DashboardSelectors.getHotels);
  orderDetail$ = this.dashboardStore.select(DashboardSelectors.getOrderDetail);
  eventDetail$ = this.dashboardStore.select(DashboardSelectors.getEventDetail);
  flightDetail$ = this.dashboardStore.select(DashboardSelectors.getFlightDetail);
  hotelDetail$ = this.dashboardStore.select(DashboardSelectors.getHotelDetail);
  packagesInfo$ = this.dashboardStore.select(DashboardSelectors.getPackagesInfo);
  personsInfo$ = this.dashboardStore.select(DashboardSelectors.getPersonsInfo);
  numberOfOrders$ = this.dashboardStore.select(DashboardSelectors.getNumberOfOrders);
  popularAirlinesInfo$ = this.dashboardStore.select(DashboardSelectors.getPopularAirlineInfo);
  flightsIncomeInfo$ = this.dashboardStore.select(DashboardSelectors.getFlightsIncomeInfo);
  flightsOutcomeInfo$ = this.dashboardStore.select(DashboardSelectors.getFlightsOutcomeInfo);
  flightsProfitInfo$ = this.dashboardStore.select(DashboardSelectors.getFlightsProfitInfo);
  popularHotelsInfo$ = this.dashboardStore.select(DashboardSelectors.getPopularHotelsInfo);
  hotelsIncomeInfo$ = this.dashboardStore.select(DashboardSelectors.getHotelsIncomeInfo);
  hotelsOutcomeInfo$ = this.dashboardStore.select(DashboardSelectors.getHotelsOutcomeInfo);
  hotelsProfitInfo$ = this.dashboardStore.select(DashboardSelectors.getHotelsProfitInfo);
  popularEventsInfo$ = this.dashboardStore.select(DashboardSelectors.getPopularEventsInfo);
  eventsIncomeInfo$ = this.dashboardStore.select(DashboardSelectors.getEventsIncomeInfo);
  eventsOutcomeInfo$ = this.dashboardStore.select(DashboardSelectors.getEventsOutcomeInfo);
  eventsProfitInfo$ = this.dashboardStore.select(DashboardSelectors.getEventsProfitInfo);
  reservationIncome$ = this.dashboardStore.select(DashboardSelectors.getReservationIncome);
  reservationOutcome$ = this.dashboardStore.select(DashboardSelectors.getReservationOutcome);
  emailObj$ = this.dashboardStore.select(DashboardSelectors.getEmailObj);
  smsObj$ = this.dashboardStore.select(DashboardSelectors.getSmsObj);
  pushObj$ = this.dashboardStore.select(DashboardSelectors.getPushObj);
  coupons$ = this.dashboardStore.select(DashboardSelectors.getCoupons);
  CouponInfo$ = this.dashboardStore.select(DashboardSelectors.getCouponInfo);
  SaveViewInfo$ = this.dashboardStore.select(DashboardSelectors.getSaveViewInfo);
  OrdersView$ = this.dashboardStore.select(DashboardSelectors.getOrdersView);
  EventsView$ = this.dashboardStore.select(DashboardSelectors.getEventsView);
  FlightsView$ = this.dashboardStore.select(DashboardSelectors.getFlightsView);
  HotelsView$ = this.dashboardStore.select(DashboardSelectors.getHotelsView);
  ReservationsView$ = this.dashboardStore.select(DashboardSelectors.getReservationsView);
  loading$ = this.dashboardStore.select(DashboardSelectors.getLoading);
  loaded$ = this.dashboardStore.select(DashboardSelectors.getLoaded);
  errors$ = this.dashboardStore.select(DashboardSelectors.getError);

  constructor(
    private dashboardStore: Store<DashboardState>,
  ) {}

  getOrders():void{
    this.dashboardStore.dispatch(dashboardStateActions.getOrders());
  } 

  getEvents():void{
    this.dashboardStore.dispatch(dashboardStateActions.getEvents());
  }
  
  getFlights():void{
    this.dashboardStore.dispatch(dashboardStateActions.getFlights());
  }

  getHotels():void{
    this.dashboardStore.dispatch(dashboardStateActions.getHotels());
  }

  getOrderDetail(id: string):void{
    this.dashboardStore.dispatch(dashboardStateActions.getOrderDetail({ id }));
  }

  getEventDetail(id: string):void{
    this.dashboardStore.dispatch(dashboardStateActions.getEventDetail({ id }));
  }
  
  getFlightDetail(id: string):void{
    this.dashboardStore.dispatch(dashboardStateActions.getFlightDetail({ id }));
  }

  getHotelDetail(id: string):void{
    this.dashboardStore.dispatch(dashboardStateActions.getHotelDetail({ id }));
  }

  resetOrderDetail() {
    this.dashboardStore.dispatch(dashboardStateActions.resetOrderDetail());
  }

  resetEventDetail() {
    this.dashboardStore.dispatch(dashboardStateActions.resetEventDetail());
  }

  resetFlightDetail() {
    this.dashboardStore.dispatch(dashboardStateActions.resetFlightDetail());
  }

  resetHotelDetail() {
    this.dashboardStore.dispatch(dashboardStateActions.resetHotelDetail());
  }

  loadPackages(query: any) {
    this.dashboardStore.dispatch(dashboardStateActions.loadPackages({ query }));
  }

  loadPersons(query: any) {
    this.dashboardStore.dispatch(dashboardStateActions.loadPersons({ query }));
  }

  getNumberOfOrders(query: any):void{
    this.dashboardStore.dispatch(dashboardStateActions.weeklyNumberOfOrders({query}));
  }
  loadPopularAirline(query: any) {
    this.dashboardStore.dispatch(dashboardStateActions.loadPopularAirline({ query }));
  }

  loadFlightsIncome(query: any) {
    this.dashboardStore.dispatch(dashboardStateActions.loadFlightsIncome({ query }));
  }
  
  loadFlightsOutcome(query: any) {
    this.dashboardStore.dispatch(dashboardStateActions.loadFlightsOutcome({ query }));
  }
  
  loadFlightsProfit(query: any) {
    this.dashboardStore.dispatch(dashboardStateActions.loadFlightsProfit({ query }));
  }
  loadPopularHotels(query: any) {
    this.dashboardStore.dispatch(dashboardStateActions.loadPopularHotels({ query }));
  }

  loadHotelsIncome(query: any) {
    this.dashboardStore.dispatch(dashboardStateActions.loadHotelsIncome({ query }));
  }
  
  loadHotelsOutcome(query: any) {
    this.dashboardStore.dispatch(dashboardStateActions.loadHotelsOutcome({ query }));
  }
  
  loadHotelsProfit(query: any) {
    this.dashboardStore.dispatch(dashboardStateActions.loadHotelsProfit({ query }));
  }
  loadPopularEvents(query: any) {
    this.dashboardStore.dispatch(dashboardStateActions.loadPopularEvents({ query }));
  }

  loadEventsIncome(query: any) {
    this.dashboardStore.dispatch(dashboardStateActions.loadEventsIncome({ query }));
  }
  
  loadEventsOutcome(query: any) {
    this.dashboardStore.dispatch(dashboardStateActions.loadEventsOutcome({ query }));
  }
  
  loadEventsProfit(query: any) {
    this.dashboardStore.dispatch(dashboardStateActions.loadEventsProfit({ query }));
  }

  sendSms(query: any) {
    this.dashboardStore.dispatch(dashboardStateActions.sendSms({ query }));
  }

  resetSms() {
    this.dashboardStore.dispatch(dashboardStateActions.resetSms());
  }

  sendEmail(query: any) {
    this.dashboardStore.dispatch(dashboardStateActions.sendEmail({ query }));
  }

  resetEmail() {
    this.dashboardStore.dispatch(dashboardStateActions.resetEmail());
  }

  sendPushNotification(query: any) {
    this.dashboardStore.dispatch(dashboardStateActions.sendPushNotification({ query }));
  }

  resetPushNotification() {
    this.dashboardStore.dispatch(dashboardStateActions.resetPushNotification());
  }

  loadReservationIncome(query: any) {
    this.dashboardStore.dispatch(dashboardStateActions.loadReservationIncome({ query }));
  }

  loadReservationOutcome(query: any) {
    this.dashboardStore.dispatch(dashboardStateActions.loadReservationOutcome({ query }));
  }

  updateOrderComment(orderCommentDetail: any) {
    this.dashboardStore.dispatch(
      dashboardStateActions.updateOrderComment({ orderCommentDetail })
    );
  }

  // Coupon imlementation 
  createCoupon(couponDetail: any) {
    this.dashboardStore.dispatch(
      dashboardStateActions.createCoupon({ couponDetail })
    );
  }

  updateCoupon(couponDetail: any) {
    this.dashboardStore.dispatch(
      dashboardStateActions.updateCoupon({ couponDetail })
    );
  }

  getCoupons():void{
    this.dashboardStore.dispatch(dashboardStateActions.getCoupons());
  } 

  deleteCoupon(code: string):void{
    this.dashboardStore.dispatch(dashboardStateActions.deleteCoupon({ code }));
  }

  saveView(saveViewDetail: any) {
    this.dashboardStore.dispatch(
      dashboardStateActions.saveView({ saveViewDetail })
    );
  }
  
  getOrdersView(user:any):void{
    this.dashboardStore.dispatch(dashboardStateActions.getOrdersViewDetail({user}));
  } 

  getEventsView(user:any):void{
    this.dashboardStore.dispatch(dashboardStateActions.getEventsViewDetail({user}));
  } 

  getFlightsView(user:any):void{
    this.dashboardStore.dispatch(dashboardStateActions.getFlightsViewDetail({user}));
  } 

  getHotelsView(user:any):void{
    this.dashboardStore.dispatch(dashboardStateActions.getHotelsViewDetail({user}));
  } 

  getReservationsView(user:any):void{
    this.dashboardStore.dispatch(dashboardStateActions.getReservationsViewDetail({user}));
  } 
}