<div class="evt-popup">
    <div class="evt-container">
        <div class="close-popup" (click)="closePopup()">
            <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g _ngcontent-nbx-c129="" opacity="0.5">
                <path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path _ngcontent-nbx-c129="" d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g>
            </svg>
        </div>
        <h3 class="font-weight-bold">{{'dashboard.orderViewPage.sendEmail.title' | i18n}}</h3>
        <div *ngIf="dashboardFacade.emailObj$ | async as emailObj">
            <div *ngIf="emailSuccess">
                <p class="text-success" *ngIf="emailObj.code == 202; else failed">{{'dashboard.orderViewPage.sendEmail.sentEmail' | i18n}}</p>
                <ng-template #failed>
                    <div *ngIf="emailObj.code != 202">
                        <p class="text-danger" *ngFor="let error of emailObj.errors">
                          {{ error.message }}
                        </p>
                      </div>
                </ng-template>
            </div>
        </div>
        <div class="error" *ngIf=" emailList.length < 1 ">
            <p class="text-danger">{{'dashboard.orderViewPage.sendEmail.failedEmail'  | i18n}}</p>
        </div>
        <div class="popup-body">
            <div class="form">
                <form [formGroup]="myForm" (ngSubmit)="submitForm($event)">
                    <!-- <div>
                        <input type="text" id="emails" placeholder="Enter comma-separated emails"
                        formControlName="emails" [class.is-invalid]="myForm.get('emails').invalid && myForm.get('emails').touched">
                        <div *ngIf="myForm.get('emails').invalid && myForm.get('emails').touched" class="invalid-feedback">
                            <div *ngIf="myForm.get('emails').hasError('required')">Emails are required.</div>
                            <div *ngIf="myForm.get('emails').hasError('invalidEmails')">Please enter valid email address.</div>
                        </div>
                    </div> -->
                    <div>
                        <input type="text" readonly [value]="emailList.join(', ')">
                    </div>
                    <div>
                        <input placeholder="{{'dashboard.orderViewPage.sendEmail.emailPlaceHolder' | i18n}}" type="subject" id="subject" name="subject" formControlName="subject" required>
                    </div>
                    <div class="editor">
                        <!-- <textarea [froalaEditor] placeholder="Message:" id="html" name="html" formControlName="html" required></textarea> -->
                        <angular-editor formControlName="html" [config]="editorConfig"></angular-editor>
                    </div>
                    <button type="submit" [disabled]="!myForm.valid || emailList.length < 1">{{'dashboard.buttons.send' |i18n}}</button>
                </form>
            </div>
        </div>
    </div>
</div>