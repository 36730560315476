import { auditTime, combineLatest, map, Observable } from "rxjs";

export type DashboardStreams = [
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<boolean>,
  Observable<boolean>,
  Observable<Error>
];

export interface DashboardViewModel {
  orders: any;
  events: any;
  flights: any;
  hotels: any;
  orderDetail: any;
  eventDetail: any;
  flightDetail: any;
  hotelDetail: any;
  packagesInfo: any;
  personsInfo: any;
  numberOfOrders:any;
  popularAirlinesInfo: any;
  flightsIncomeInfo:any;
  flightsOutcomeInfo:any;
  flightsProfitInfo:any;
  popularHotelsInfo: any;
  hotelsIncomeInfo:any;
  hotelsOutcomeInfo:any;
  hotelsProfitInfo:any;
  popularEventsInfo: any;
  eventsIncomeInfo:any;
  eventsOutcomeInfo:any;
  eventsProfitInfo:any;
  reservationIncome: any;
  reservationOutcome: any;
  emailObj:any;
  smsObj: any;
  pushObj: any;
  coupons:any;
  CouponInfo:any;
  SaveViewInfo:any;
  OrdersView:any;
  EventsView:any;
  FlightsView:any;
  HotelsView:any;
  ReservationsView:any;
  loading: boolean;
  loaded: boolean;
  error: Error;
}

export function makeDashboardViewModel(streams: DashboardStreams): Observable<Readonly<DashboardViewModel>> {
  const toViewModel = ([ orders, events, flights, hotels, orderDetail, eventDetail, flightDetail, hotelDetail, packagesInfo, personsInfo,numberOfOrders,popularAirlinesInfo, flightsIncomeInfo,flightsOutcomeInfo,flightsProfitInfo,popularHotelsInfo, hotelsIncomeInfo,hotelsOutcomeInfo,hotelsProfitInfo,popularEventsInfo, eventsIncomeInfo,eventsOutcomeInfo,eventsProfitInfo, reservationIncome, reservationOutcome, emailObj, smsObj, pushObj,coupons,CouponInfo,SaveViewInfo,OrdersView,EventsView,FlightsView,HotelsView,ReservationsView,loading, loaded, error ]: [any,any, any, any, any,any, any, any, any, any, any, any, any, any, any, any, any, any, any,any, any,any,any,any, any,any,any,any, any,any, any,any, any, any,any,any ,boolean, boolean, Error]): DashboardViewModel => {
    return {
      orders,
      events,
      flights,
      hotels,
      orderDetail,
      eventDetail,
      flightDetail,
      hotelDetail,
      packagesInfo,
      personsInfo,
      numberOfOrders,
      popularAirlinesInfo,
      flightsIncomeInfo,
      flightsOutcomeInfo,
      flightsProfitInfo,
      popularHotelsInfo,
      hotelsIncomeInfo,
      hotelsOutcomeInfo,
      hotelsProfitInfo,
      popularEventsInfo,
      eventsIncomeInfo,
      eventsOutcomeInfo,
      eventsProfitInfo,
      reservationIncome,
      reservationOutcome,
      emailObj,
      smsObj,
      pushObj,
      coupons,
      CouponInfo,
      SaveViewInfo,
      OrdersView,
      EventsView,
      FlightsView,
      HotelsView,
      ReservationsView,
      loading,
      loaded,
      error
    }
  }

  return combineLatest(streams).pipe(auditTime(0), map(toViewModel))
}