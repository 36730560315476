import { createFeatureSelector, createSelector } from "@ngrx/store";
import { FlightsState } from "../model";
import { FlightsStateFeatureKey } from "./flights.reducer";

const flightsFeatureSelector = createFeatureSelector<FlightsState>(
  FlightsStateFeatureKey
);

const getFlights = createSelector(
  flightsFeatureSelector,
  (state: FlightsState) => state?.entities
);
const getActive = createSelector(
  flightsFeatureSelector,
  (state: FlightsState) => state?.active
);
const getOfferRequestId = createSelector(
  flightsFeatureSelector,
  (state: FlightsState) => state?.offerRequestId
);
const getTotalRecordsLeft = createSelector(
  flightsFeatureSelector,
  (state: FlightsState) => state?.totalRecordsLeft
);
const getTotalRecords = createSelector(
  flightsFeatureSelector,
  (state: FlightsState) => state?.totalRecords
);
const getSortValue = createSelector(
  flightsFeatureSelector,
  (state: FlightsState) => state?.sort
);
const getFilterMetaData = createSelector(
  flightsFeatureSelector,
  (state: FlightsState) => state?.filtersMetadata
);
const getRequestMetaData = createSelector(
  flightsFeatureSelector,
  (state: FlightsState) => state?.requestMetadata
);

const getSelectedQuickFilter = createSelector(
  flightsFeatureSelector,
  (state: FlightsState) => state?.selectedQuickFilterFlight
);

const getLoadMoreActions = createSelector(
  flightsFeatureSelector,
  (state: FlightsState) => state?.loadMoreActions
);
const getLoading = createSelector(
  flightsFeatureSelector,
  (state: FlightsState) => state?.loading
);

const getLoaded = createSelector(
  flightsFeatureSelector,
  (state: FlightsState) => state?.loaded
);

const getErrors = createSelector(
  flightsFeatureSelector,
  (state: FlightsState) => state?.error
);

export const FlightsSelectors = {
  getFlights,
  getActive,
  getOfferRequestId,
  getFilterMetaData,
  getRequestMetaData,
  getTotalRecordsLeft,
  getTotalRecords,
  getSortValue,
  getSelectedQuickFilter,
  getLoadMoreActions,
  getLoading,
  getLoaded,
  getErrors,
};
