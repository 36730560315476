import { Component } from '@angular/core';
import { DashboardFacade } from '../../../data-access/services/dashboard.facade';

@Component({
  selector: 'tic-single-event',
  templateUrl: './single-event.component.html',
  styleUrls: ['./single-event.component.scss']
})
export class SingleEventComponent {

  constructor(public dashboardFacade: DashboardFacade) {
  }
}
