<div class="evt-popup">
    <div class="evt-container">
        <div class="close-popup" (click)="closePopup()">
            <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g _ngcontent-nbx-c129="" opacity="0.5">
                <path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path _ngcontent-nbx-c129="" d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g>
            </svg>
        </div>
        <h3 class="font-weight-bold">{{'dashboard.orderViewPage.sendSms.title' | i18n}}</h3>
        <div *ngIf="dashboardFacade.smsObj$ | async as smsObj">
            <div *ngIf="smsSuccess">
                <div *ngIf="isObjectNotEmpty(smsObj); else error">
                    <p class="text-success" *ngIf="smsObj.code == 201; else failed">{{'dashboard.orderViewPage.sentMessage' | i18n}}</p>
                    <ng-template #failed>
                        <p class="text-danger">{{ smsObj.message }}</p>
                    </ng-template>
                </div>
                <ng-template #error>
                    <p class="text-danger" *ngIf="smsObj.code != 201">{{'dashboard.orderViewPage.failedMessage' | i18n}}</p>
                </ng-template>
             </div>
        </div>
        <div class="error" *ngIf=" phoneList.length < 1 ">
            <p class="text-danger">{{'dashboard.orderViewPage.numberValidatorMessage' | i18n}}</p>
        </div>
        <div class="popup-body">
            <div class="form">
                <form [formGroup]="myForm" (ngSubmit)="submitForm()">
                    <!-- <div>
                      <input placeholder="Phone Number:" type="phone" id="to" name="to" formControlName="to" required>
                      <div *ngIf="myForm.get('to').invalid && myForm.get('to').touched" class="invalid-feedback">
                        <div *ngIf="myForm.get('to').hasError('required')">Phone number is required.</div>
                        <div *ngIf="myForm.get('to').hasError('invalidPhone')">Please enter valid Phone number.</div>
                      </div>
                    </div> -->
                    <div>
                        <input type="text" readonly [value]="phoneList.join(', ')">
                    </div>
                    <div>
                      <textarea placeholder="Message:" id="sms_body" name="sms_body" formControlName="sms_body" required></textarea>
                    </div>
                    <button type="submit" [disabled]="!myForm.valid || phoneList.length < 1">{{'dashboard.buttons.send' | i18n}}</button>
                </form>
            </div>
        </div>
    </div>
</div>