import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { CurrencyService } from '../services/currency.service';
import { currencyStateActions } from './currency.actions';

@Injectable()
export class CurrencyEffects {

  constructor(
    private actions$: Actions,
    private CurrencyService: CurrencyService
  ) { }

  getExchangeRate$ = createEffect(() =>
  this.actions$.pipe(
    ofType(currencyStateActions.getExchangeRate),
    exhaustMap(() => {
      //TODO: complete types
      return this.CurrencyService.getExchangeRate().pipe(
        map((res) => {
          const exchangeRate = res;
          return currencyStateActions.exchangeRateSuccess({
            data: {
              exchangeRate,
            }
          });
        }),
        catchError((error: HttpErrorResponse) => {
          return of(
          );
        })
      );
    })
  )
);
}