import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/app/core/environments/environment";
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  swPushpayload: any;
  readonly VAPID_PUBLIC_KEY = "BOJlnlqkJBG0WzATLmq-_C_O4x9rWn9zrqY0asTRko2Vuq25nbJi9MIGlwqWHx1wWkLvgYERbghh2lbpmf9YglQ";

  constructor(private swPush: SwPush, private httpClient: HttpClient) {}

  subscribeToNotifications(): void {
    if (this.swPush.isEnabled && Notification.permission === 'granted') {
      const storedSubscription = this.getStoredSubscription();
      if (storedSubscription) {
      } else {
        this.requestSubscription();
      }
    } else if (Notification.permission === 'default') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          this.requestSubscription();
        }
      });
    }
  }

  unsubscribeFromPushNotifications(): void {
    this.swPush
      .unsubscribe()
      .then(() => {
        this.removeStoredSubscription();
      })
      .catch(error => {
        console.error('Error unsubscribing from push notifications:', error);
      });
  }

  subscribeMessage(): void {
    this.swPush.messages.subscribe((res: any) => {
    });
  }

  private requestSubscription(): void {
    this.swPush
      .requestSubscription({
        serverPublicKey: this.VAPID_PUBLIC_KEY
      })
      .then((sub: PushSubscription) => {
        this.saveSubscription(sub);
        this.storeSubscription(sub);
      })
      .catch((err: any) => console.error('Could not subscribe to notifications', err));
  }

  private saveSubscription(sub: PushSubscription): void {
    this.httpClient.post(
      `${environment.ms_notifications_base_url}/create-subscription`,
       sub
    ).pipe(map((res: any) => res))
    .subscribe(
      response => {
      },
      error => {
        console.error('Error saving subscription', error);
      }
    );
  }

  private storeSubscription(sub: PushSubscription): void {
    localStorage.setItem('pushSubscription', JSON.stringify(sub));
  }

  private getStoredSubscription(): PushSubscription | null {
    const subJson = localStorage.getItem('pushSubscription');
    return subJson ? JSON.parse(subJson) : null;
  }

  private removeStoredSubscription(): void {
    localStorage.removeItem('pushSubscription');
  }
}
