import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, of, throwError } from "rxjs";
import { environment } from "src/app/core/environments/environment";
import { RouterService } from "src/app/libs/data-access/services/router/router.service";

@Injectable({
  providedIn: "root",
})

export class EventsService {

  constructor(private HttpClient: HttpClient, private routerService: RouterService) { }

  getFilterOptions(filterOptionsRequest: any) {
    //TODO: complete types and get it as props
    return this.HttpClient.get(
      `${environment.ms_events_base_url}/search/loadAllFilterOptions?country=${filterOptionsRequest.country}&city=${filterOptionsRequest.city}&fromDate=${filterOptionsRequest.fromDate}&toDate=${filterOptionsRequest.toDate}&sportId=${filterOptionsRequest.sportId}`
    ).pipe(map((res: any) => res));
  }

  getEvents(getEventsRequest: any) {
    //TODO: complete types and get it as props
    return this.HttpClient.post(
      `${environment.ms_events_base_url}/search/dateRange`,
      getEventsRequest
    ).pipe(map((res: any) => res),
      catchError((_error) => {
        this.getFilterFailed();
        return throwError('An error occurred while fetching filter options');
      }));
  }

  listEvents(listEventsRequest: any) {
    //TODO: complete types and get it as props
    return this.HttpClient.post(
      `${environment.ms_events_base_url}/search/filterAndSortEvents`,
      listEventsRequest
    ).pipe(map((res: any) => res),
      catchError((_error) => {
        this.getFilterFailed();
        return throwError('An error occurred while fetching filter options');
      }));
  }

  filterEvents(eventFiltersData: any) {
    //TODO: complete types and get it as props
    return this.HttpClient.post(
      `${environment.ms_events_base_url}/search/filterAndSortEvents`,
      eventFiltersData
    ).pipe(map((res: any) => res),
      catchError((_error) => {
        this.getFilterFailed();
        return throwError('An error occurred while fetching filter options');
      }));
  }
  
  getFilterFailed() {
    this.routerService.setFilterState(true);
  }
}
