import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { QueryBase } from "src/app/libs/data-access/store/shared/query-base.interface";
import { EventsState } from "../model";
import { EventsStateActions } from "../store";
import { EventsSelectors } from "../store/events.selectors";
import { IFilter } from "src/app/libs/ui/interfaces/filters.interface";

@Injectable({
  providedIn: "root",
})

export class EventsQuery implements QueryBase {

  events$ = this.eventsStore.select(EventsSelectors.getEvents);
  flights$ = this.eventsStore.select(EventsSelectors.getFlights);
  hotels$ = this.eventsStore.select(EventsSelectors.getHotels);
  ids$ = this.eventsStore.select(EventsSelectors.getIds);
  page$ = this.eventsStore.select(EventsSelectors.getPage);

  totalLeftToFetch$ = this.eventsStore.select(
    EventsSelectors.getTotalLeftToFetch
  );

  totalRecords$ = this.eventsStore.select(
    EventsSelectors.getTotalRecords
  );

  sort$ = this.eventsStore.select(EventsSelectors.getSortValue);
  loadMoreAction$ = this.eventsStore.select(EventsSelectors.getLoadMoreActions);
  loading$ = this.eventsStore.select(EventsSelectors.getLoading);
  loaded$ = this.eventsStore.select(EventsSelectors.getLoaded);
  filtersMetadata$ = this.eventsStore.select(EventsSelectors.getFiltersMetadata);

  selectedFilters$ = this.eventsStore.select(
    EventsSelectors.getSelectedFilters
  );

  selectedQuickFilter$ = this.eventsStore.select(
    EventsSelectors.getSelectedQuickFilter
  );

  active$ = this.eventsStore.select(EventsSelectors.getActive);
  errors$ = this.eventsStore.select(EventsSelectors.getErrors);

  constructor(private eventsStore: Store<EventsState>) {}  

  loadFilterOptions(requestQuery: any): void {
    this.eventsStore.dispatch(
      EventsStateActions.loadEventFilterOptions({ requestQuery })
    );
  }

  saveFilterConfiguration(metadata: IFilter<any>[]): void {
    //TODO: Complete type
    this.eventsStore.dispatch(
      EventsStateActions.saveFilterConfiguration({ metadata })
    );
  }

  setSelectedFilters(selectedFilters: any): void {
    //TODO: Complete type
    this.eventsStore.dispatch(
      EventsStateActions.setSelectedFilters({ selectedFilters })
    );
  }

  loadInitialEventData(requestQuery: any): void {
    //TODO: Complete type
    this.eventsStore.dispatch(
      EventsStateActions.loadInitialEventsData({ requestQuery })
    );
  }

  loadFilteredEventData(requestQuery: any): void {
    //TODO: Complete type
    this.eventsStore.dispatch(
      EventsStateActions.loadFilteredEventsData({ requestQuery })
    );
  }

  loadMoreEvents(requestQuery: any): void {
    //TODO: Complete type
    this.eventsStore.dispatch(
      EventsStateActions.loadMoreEventsData(requestQuery)
    );
  }

  setQuickFilter(selectedQuickFilterEvent: any): void {
    //TODO: Complete type
    this.eventsStore.dispatch(
      EventsStateActions.setQuickFilterEvent({ selectedQuickFilterEvent})
    );
  }
  
}
