import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CurrencyState } from "../../../models";
import { CurrencyStateFeatureKey } from "./currency.reducer";

export const currencyFeatureSelector = createFeatureSelector<CurrencyState>(CurrencyStateFeatureKey);

export const getExchangeRate = createSelector(
  currencyFeatureSelector,
  (state: CurrencyState) => state?.exchangeRate 
);

export const getFrom = createSelector(
    currencyFeatureSelector,
    (state: CurrencyState) => state?.from 
);

export const getTo = createSelector(
    currencyFeatureSelector,
    (state: CurrencyState) => state?.to 
);

export const getLoading = createSelector(
    currencyFeatureSelector,
    (state: CurrencyState) => state?.loading 
);

export const getLoaded = createSelector(
    currencyFeatureSelector,
    (state: CurrencyState) => state?.loaded 
);
export const getError = createSelector(
    currencyFeatureSelector,
    (state: CurrencyState) => state?.error
);
export const getCurrentRate = createSelector(
    currencyFeatureSelector,
    (state: CurrencyState) => state?.currentCurrency,
);

export const CurrencySelectors = {
  getExchangeRate,
  getCurrentRate,
  getTo,
  getFrom,
  getLoading,
  getLoaded,
  getError
};
