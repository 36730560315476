export enum FilterType {
    Checkbox = "checkbox",
    Textbox = "textbox",
    Slider = "slider",
    Star = "star",
    // Add more filter types as needed
  }
  
  export enum ValueType {
    Number = "number",
    String = "string",
    Time = "time",
    Duration = "duration"
    // Add more value types as needed
  }