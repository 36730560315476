import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { CurrencyReducer,CurrencyStateFeatureKey } from "./store/currency.reducer";
import { CurrencyEffects } from "./store/currency.effect";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(CurrencyStateFeatureKey, CurrencyReducer),
    EffectsModule.forFeature([CurrencyEffects]),
  ],
})
export class CurrencyDataAccessModule {}
