import { createFeatureSelector, createSelector } from "@ngrx/store";
import { EventsStateFeatureKey } from "./events.reducer";
import { EventsState } from "../model/events-state";

const eventsFeatureSelector = createFeatureSelector<EventsState>(
  EventsStateFeatureKey
);

const getEvents = createSelector(
  eventsFeatureSelector,
  (state: EventsState) => state?.entities
);

const getFlights = createSelector(
  eventsFeatureSelector,
  (state: EventsState) => state?.flights
);

const getHotels = createSelector(
  eventsFeatureSelector,
  (state: EventsState) => state?.hotels
);
const getIds = createSelector(
  eventsFeatureSelector,
  (state: EventsState) => state?.ids
);

const getTotalLeftToFetch = createSelector(
  eventsFeatureSelector,
  (state: EventsState) => state?.totalLeftToFetch
);

const getTotalRecords = createSelector(
  eventsFeatureSelector,
  (state: EventsState) => state?.totalRecords
);

const getPage = createSelector(
  eventsFeatureSelector,
  (state: EventsState) => state?.page
);
const getSortValue = createSelector(
  eventsFeatureSelector,
  (state: EventsState) => state?.sort
);

const getLoading = createSelector(
  eventsFeatureSelector,
  (state: EventsState) => state?.loading
);

const getLoaded = createSelector(
  eventsFeatureSelector,
  (state: EventsState) => state?.loaded
);

const getFiltersMetadata = createSelector(
  eventsFeatureSelector,
  (state: EventsState) => state?.filtersMetadata
);

const getActive = createSelector(
  eventsFeatureSelector,
  (state: EventsState) => state?.active
);

const getSelectedFilters = createSelector(
  eventsFeatureSelector,
  (state: EventsState) => state?.selectedFilters
);

const getSelectedQuickFilter = createSelector(
  eventsFeatureSelector,
  (state: EventsState) => state?.selectedQuickFilterEvent
);

const getLoadMoreActions = createSelector(
  eventsFeatureSelector,
  (state: EventsState) => state?.loadMoreActions
);

const getErrors = createSelector(
  eventsFeatureSelector,
  (state: EventsState) => state?.error
);

export const EventsSelectors = {
  getEvents,
  getFlights,
  getHotels,
  getActive,
  getIds,
  getTotalLeftToFetch,
  getTotalRecords,
  getPage,
  getSelectedFilters,
  getSelectedQuickFilter,
  getSortValue,
  getLoadMoreActions,
  getLoading,
  getLoaded,
  getFiltersMetadata,
  getErrors,
};
