import { createActionGroup, props, ActionCreator, emptyProps } from "@ngrx/store";

export const dashboardStateActions = createActionGroup({
  source: 'State/API',
  events: {
    'Get Orders': emptyProps(),
    'Orders Success': props<{ data: any }>(),    
    'Get Events': emptyProps(),
    'Events Success': props<{ data: any }>(),
    'Get Flights': emptyProps(),
    'Flights Success': props<{ data: any }>(),
    'Get Hotels': emptyProps(),
    'Hotels Success': props<{ data: any }>(),
    'Get Order Detail': props<{ id: string }>(),
    'Order Detail Success': props<{ data: any }>(),
    'Get Event Detail': props<{ id: string }>(),
    'Event Detail Success': props<{ data: any }>(),
    'Get Flight Detail': props<{ id: string }>(),
    'Flight Detail Success': props<{ data: any }>(),
    'Get Hotel Detail': props<{ id: string }>(),
    'Hotel Detail Success': props<{ data: any }>(),
    'Reset Order Detail': emptyProps(),
    'Reset Event Detail': emptyProps(),
    'Reset Flight Detail': emptyProps(),
    'Reset Hotel Detail': emptyProps(),
    'Load Packages': props<{ query: string }>(),
    'Load Packages Success': props<{ data: any }>(),
    'Load Persons': props<{ query: string }>(),
    'Load Persons Success': props<{ data: any }>(),

    'Weekly Number Of Orders': props<{ query: string }>(),
    'Weekly Number Of Orders Success': props<{ data: any }>(),
    'Weekly Number Of Orders Failure': props<{ error: any }>(),
    
    // load popular airlines
    "Load Popular Airline": props<{ query: any }>(),
    "Load Popular Airline Success": props<{ data: any }>(),
    "Load Popular Airline Failure": props<{ error: any }>(),

    // load flights income
    "Load Flights Income": props<{ query: any }>(),
    "Load Flights Income Success": props<{ data: any }>(),
    "Load Flights Income Failure": props<{ error: any }>(),

    // load flights outcome
    "Load Flights Outcome": props<{ query: any }>(),
    "Load Flights Outcome Success": props<{ data: any }>(),
    "Load Flights Outcome Failure": props<{ error: any }>(),

    // load flights outcome
    "Load Flights Profit": props<{ query: any }>(),
    "Load Flights Profit Success": props<{ data: any }>(),
    "Load Flights Profit Failure": props<{ error: any }>(),

    // load popular hotel
    "Load Popular Hotels": props<{ query: any }>(),
    "Load Popular Hotels Success": props<{ data: any }>(),
    "Load Popular Hotels Failure": props<{ error: any }>(),

    // load hotel income
    "Load Hotels Income": props<{ query: any }>(),
    "Load Hotels Income Success": props<{ data: any }>(),
    "Load Hotels Income Failure": props<{ error: any }>(),

    // load hotel outcome
    "Load Hotels Outcome": props<{ query: any }>(),
    "Load Hotels Outcome Success": props<{ data: any }>(),
    "Load Hotels Outcome Failure": props<{ error: any }>(),

    // load hotel profit
    "Load Hotels Profit": props<{ query: any }>(),
    "Load Hotels Profit Success": props<{ data: any }>(),
    "Load Hotels Profit Failure": props<{ error: any }>(),
    // load popular events
    "Load Popular Events": props<{ query: any }>(),
    "Load Popular Events Success": props<{ data: any }>(),
    "Load Popular Events Failure": props<{ error: any }>(),

    // load events income
    "Load Events Income": props<{ query: any }>(),
    "Load Events Income Success": props<{ data: any }>(),
    "Load Events Income Failure": props<{ error: any }>(),

    // load events outcome
    "Load Events Outcome": props<{ query: any }>(),
    "Load Events Outcome Success": props<{ data: any }>(),
    "Load Events Outcome Failure": props<{ error: any }>(),

    // load events profit
    "Load Events Profit": props<{ query: any }>(),
    "Load Events Profit Success": props<{ data: any }>(),
    "Load Events Profit Failure": props<{ error: any }>(),

    // load reservation income
    "Load Reservation Income": props<{ query: any }>(),
    "Load Reservation Income Success": props<{ data: any }>(),
    "Load Reservation Income Failure": props<{ error: any }>(),

    // load reservation outcome
    "Load Reservation Outcome": props<{ query: any }>(),
    "Load Reservation Outcome Success": props<{ data: any }>(),
    "Load Reservation Outcome Failure": props<{ error: any }>(),

    "Send Sms": props<{ query: any }>(),
    "Send Sms Success": props<{ data: any }>(),
    "Reset Sms": emptyProps(),
    "Send Email": props<{ query: any }>(),
    "Reset Email": emptyProps(),
    "Send Email Success": props<{ data: any }>(),
    "Send Push Notification": props<{ query: any }>(),
    "Reset Push Notification": emptyProps(),
    "Send Push Notification Success": props<{ data: any }>(),

    "Update Order Comment": props<{ orderCommentDetail: any }>(),
    "Update Order Comment Success": props<{
      data: {
        UpdateOrderInfo: any;
      };
    }>(),
    "Update Order Comment Failure": props<{ error: any }>(),
    //Coupon imlementation 

    //Add

    "Create Coupon": props<{ couponDetail: any }>(),
    "Create Coupon Success": props<{
      data: {
        couponInfo: any;
      };
    }>(),
    "Create Coupon Failure": props<{ error: any }>(),

    //update

    "Update Coupon": props<{ couponDetail: any }>(),
    "Update Coupon Success": props<{
      data: {
        couponInfo: any;
      };
    }>(),
    "Update Coupon Failure": props<{ error: any }>(),

    //Get All
    
     "Get Coupons": emptyProps(),
     "Get Coupons Success": props<{data: any}>(),
     "Get Coupons Failure": props<{ error: any }>(),

    // Delete 

    'Delete Coupon': props<{ code: string }>(),
    'Delete Coupon Success': props<{ 
      data: {
      couponInfo: any;
    }; 
  }>(),
    'Delete Coupon Failure': props<{ error: any }>(),
   
    // Save View Implementation

    "Save View": props<{ saveViewDetail: any }>(),
    "Save View Success": props<{
      data: {
        SaveViewInfo: any;
      };
    }>(),
    "Save View Failure": props<{ error: any }>(),

    'Get Orders View Detail': props<{ user: any }>(),
    'Get Orders View Success': props<{ data: any }>(),
    "Get Orders View Failure": props<{ error: any }>(),

    'Get Events View Detail': props<{ user: any }>(),
    'Get Events View Success': props<{ data: any }>(),
    "Get Events View Failure": props<{ error: any }>(),

    'Get Flights View Detail': props<{ user: any }>(),
    'Get Flights View Success': props<{ data: any }>(),
    "Get Flights View Failure": props<{ error: any }>(),

    'Get Hotels View Detail': props<{ user: any }>(),
    'Get Hotels View Success': props<{ data: any }>(),
    "Get Hotels View Failure": props<{ error: any }>(),

    'Get Reservations View Detail': props<{ user: any }>(),
    'Get Reservations View Success': props<{ data: any }>(),
    "Get Reservations View Failure": props<{ error: any }>(),
  }
});