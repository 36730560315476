<div [ngClass]="{'view-order': true}" appOutside>
  <div class="vo-container container">
    <div class="close-popup">
      <svg (click)="closePopupVo()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
        fill="none">
        <g opacity="0.5">
          <path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </g>
      </svg>
    </div>
    <div class="row">
      <div class="col-lg-6 vo-logo">
        <img src="../../../../../assets/images/vo-logo.png">
      </div>
      <div class="col-lg-6">
        <form class="vo-form" [formGroup]="orderSearchFormGroup" (ngSubmit)="onSubmitForm()" #myForm="ngForm">
          <div class="vo-formfield">
            <label for="orderid">{{'viewOrder.idInputText'  | i18n}}</label>
            <input type="text" name="orderid" formControlName="orderIdControl" />
            <div *ngIf="
                this.orderSearchFormGroup.get('orderIdControl').invalid &&
                (this.orderSearchFormGroup.get('orderIdControl').dirty || this.orderSearchFormGroup.get('orderIdControl').touched)
              " class="text-danger errorText">
              {{ checkError(this.orderSearchFormGroup.get('orderIdControl'),'viewOrder.validatorMessage.idRequired') | i18n}}
            </div>
            <div *ngIf="
                this.orderSearchFormGroup.get('orderIdControl').invalid &&
                (this.orderSearchFormGroup.get('orderIdControl').dirty || this.orderSearchFormGroup.get('orderIdControl').touched)
              " class="text-danger errorText">
              {{ checkLengthError(this.orderSearchFormGroup.get('orderIdControl')) | i18n }}
            </div>
          </div>
          <div class="vo-formfield">
            <label for="email">{{'viewOrder.emailInputText'  | i18n}}</label>
            <input type="text" name="email" formControlName="emailControl" />
            <div *ngIf="
                this.orderSearchFormGroup.get('emailControl').invalid &&
                (this.orderSearchFormGroup.get('emailControl').dirty || this.orderSearchFormGroup.get('emailControl').touched)
              " class="text-danger errorText">
              {{ checkError(this.orderSearchFormGroup.get('emailControl'),'viewOrder.validatorMessage.emailRequired') | i18n }}
            </div>
            <div *ngIf="
                this.orderSearchFormGroup.get('emailControl').invalid &&
                (this.orderSearchFormGroup.get('emailControl').dirty || this.orderSearchFormGroup.get('emailControl').touched)
              " class="text-danger errorText">
              {{ checkValidEmailError(this.orderSearchFormGroup.get('emailControl')) | i18n }}
            </div>
          </div>
          <tic-button className="bg-dark w-100 blackButton" buttonText="viewOrder.btnText"></tic-button>
        </form>
      </div>
    </div>
  </div>
</div>