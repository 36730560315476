import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, of } from "rxjs";
import { environment } from "src/app/core/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CurrencyService {

  baseUrl = 'https://trripi-stg.com/orders/orders'

  constructor(private HttpClient: HttpClient) {}

  getExchangeRate() {
    return this.HttpClient.get(
      `${this.baseUrl}/get-exchange-rates/all`
    ).pipe(map((res: any) => res.rates));
  }
}
