import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, of } from "rxjs";
import { environment } from "src/app/core/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DashboardService {

  baseUrl = 'https://trripi-stg.com'

  constructor(private HttpClient: HttpClient) {}

  getOrders() {
    return this.HttpClient.get(
      `${this.baseUrl}/admin/dashboard/all`
    ).pipe(map((res: any) => res));
  }

  getNumberOfOrders(params: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/orders/orders/graph/weekly-orders-amount`,params,
    ).pipe(map((res: any) => res));
  }

  getPlatform(params: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/orders/orders/admin/lists`, params,
      {}
    ).pipe(map((res: any) => res));
  }

  getOrderDetail(params: any) {
    return this.HttpClient.get(
      `${this.baseUrl}/admin/dashboard/details/${params.id}`
    ).pipe(map((res: any) => res));
  }

  getEventDetail(params: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/admin/dashboard/event-details`, params,
      {}
    ).pipe(map((res: any) => res));
  }

  getFlightDetail(params: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/admin/dashboard/flight-details`, params,
      {}
    ).pipe(map((res: any) => res));
  }

  getHotelDetail(params: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/admin/dashboard/hotel-details`, params,
      {}
    ).pipe(map((res: any) => res));
  }

  getPackagesInfo(query: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/orders/orders/graph/packages`, query,
      {}
    ).pipe(map((res: any) => res));
  }

  getPersonsInfo(query: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/orders/orders/graph/total-persons`, query,
      {}
    ).pipe(map((res: any) => res));
  }

  loadPopularAirline(query: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/orders/orders/graph/flights/popular`, query,
      {}
    ).pipe(map((res: any) => res));
  }

  loadFlightsIncome(query: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/orders/orders/graph/flights/income`, query,
      {}
    ).pipe(map((res: any) => res));
  }

  loadFlightsOutcome(query: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/orders/orders/graph/flights/outcome`, query,
      {}
    ).pipe(map((res: any) => res));
  }

  loadFlightsProfit(query: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/orders/orders/graph/flights/profit`, query,
      {}
    ).pipe(map((res: any) => res));
  }

  loadPopularHotels(query: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/orders/orders/graph/hotels/popular`, query,
      {}
    ).pipe(map((res: any) => res));
  }

  loadHotelsIncome(query: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/orders/orders/graph/hotels/income`, query,
      {}
    ).pipe(map((res: any) => res));
  }

  loadHotelsOutcome(query: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/orders/orders/graph/hotels/outcome`, query,
      {}
    ).pipe(map((res: any) => res));
  }

  loadHotelsProfit(query: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/orders/orders/graph/hotels/profit`, query,
      {}
    ).pipe(map((res: any) => res));
  }
  loadPopularEvents(query: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/orders/orders/graph/events/popular`, query,
      {}
    ).pipe(map((res: any) => res));
  }

  loadEventsIncome(query: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/orders/orders/graph/events/income`, query,
      {}
    ).pipe(map((res: any) => res));
  }

  loadEventsOutcome(query: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/orders/orders/graph/events/outcome`, query,
      {}
    ).pipe(map((res: any) => res));
  }

  loadEventsProfit(query: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/orders/orders/graph/events/profit`, query,
      {}
    ).pipe(map((res: any) => res));
  }

  sendSms(query: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/notifications/sms`, query,
      {}
    ).pipe(map((res: any) => res));
  }

  sendEmail(query: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/notifications/email`, query,
      {}
    ).pipe(map((res: any) => res));
  }

  sendPushNotification(query: any) {
    return this.HttpClient.post(
      `${environment.ms_notifications_base_url}/send-push`, query,
      {}
    ).pipe(map((res: any) => res));
  }

  loadReservationIncome(query: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/orders/orders/graph/reservations/income`, query,
      {}
    ).pipe(map((res: any) => res));
  }

  loadReservationOutcome(query: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/orders/orders/graph/reservations/outcome`, query,
      {}
    ).pipe(map((res: any) => res));
  }

  UpdateOrderComment(orderCommentRequestInfo: any) {
    return this.HttpClient.post(
      `${environment.ms_orders_base_url}/orders/update/comment`,
      orderCommentRequestInfo
    ).pipe(map((res: any) => res));
  }

  //coupon Implementation 

  CreateCoupon(couponInfo: any) {
    return this.HttpClient.post(
      `${environment.ms_admin_coupon_url}/create`,
      couponInfo
    ).pipe(map((res: any) => res));
  }

  UpdateCoupon(couponInfo: any) {
    return this.HttpClient.post(
      `${environment.ms_admin_coupon_url}/update`,
      couponInfo
    ).pipe(map((res: any) => res));
  }

  GetCoupons() {
    return this.HttpClient.get(
      `${environment.ms_admin_coupon_url}/getAll`
    ).pipe(map((res: any) => res));
  }

  DeleteCoupons(code:any) {
    return this.HttpClient.post(
      `${environment.ms_admin_coupon_url}/delete`,code
    ).pipe(map((res: any) => res));
  }

  // Save View Implementation 

  SaveView(saveViewDetail: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/admin/auth/saveView`,
      saveViewDetail
    ).pipe(map((res: any) => res));
  }

  GetOrdersView(user: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/admin/auth/orderView`,
      user
    ).pipe(map((res: any) => res));
  }

  GetEventsView(user: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/admin/auth/eventView`,
      user
    ).pipe(map((res: any) => res));
  }

  GetFlightsView(user: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/admin/auth/flightView`,
      user
    ).pipe(map((res: any) => res));
  }

  GetHotelsView(user: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/admin/auth/hotelView`,
      user
    ).pipe(map((res: any) => res));
  }

  GetReservationsView(user: any) {
    return this.HttpClient.post(
      `${this.baseUrl}/admin/auth/reservationView`,
      user
    ).pipe(map((res: any) => res));
  }

}