import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { SortValueEnum } from "src/app/libs/ui/components/top-bar/enums/top-bar.enum";

export const EventsStateActions = createActionGroup({
  source: "State/API",
  events: {
    "Load Event Filter Options": props<{ requestQuery: any }>(),
    "Load Event Filter Options Success": props<{
      data: {
        filterOptions: any;
      };
    }>(),
    "Load Event Filter Options Failure": props<{ error: any }>(),
    "Load Initial Events Data": props<{ requestQuery: any }>(),
    "Load Initial Events Data Success": props<{
      data: {
        events: any;
        totalLeftToFetch: number;
      };
    }>(),
    "Load Initial Events Data Failure": props<{ error: any }>(),

    "Load Filtered Events Data": props<{ requestQuery: any }>(),
    "Load Filtered Events Data Success": props<{
      data: {
        events: any;
        flights: any;
        hotels: any;
        totalLeftToFetch: number;
        totalRecords: number;
      };
    }>(),
    "Load Filtered Events Data Failure": props<{ error: any }>(),

    "Load More Events Data": props<{ requestQuery: any }>(),
    "Load More Events Data Success": props<{
      data: {
        events: any;
        flights: any;
        hotels: any;
        totalLeftToFetch: number;
      };
    }>(),
    "Load More Events Failure": props<{ error: any }>(),
    "Update Event Sort Order": props<{ sortValue: SortValueEnum }>(),
    "Save Filter Configuration": props<{ metadata: any }>(),
    "Set Selected Filters": props<{ selectedFilters: any }>(),
    'Set Quick Filter Event': props<{ selectedQuickFilterEvent: string }>(),
  },
});
