import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { QueryBase } from "src/app/libs/data-access/store/shared/query-base.interface";
import { FlightsState } from "../model";
import { FlightsStateActions } from "../store";
import { FlightsSelectors } from "../store/flights.selectors";
import { IFilter } from "src/app/libs/ui/interfaces/filters.interface";

@Injectable({
  providedIn: "root",
})

export class FlightsQuery implements QueryBase {
  flights$ = this.flightsStore.select(FlightsSelectors.getFlights);
  active$ = this.flightsStore.select(FlightsSelectors.getActive);

  offerRequestId$ = this.flightsStore.select(
    FlightsSelectors.getOfferRequestId
  );

  filtersMetadata$ = this.flightsStore.select(
    FlightsSelectors.getFilterMetaData
  );

  requestMetadata$ = this.flightsStore.select(
    FlightsSelectors.getRequestMetaData
  );

  sort$ = this.flightsStore.select(FlightsSelectors.getSortValue);
  totalRecordsLeft$ = this.flightsStore.select(
    FlightsSelectors.getTotalRecordsLeft
  );

  totalRecords$ = this.flightsStore.select(
    FlightsSelectors.getTotalRecords
  );

  selectedQuickFilter$ = this.flightsStore.select(
    FlightsSelectors.getSelectedQuickFilter
  );

  loadMoreActions$ = this.flightsStore.select(
    FlightsSelectors.getLoadMoreActions
  );

  loading$ = this.flightsStore.select(FlightsSelectors.getLoading);
  loaded$ = this.flightsStore.select(FlightsSelectors.getLoaded);
  errors$ = this.flightsStore.select(FlightsSelectors.getLoaded);

  constructor(private flightsStore: Store<FlightsState>) {}

  loadFilterOptions(requestQuery: any): void {
    this.flightsStore.dispatch(
      FlightsStateActions.loadFlightFilterOptions({ requestQuery })
    );
  }

  saveFilterConfiguration(filtersMetadata: IFilter<any>[]): void {
    //TODO: Complete type
    this.flightsStore.dispatch(
      FlightsStateActions.saveFlightFiltersConfiguration({ filtersMetadata })
    );
  }

  loadInitialFlightData(requestQuery: any): void {
    //TODO: Complete type
    this.flightsStore.dispatch(
      FlightsStateActions.loadInitialFlightsData({ requestQuery })
    );
  }

  loadMoreFlights(requestQuery: any): void {
    //TODO: Complete type
    this.flightsStore.dispatch(
      FlightsStateActions.loadMoreFlights(requestQuery)
    );
  }
  
  setQuickFilter(selectedQuickFilterFlight: any): void {
    //TODO: Complete type
    this.flightsStore.dispatch(
      FlightsStateActions.setQuickFilterFlight({ selectedQuickFilterFlight})
    );
  }
}
