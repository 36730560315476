<h3 class="font-weight-bold">{{'dashboard.reservationPage.title' | i18n}}</h3>
<div class="text-end saveView">
    <mat-form-field>
        <mat-select placeholder="{{'dashboard.orderDetailPage.selectView' | i18n}}" (selectionChange)="onSelectionChange($event)">
            <mat-option [value]="null">None</mat-option>
            <mat-option *ngFor="let data of reservationsView.reservationViews" [value]="data.param">{{data.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <tic-button class="blue" buttonText="dashboard.buttons.saveView" (btnClick)="openSaveView()"></tic-button>
</div>
<div *ngIf="showValidation">
    <div *ngIf="Info && Info.error && Info.error===true" class="alert alert-danger d-flex align-items-center" role="alert">
        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
        <div>
        {{Info.message}}
        </div>
    </div>    
    <div *ngIf="Info && !Info.error && Info.error===false" class="alert alert-success d-flex align-items-center" role="alert">
        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#exclamation-triangle-fill"/></svg>
        <div>
        {{Info.message}}
        </div>
    </div>
</div>   
<div class="order-view">
    <div class="container-fluid">
        <div class="row rw-1">
            <div class="col-lg-6 col-style">
                <div class="header">
                    <h6>{{'dashboard.reservationPage.incomeFromReservations' | i18n}}</h6>
                    <div class="query-opt">
                        <div *ngIf="requestQueryReservationIncome && requestQueryReservationIncome.fromDate && requestQueryReservationIncome.toDate; else dateIncome">
                            <tic-date-selector [selectedFrom]="requestQueryReservationIncome.fromDate" [selectedTo]="requestQueryReservationIncome.toDate" (range)="filterByRange($event,'income')" ></tic-date-selector>
                       </div>
                       <ng-template #dateIncome>
                           <tic-date-selector (range)="filterByRange($event,'income')" ></tic-date-selector>
                       </ng-template>
                        <tic-chart-type [selected]="incomeChart" (chartType)="toggleChart($event,'income')"></tic-chart-type>
                    </div>
                </div>
                <div class="query-opt">
                    <div *ngIf="requestQueryReservationIncome && requestQueryReservationIncome.filters && requestQueryReservationIncome.filters.status; else noStatusIncome">
                        <tic-filters-dropdown [selected]="requestQueryReservationIncome.filters.status" [label]="statusTitle" [filterOptions]="status" (option)="dataFilter($event,'income','status')" ></tic-filters-dropdown>
                    </div>
                    <ng-template #noStatusIncome>
                        <tic-filters-dropdown [label]="statusTitle" [filterOptions]="status" (option)="dataFilter($event,'income','status')" ></tic-filters-dropdown>
                    </ng-template>
                    <div *ngIf="requestQueryReservationIncome && requestQueryReservationIncome.filters && requestQueryReservationIncome.filters.priceType && requestQueryReservationIncome.filters.minPrice && requestQueryReservationIncome.filters.maxPrice; else priceIncome">
                        <tic-price-selector [selected]="requestQueryReservationIncome.filters.priceType" [min]="requestQueryReservationIncome.filters.minPrice" [max]="requestQueryReservationIncome.filters.maxPrice" (range)="dataFilter($event,'income','price')" ></tic-price-selector>
                    </div>
                    <ng-template #priceIncome>
                        <tic-price-selector (range)="dataFilter($event,'income','price')" ></tic-price-selector>
                    </ng-template>
                </div>
                <div *ngIf="chartOptions">
                  <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"></highcharts-chart>
                </div>
            </div>
            <div class="col-lg-6 col-style">
                <div class="header">
                    <h6>{{'dashboard.reservationPage.outcomeOfReservations' | i18n}}</h6>
                    <div class="query-opt">
                        <div *ngIf="requestQueryReservationOutcome && requestQueryReservationOutcome.fromDate && requestQueryReservationOutcome.toDate; else dateOutcome">
                            <tic-date-selector [selectedFrom]="requestQueryReservationOutcome.fromDate" [selectedTo]="requestQueryReservationOutcome.toDate" (range)="filterByRange($event,'outcome')" ></tic-date-selector>
                        </div>
                        <ng-template #dateOutcome>
                            <tic-date-selector (range)="filterByRange($event,'outcome')" ></tic-date-selector>
                        </ng-template>
                        <tic-chart-type [selected]="outcomeChart" (chartType)="toggleChart($event,'outcome')"></tic-chart-type>
                    </div>
                </div>
                <div class="query-opt">
                    <div *ngIf="requestQueryReservationOutcome && requestQueryReservationOutcome.filters && requestQueryReservationOutcome.filters.status; else noStatusOutcome">
                        <tic-filters-dropdown [selected]="requestQueryReservationOutcome.filters.status" [label]="statusTitle" [filterOptions]="status" (option)="dataFilter($event,'outcome','status')" ></tic-filters-dropdown>
                    </div>
                    <ng-template #noStatusOutcome>
                        <tic-filters-dropdown [label]="statusTitle" [filterOptions]="status" (option)="dataFilter($event,'outcome','status')" ></tic-filters-dropdown>
                    </ng-template>
                    <div *ngIf="requestQueryReservationOutcome && requestQueryReservationOutcome.filters && requestQueryReservationOutcome.filters.priceType && requestQueryReservationOutcome.filters.minPrice && requestQueryReservationOutcome.filters.maxPrice; else priceOutcome">
                        <tic-price-selector [selected]="requestQueryReservationOutcome.filters.priceType" [min]="requestQueryReservationOutcome.filters.minPrice" [max]="requestQueryReservationOutcome.filters.maxPrice"  (range)="dataFilter($event,'outcome','price')" ></tic-price-selector>
                    </div>
                    <ng-template #priceOutcome>
                        <tic-price-selector (range)="dataFilter($event,'outcome','price')" ></tic-price-selector>
                    </ng-template>
                </div>
                <div *ngIf="chartOptions1">
                  <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions1"></highcharts-chart>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="showSaveViewPopup">
    <tic-save-view (close)="closeViewPopup()" (saveView)="saveView($event)"></tic-save-view>
  </ng-container>