import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'tic-comments-popup',
  templateUrl: './comments-popup.component.html',
  styleUrls: ['./comments-popup.component.scss']
})
export class CommentsPopupComponent {
  @Output() close = new EventEmitter<boolean>();
  @Output() sendComment = new EventEmitter<any>();
  @Input() passengerName:string=''; 
  @Input() preComment:string=''; 
  orderCommentForm!:FormGroup;

  constructor(private fb:FormBuilder) {    
    this.createCommentForm();
  }

  ngOnInit() {
    this.comment.setValue(this.preComment);
  }

createCommentForm(){
 this.orderCommentForm=this.fb.group({
  comment:['',[Validators.required]]
 });
}
get comment() {
  return this.orderCommentForm.get("comment");
}
submitOrderCommentForm(){
this.sendComment.emit(this.orderCommentForm.value);
}

closePopup() {
 this.close.emit(true);
}

}
