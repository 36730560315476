import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { DashboardService } from '../services/dashboard.service';
import { dashboardStateActions } from './dashboard.action';

@Injectable()
export class DashboardEffects {

  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService
  ) { }

  getOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.getOrders),
      exhaustMap(() => {
        //TODO: complete types
        return this.dashboardService.getOrders().pipe(
          map((res) => {
            const orders = res;
            return dashboardStateActions.ordersSuccess({
              data: {
                orders,
              }
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
            );
          })
        );
      })
    )
  );

  getEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.getEvents),
      exhaustMap(() => {
        //TODO: complete types
        const params = {
          platform: "events"
        }
        return this.dashboardService.getPlatform(params).pipe(
          map((res) => {
            const events = res;
            return dashboardStateActions.eventsSuccess({
              data: {
                events,
              }
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
            );
          })
        );
      })
    )
  );
  getFlights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.getFlights),
      exhaustMap(() => {
        //TODO: complete types
        const params = {
          platform: "flights"
        }
        return this.dashboardService.getPlatform(params).pipe(
          map((res) => {
            const flights = res;
            return dashboardStateActions.flightsSuccess({
              data: {
                flights,
              }
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
            );
          })
        );
      })
    )
  );
  getHotels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.getHotels),
      exhaustMap(() => {
        //TODO: complete types
        const params = {
          platform: "hotels"
        }
        return this.dashboardService.getPlatform(params).pipe(
          map((res) => {
            const hotels = res;
            return dashboardStateActions.hotelsSuccess({
              data: {
                hotels,
              }
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
            );
          })
        );
      })
    )
  );
  getOrderDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.getOrderDetail),
      exhaustMap((query: any) => {
        //TODO: complete types
        const params = query;
        return this.dashboardService.getOrderDetail(params).pipe(
          map((res) => {
            const orderDetail = res;
            return dashboardStateActions.orderDetailSuccess({
              data: {
                orderDetail,
              }
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
            );
          })
        );
      })
    )
  );
  getEventDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.getEventDetail),
      exhaustMap((query: any) => {
        //TODO: complete types
        const params = {
          orderId: query.id
        }
        return this.dashboardService.getEventDetail(params).pipe(
          map((res) => {
            const eventDetail = res;
            return dashboardStateActions.eventDetailSuccess({
              data: {
                eventDetail,
              }
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
            );
          })
        );
      })
    )
  );
  getFlightDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.getFlightDetail),
      exhaustMap((query: any) => {
        //TODO: complete types
        const params = {
          orderId: query.id
        }
        return this.dashboardService.getFlightDetail(params).pipe(
          map((res) => {
            const flightDetail = res;
            return dashboardStateActions.flightDetailSuccess({
              data: {
                flightDetail,
              }
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
            );
          })
        );
      })
    )
  );
  getHotelDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.getHotelDetail),
      exhaustMap((query: any) => {
        //TODO: complete types
        const params = {
          bookingId: query.id
        }
        return this.dashboardService.getHotelDetail(params).pipe(
          map((res) => {
            const hotelDetail = res;
            return dashboardStateActions.hotelDetailSuccess({
              data: {
                hotelDetail,
              }
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
            );
          })
        );
      })
    )
  );
  getPackagesInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.loadPackages),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.dashboardService.getPackagesInfo(query.query).pipe(
          map((res) => {
            const packagesInfo = res;
            return dashboardStateActions.loadPackagesSuccess({
              data: packagesInfo
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
            );
          })
        );
      })
    )
  );
  getPersonsInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.loadPersons),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.dashboardService.getPersonsInfo(query.query).pipe(
          map((res) => {
            const personsInfo = res;
            return dashboardStateActions.loadPersonsSuccess({
              data: personsInfo
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
            );
          })
        );
      })
    )
  );

  loadPopularAirline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.loadPopularAirline),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.dashboardService.loadPopularAirline(query.query).pipe(
          map((res) => {
            const popularAirlines = res;
            return dashboardStateActions.loadPopularAirlineSuccess({
              data: popularAirlines
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.loadPopularAirlineFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  loadFlightsIncome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.loadFlightsIncome),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.dashboardService.loadFlightsIncome(query.query).pipe(
          map((res) => {
            const flightIncome = res;
            return dashboardStateActions.loadFlightsIncomeSuccess({
              data: flightIncome
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.loadFlightsIncomeFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  loadFlightsOutcome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.loadFlightsOutcome),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.dashboardService.loadFlightsOutcome(query.query).pipe(
          map((res) => {
            const flightOutcome = res;
            return dashboardStateActions.loadFlightsOutcomeSuccess({
              data: flightOutcome
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.loadFlightsOutcomeFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  loadFlightsProfit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.loadFlightsProfit),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.dashboardService.loadFlightsProfit(query.query).pipe(
          map((res) => {
            const flightOutcome = res;
            return dashboardStateActions.loadFlightsProfitSuccess({
              data: flightOutcome
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.loadFlightsProfitFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  loadPopularHotels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.loadPopularHotels),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.dashboardService.loadPopularHotels(query.query).pipe(
          map((res) => {
            const popularHotels = res;
            return dashboardStateActions.loadPopularHotelsSuccess({
              data: popularHotels
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.loadPopularHotelsFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  loadHotelsIncome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.loadHotelsIncome),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.dashboardService.loadHotelsIncome(query.query).pipe(
          map((res) => {
            const hotelsIncome = res;
            return dashboardStateActions.loadHotelsIncomeSuccess({
              data: hotelsIncome
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.loadHotelsIncomeFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  loadHotelsOutcome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.loadHotelsOutcome),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.dashboardService.loadHotelsOutcome(query.query).pipe(
          map((res) => {
            const hotelsOutcome = res;
            return dashboardStateActions.loadHotelsOutcomeSuccess({
              data: hotelsOutcome
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.loadHotelsOutcomeFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  loadHotelsProfit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.loadHotelsProfit),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.dashboardService.loadHotelsProfit(query.query).pipe(
          map((res) => {
            const hotelsOutcome = res;
            return dashboardStateActions.loadHotelsProfitSuccess({
              data: hotelsOutcome
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.loadHotelsProfitFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  getNumberOfOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.weeklyNumberOfOrders),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.dashboardService.getNumberOfOrders(query.query).pipe(
          map((res) => {
            const numberOfOrders = res;
            return dashboardStateActions.weeklyNumberOfOrdersSuccess({
              data:numberOfOrders
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.weeklyNumberOfOrdersFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  loadPopularEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.loadPopularEvents),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.dashboardService.loadPopularEvents(query.query).pipe(
          map((res) => {
            const popularEvents = res;
            return dashboardStateActions.loadPopularEventsSuccess({
              data: popularEvents
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.loadPopularEventsFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  loadEventsIncome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.loadEventsIncome),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.dashboardService.loadEventsIncome(query.query).pipe(
          map((res) => {
            const eventsIncome = res;
            return dashboardStateActions.loadEventsIncomeSuccess({
              data: eventsIncome
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.loadEventsIncomeFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  loadEventssOutcome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.loadEventsOutcome),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.dashboardService.loadEventsOutcome(query.query).pipe(
          map((res) => {
            const EventsOutcome = res;
            return dashboardStateActions.loadEventsOutcomeSuccess({
              data: EventsOutcome
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.loadEventsOutcomeFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  loadEventsProfit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.loadEventsProfit),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.dashboardService.loadEventsProfit(query.query).pipe(
          map((res) => {
            const EventsOutcome = res;
            return dashboardStateActions.loadEventsProfitSuccess({
              data: EventsOutcome
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.loadEventsProfitFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  sendSms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.sendSms),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.dashboardService.sendSms(query.query).pipe(
          map((res) => {
            const response = res;
            return dashboardStateActions.sendSmsSuccess({
              data: response
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
            );
          })
        );
      })
    )
  );

  sendEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.sendEmail),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.dashboardService.sendEmail(query.query).pipe(
          map((res) => {
            const response = res;
            return dashboardStateActions.sendEmailSuccess({
              data: response
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
            );
          })
        );
      })
    )
  );

  sendPushNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.sendPushNotification),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.dashboardService.sendPushNotification(query.query).pipe(
          map((res) => {
            const response = res;
            return dashboardStateActions.sendPushNotificationSuccess({
              data: response
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
            );
          })
        );
      })
    )
  );

  loadReservationIncome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.loadReservationIncome),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.dashboardService.loadReservationIncome(query.query).pipe(
          map((res) => {
            const reservationIncome = res;
            return dashboardStateActions.loadReservationIncomeSuccess({
              data: reservationIncome
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.loadReservationIncomeFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  loadReservationOutcome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.loadReservationOutcome),
      exhaustMap((query: any) => {
        //TODO: complete types
        return this.dashboardService.loadReservationOutcome(query.query).pipe(
          map((res) => {
            const reservationOutcome = res;
            return dashboardStateActions.loadReservationOutcomeSuccess({
              data: reservationOutcome
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.loadReservationOutcomeFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  UpdateOrderComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.updateOrderComment),
      exhaustMap((query: any) => {
        return this.dashboardService.UpdateOrderComment(query.orderCommentDetail).pipe(
          map((res) => {
            const UpdateOrderInfo = { message: "saved" };
            return dashboardStateActions.updateOrderCommentSuccess({
              data: {
                UpdateOrderInfo,
              },
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.updateOrderCommentFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  // Coupon Implementation 
  
  CreateCoupon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.createCoupon),
      exhaustMap((query: any) => {
        return this.dashboardService.CreateCoupon(query.couponDetail).pipe(
          map((res) => {
            const couponInfo = res;
            return dashboardStateActions.createCouponSuccess({
              data: {
                couponInfo,
              },
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.createCouponFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  UpdateCoupon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.updateCoupon),
      exhaustMap((query: any) => {
        return this.dashboardService.UpdateCoupon(query.couponDetail).pipe(
          map((res) => {
            const couponInfo = res;
            return dashboardStateActions.updateCouponSuccess({
              data: {
                couponInfo,
              },
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.updateCouponFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  getCoupons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.getCoupons),
      exhaustMap(() => {
        //TODO: complete types
        return this.dashboardService.GetCoupons().pipe(
          map((res) => {
            const coupons = res;
            return dashboardStateActions.getCouponsSuccess({
              data: {
                coupons,
              }
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.getCouponsFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );
  
  DeleteCoupons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.deleteCoupon),
      exhaustMap((code: any) => {
        //TODO: complete types
        const params = code;
        return this.dashboardService.DeleteCoupons(params).pipe(
          map((res) => {
            const couponInfo = res;
            return dashboardStateActions.deleteCouponSuccess({
              data: {
                couponInfo,
              },
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.deleteCouponFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  // Save view implementation
  SaveView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.saveView),
      exhaustMap((query: any) => {
        return this.dashboardService.SaveView(query.saveViewDetail.saveViewDetail).pipe(
          map((res) => {
            const SaveViewInfo = res;
            return dashboardStateActions.saveViewSuccess({
              data: {
                SaveViewInfo,
              },
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.saveViewFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  getOrdersView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.getOrdersViewDetail),
      exhaustMap((query) => {
        //TODO: complete types
        return this.dashboardService.GetOrdersView(query.user).pipe(
          map((res) => {
            const OrdersView = res;
            return dashboardStateActions.getOrdersViewSuccess({
              data: {
                OrdersView,
              }
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.getOrdersViewFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  getEventsView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.getEventsViewDetail),
      exhaustMap((query) => {
        //TODO: complete types
        return this.dashboardService.GetEventsView(query.user).pipe(
          map((res) => {
            const eventsView = res;
            return dashboardStateActions.getEventsViewSuccess({
              data: {
                eventsView,
              }
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.getEventsViewFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  getFlightsView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.getFlightsViewDetail),
      exhaustMap((query) => {
        //TODO: complete types
        return this.dashboardService.GetFlightsView(query.user).pipe(
          map((res) => {
            const flightsView = res;
            return dashboardStateActions.getFlightsViewSuccess({
              data: {
                flightsView,
              }
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.getFlightsViewFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  getHotelsView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.getHotelsViewDetail),
      exhaustMap((query) => {
        //TODO: complete types
        return this.dashboardService.GetHotelsView(query.user).pipe(
          map((res) => {
            const hotelsView = res;
            return dashboardStateActions.getHotelsViewSuccess({
              data: {
                hotelsView,
              }
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.getHotelsViewFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );

  getReservationsView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardStateActions.getReservationsViewDetail),
      exhaustMap((query) => {
        //TODO: complete types
        return this.dashboardService.GetReservationsView(query.user).pipe(
          map((res) => {
            const reservationsView = res;
            return dashboardStateActions.getReservationsViewSuccess({
              data: {
                reservationsView,
              }
            });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              dashboardStateActions.getReservationsViewFailure({
                error: error?.message || String(error),
              } as any)
            );
          })
        );
      })
    )
  );
  
  
}