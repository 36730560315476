import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { QueryBase } from 'src/app/libs/data-access/store/shared/query-base.interface';
import { CurrencyState } from '../../../models';
import { CurrencySelectors, currencyFeatureSelector } from '../store/currency.selectors';
import { currencyStateActions } from '../store/currency.actions';

@Injectable({
  providedIn: 'root'
})
export class CurrencyQuery implements QueryBase {
  exchangeRate$ = this.currencyStore.select(CurrencySelectors.getExchangeRate);
  currentCurrency$ = this.currencyStore.select(CurrencySelectors.getCurrentRate);
  to$ = this.currencyStore.select(CurrencySelectors.getTo);
  from$ = this.currencyStore.select(CurrencySelectors.getFrom);
  loading$ = this.currencyStore.select(CurrencySelectors.getLoading);
  loaded$ = this.currencyStore.select(CurrencySelectors.getLoaded);
  errors$ = this.currencyStore.select(CurrencySelectors.getError);

  constructor(
    private currencyStore: Store<CurrencyState>,
  ) {}

  getExchangeRate():void{
    this.currencyStore.dispatch(currencyStateActions.getExchangeRate());
  }

  setExchange(name):void{
    this.currencyStore.dispatch(currencyStateActions.setCurrentCurrency({ currency: name }));
  }
}
