import { InjectionToken } from '@angular/core';
import enI18n from './config/en.i18n.config';
import heI18n from './config/he.i18n.config';
import { Language } from './config/enums/languages.enums';
import { AppSupportedLanguagesInterface } from './config/interfaces/app-supported-languages.interface';

export const i18nConfigService = new InjectionToken<any>('i18nConfig');

export const AppSupportedRtlLanguages: AppSupportedLanguagesInterface[] = [
  { name: Language.Hebrew, flag: '../../../../../assets/images/ils.png', file: heI18n ,writingDirection:'rtl',selected:false}
];

export const AppSupportedLtrLanguages: AppSupportedLanguagesInterface[] = [
  { name: Language.English, flag: '../../../../../assets/images/gbp.png', file: enI18n,writingDirection:'ltr',selected:false }
];

export const AppSupportedLanguages: AppSupportedLanguagesInterface[] = [
  ...AppSupportedLtrLanguages,
  ...AppSupportedRtlLanguages,
];

