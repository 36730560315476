<div class="admin-dashboard">
    <tic-dashboard-header (searchInOrders)="searchInOrders($event)" (toggle)="toggleSidebar($event)"></tic-dashboard-header>
    <div class="dashboard-body">
        <tic-side-panel [toggle]="toggle" (toggleMenu)="toggleSidebar($event)"></tic-side-panel>
        <div class="main-panel" [class.active]="toggle">
            <tic-orders-table *ngIf="renderComp == 'all-trip'" [searchString]="searchString"></tic-orders-table>
            <tic-order-view *ngIf="renderComp == 'orderDetail'"></tic-order-view>
            <tic-event-detail *ngIf="renderComp == 'events'"></tic-event-detail>
            <tic-flight-detail *ngIf="renderComp == 'flight'"></tic-flight-detail>
            <tic-hotel-detail *ngIf="renderComp == 'hotels'"></tic-hotel-detail>
            <tic-single-order *ngIf="renderComp == 'singleOrder'"></tic-single-order>
            <tic-reservation-detail *ngIf="renderComp == 'reservation'"></tic-reservation-detail>
            <tic-coupon-detail *ngIf="renderComp == 'coupon-detail'"></tic-coupon-detail>
        </div>
    </div>
</div>