<nav class="sidebar sidebar-offcanvas" [ngClass]="{'active': isActive}" id="sidebar">
  <ul class="nav">
    <li *ngIf="isActive == true" class="close-popup" (click)="closePanel()">
      <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g _ngcontent-nbx-c129="" opacity="0.5">
          <path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path _ngcontent-nbx-c129="" d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g>
      </svg>
    </li>
    <li *ngIf="isActive == false" class="open-panel" (click)="openPanel()">
      <span class="arrrow"></span>
    </li>
    <li class="nav-item" *ngFor="let menuItem of menuItems">
      <a class="nav-link" (click)="selectedMenu(menuItem.code)" href="{{ menuItem.routerLink }}"
       *ngIf="menuItem.routerLink" [matTooltip]="!isActive?(menuItem.title | i18n):''">
        <i class="{{ menuItem.iconClass }}" aria-hidden="true"></i>
        <span class="menu-title ml-2">{{ menuItem.title | i18n }}</span>
      </a>
    </li>
  </ul>
</nav>