import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { PriceSelectorComponent } from './price-selector.component';
import { MatIconModule } from '@angular/material/icon';
import { I18nModule } from 'src/app/core/languages-support/i18n.module';
import { AppSupportedLanguages } from 'src/app/core/languages-support/i18n-config.service';



@NgModule({
  declarations: [PriceSelectorComponent],
  providers: [DatePipe],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    I18nModule.forRoot(AppSupportedLanguages),
  ],
  exports: [
    PriceSelectorComponent
  ]
})
export class PriceSelectorModule { }
