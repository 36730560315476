import { Component } from '@angular/core';
import { DashboardFacade } from '../../../data-access/services/dashboard.facade';

@Component({
  selector: 'tic-single-flight',
  templateUrl: './single-flight.component.html',
  styleUrls: ['./single-flight.component.scss']
})
export class SingleFlightComponent {
  constructor(public dashboardFacade: DashboardFacade){}
}
