<form>
    <div class="name">
        <div class="fname">
            <input type="text" id="firstname" formControlName="firstname" placeholder="First Name">
            <div *ngIf="signupForm.get('firstname').invalid && (signupForm.get('firstname').dirty || signupForm.get('firstname').touched)">
                <small>First Name is required.</small>
            </div>
        </div>
        <div class="lname">
            <input type="text" id="lastname" formControlName="lastname" placeholder="Last Name">
            <div *ngIf="signupForm.get('lastname').invalid && (signupForm.get('lastname').dirty || signupForm.get('lastname').touched)">
                <small>Last Name is required.</small>
            </div>
        </div>
    </div>
    <div>
        <input type="email" id="email" formControlName="email" placeholder="Email">
        <div *ngIf="signupForm.get('email').invalid && (signupForm.get('email').dirty || signupForm.get('email').touched)">
            <small>Email is required.</small>
        </div>
    </div>
    <div>
        <input type="password" id="password" formControlName="password" placeholder="Password">
        <div *ngIf="signupForm.get('password').invalid && (signupForm.get('password').dirty || signupForm.get('password').touched)">
            <small>Password is required.</small>
        </div>
    </div>
    <div class="btn-group">
        <button type="submit">Sign Up</button>
    </div>
</form>
