import { Action, createReducer, on } from "@ngrx/store";
import { SortValueEnum } from "src/app/libs/ui/components/top-bar/enums/top-bar.enum";
import { EventsState } from "../model";
import { EventsStateActions } from "./events.actions";
import { eventsAdapter } from "./events.adapter";
import { InitialLoadActions, InitialLoadActionsWithData } from "../../../../../../../app/libs/data-access/store/shared";
import { FilterType } from "src/app/libs/ui/enums/fiters.enums";
import { ResetState } from "../../../../../../actions/global.actions";

export const initialEventsState: EventsState = eventsAdapter.getInitialState({
  entities: {},
  ids: [],
  totalLeftToFetch: -1,
  totalRecords: 0,
  page: 1,
  filtersMetadata: InitialLoadActionsWithData,
  sort: SortValueEnum.TotalAmountAsc,
  selectedFilters: {},
  selectedQuickFilterEvent: 'cheapest',
  requestMetadata: {},
  loadMoreActions: InitialLoadActions,
  loading: false,
  loaded: false,
  error: null,
  active: [],
  flights: [],
  hotels: [],
});

export const EventsStateFeatureKey = "EventsStateFeatureKey";

export const eventsReducer = createReducer(
  initialEventsState,  
  on(EventsStateActions.loadEventFilterOptions, (state, action) => {

    return {
      ...state,
      filtersMetadata: {
        data: state.filtersMetadata?.data,
        loading: true,
        loaded: false,
        error: null,
      },
    };
  }),

  on(EventsStateActions.loadEventFilterOptionsSuccess, (state, action) => {
    const { filterOptions } = action.data || {};
    const events: EventsState[] = [];
    const updatedState = eventsAdapter.addMany(events, state);
    
    const filtersMetadata = JSON.parse(JSON.stringify(state.filtersMetadata));

    for (let filter of filtersMetadata.data) {
      if (filter.type == FilterType.Slider) {
        if (filter.id == "price") {
          filter.options[0].min.defaultValue = filterOptions[filter.id].min;
          filter.options[0].max.defaultValue = filterOptions[filter.id].max;
        }
      }

      if (filter.type == FilterType.Checkbox) {
        for (let data of filterOptions[filter.id]) {
          filter.options[0].options.push(data);
        }
      }
    }

    return {
      ...updatedState,
      filtersMetadata: {
        data: [ ...filtersMetadata.data ],
        loading: false,
        loaded: true,
        error: null,
      },
    };
  }),

  on(EventsStateActions.loadEventFilterOptionsFailure, (state, action) => {
    const { error } = action || {};
    //TODO: add errors
    return {
      ...state,
      filtersMetadata: {
        data: null,
        loading: false,
        loaded: true,
        error,
      },
    };
  }),

  on(EventsStateActions.loadInitialEventsData, (state, action) => {
    const { requestQuery } = action || {};
    return {
      ...state,
      requestMetadata: { requestQuery },
      loading: true,
      loaded: false,
      error: null,
    };
  }),

  on(EventsStateActions.loadInitialEventsDataSuccess, (state, action) => {
    const { events, totalLeftToFetch } = action?.data || {};
    const updatedState = eventsAdapter.addMany(events, state);
    const active = events;
    return {
      ...updatedState,
      active,
      totalLeftToFetch,
      loading: false,
      loaded: true,
      error: null,
    };
  }),

  on(EventsStateActions.loadInitialEventsDataFailure, (state, action) => {
    //TODO: add errors
    const { error } = action || {};
    return {
      ...state,
      loading: false,
      loaded: true,
      error,
    };
  }),

  on(EventsStateActions.loadFilteredEventsData, (state, action) => {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: null,
    };
  }),

  on(EventsStateActions.loadFilteredEventsDataSuccess, (state, action) => {
    const { events, totalLeftToFetch ,flights, hotels, totalRecords } = action.data;
    const active = events;
    const updatedState = eventsAdapter.addMany(events, state);
    return {
      ...updatedState,
      flights,
      hotels,
      active,
      totalLeftToFetch,
      totalRecords,
      loading: false,
      loaded: true,
      error: false,
    };
  }),

  on(EventsStateActions.loadFilteredEventsDataFailure, (state, action) => {
    //TODO: add errors
    const { error } = action || {};
    return {
      ...state,
      loading: false,
      loaded: true,
      error,
    };
  }),

  on(EventsStateActions.loadMoreEventsData, (state, action) => {
    const { requestQuery } = action;

    return {
      ...state,
      requestMetadata: requestQuery,
      loadMoreActions: {
        loading: true,
        loaded: false,
        error: null,
      },
    };
  }),

  on(EventsStateActions.loadMoreEventsDataSuccess, (state, action) => {
    const { events, flights, hotels, totalLeftToFetch } = action.data;
    const updatedState = eventsAdapter.addMany(events, state);
    const active = [...state.active, ...events];
    return {
      ...updatedState,
      active,
      flights,
      hotels,
      totalLeftToFetch,
      loadMoreActions: {
        loading: false,
        loaded: true,
        error: null,
      },
    };
  }),

  on(EventsStateActions.loadMoreEventsFailure, (state, action) => {
    //TODO: add errors
    const { error } = action;

    return {
      ...state,
      loadMoreActions: {
        loading: false,
        loaded: true,
        error,
      },
    };
  }),

  on(EventsStateActions.updateEventSortOrder, (state, action) => {
    const { sortValue } = action || {}; //This is a sync action so no need for loading and loaded...

    return {
      ...state,
      sort: sortValue,
    };
  }),

  on(EventsStateActions.saveFilterConfiguration, (state, action) => {
    const { metadata } = action || {}; //This is a sync action so no need for loading and loaded...

    return {
      ...state,
      filtersMetadata: {
        data: metadata,
        loading: false,
        loaded: false,
        error: null,
      },
    };
  }),

  on(EventsStateActions.setSelectedFilters, (state, action) => {
    const { selectedFilters } = action || {};
    return { //This is a sync action so no need for loading and loaded...
      ...state,
      selectedFilters,
    };
  }),
  on(EventsStateActions.setQuickFilterEvent, (state, action) => {
    const { selectedQuickFilterEvent } = action || {};
    return { //This is a sync action so no need for loading and loaded...
      ...state,
      selectedQuickFilterEvent,
    };
  }),
  on(ResetState, (state:any,action:any) =>{
    
    return {
      ...initialEventsState,
      filtersMetadata: {
        data: [],
        loading: false,
        loaded: false,
        error: null,
      },
    }
  }),
);

export function EventsReducer(state: EventsState | undefined, action: Action) {
  return eventsReducer(state, action);
}
