import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'tic-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent {
  @Output() toggle = new EventEmitter<boolean>();
  @Output() searchInOrders=new EventEmitter<any>();
  showSearching:boolean =false;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const currentUrl = this.router.url;
      this.showSearching = currentUrl === '/dashboard' || currentUrl ==='/dashboard?tab=all-trip';
    });
    const initialUrl = this.router.url;
    this.showSearching = initialUrl.includes('/dashboard') || initialUrl.includes('/dashboard?tab=all-trip'); 
  }
  
  toggleSidebar() {
    this.toggle.emit(true);
  }

  navigateToHome():void {
    this.router.navigate(['/home-page']);
  }

  searchInOrderList(event:any){
    this.searchInOrders.emit(event.target.value);
  }
  
  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    this.router.navigate(['/login'],{});
  }
}
