import { auditTime, combineLatest, map, Observable } from "rxjs";

export type CurrencyStreams = [
  Observable<any>,
  Observable<any>,
  Observable<any>,
  Observable<boolean>,
  Observable<boolean>,
  Observable<Error>
];

export interface CurrencyViewModel {
  exchangeRate: any;
  to: any;
  from: any;
  loading: boolean;
  loaded: boolean;
  error: Error;
}

export function makeCurrencyViewModel(streams: CurrencyStreams): Observable<Readonly<CurrencyViewModel>> {
  const toViewModel = ([ exchangeRate, to, from, loading, loaded, error ]: [any, any, any, boolean, boolean, Error]): CurrencyViewModel => {
    return {
      exchangeRate,
      to,
      from,
      loading,
      loaded,
      error
    }
  }

  return combineLatest(streams).pipe(auditTime(0), map(toViewModel))
}