import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/app/core/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = 'https://trripi-stg.com/admin/auth/login'; // Replace with your API URL
  private tokenExpirationTime = 2 * 60 * 60 * 1000; // 2 hours in milliseconds

  constructor(private http: HttpClient) {}

  login(credentials: { username: string; password: string }): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}`, credentials)
      .pipe(
        tap(response => {
          const expirationDate = new Date(new Date().getTime() + this.tokenExpirationTime);
          localStorage.setItem('token', response.token);
          localStorage.setItem('userId', response.username);
          localStorage.setItem('tokenExpiration', expirationDate.toISOString());
        })
      );
  }

  logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('tokenExpiration');
  }

  isLoggedIn(): boolean {
    const token = localStorage.getItem('token');
    const tokenExpiration = localStorage.getItem('tokenExpiration');

    if (!token || !tokenExpiration) {
      return false;
    }

    const expirationDate = new Date(tokenExpiration);
    if (expirationDate <= new Date()) {
      this.logout();
      return false;
    }

    return true;
  }

  getToken(): string | null {
    if (this.isLoggedIn()) {
      return localStorage.getItem('token');
    } else {
      return null;
    }
  }
}
