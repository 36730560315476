export enum SortValueEnum {
  TotalAmountAsc = "total_amount",
  TotalAmountDesc = "-total_amount",
  TotalDurationAsc = "total_duration",
  TotalDurationDesc = "-total_duration",
}
export enum SortValueFlightEnum {
  Price_High_to_Low = "Price_High_to_Low",
  Price_Low_to_High = "Price_Low_to_High",
  Best_Price = "Best_Price",
}
export enum SortValueHotelEnum {
  Price_High_to_Low = "Price_High_to_Low",
  Price_Low_to_High = "Price_Low_to_High",
  Best_Price = "Best_Price",
}
