import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  NavigationExtras,
  ParamMap,
  Params,
  Router,
} from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { first, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
//TODO: complete all types
export class RouterService {
  private isPaid = new BehaviorSubject<boolean>(false);
  isPaid$ = this.isPaid.asObservable();
  private isFilterOptions = new BehaviorSubject<boolean>(false);
  isFilterOptions$ = this.isFilterOptions.asObservable();
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  navigate(route: string): Promise<boolean> {
    return this.router.navigate([route], { relativeTo: this.activatedRoute });
  }

  navigateAndAddHiddenParamsToUrl(
    queryParams: Record<any, any>,
    shouldBeMerged = true,
    redirectRoute: string
  ): Promise<boolean> {
    //In order to include any type of keys - enums/ etc...
    return this.router.navigate([redirectRoute], {
      relativeTo: this.activatedRoute,
      state: {
        data: queryParams,
      },
      queryParamsHandling: shouldBeMerged ? "merge" : "",
      replaceUrl: false,
    });
  }

  navigateAndAddParamsToUrl(
    queryParams: Record<any, any>,
    shouldBeMerged = true,
    redirectRoute: string
  ): Promise<boolean> {
    //In order to include any type of keys - enums/ etc...
    return this.router.navigate([redirectRoute], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: shouldBeMerged ? "merge" : "",
      replaceUrl: false,
    });
  }

  getUrlParams(): Observable<Params> {
    return this.activatedRoute.queryParamMap.pipe(
      first((res: ParamMap) => !!res),
      map((currentQueryParams: ParamMap) => {
        const { params: currentParams } = (currentQueryParams as Params) || {};

        return currentParams;
      })
    );
  }

  detectUrlParamsChanges(): Observable<Params> {
    return this.activatedRoute.queryParams;
  }

  getUrlStateData(): NavigationExtras | undefined {
    return this.router.getCurrentNavigation()?.extras?.state;
  }
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
  scrollToSection(sectionId?: string) {
    const targetElement = sectionId
      ? document.getElementById(sectionId)
      : document.body;

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  setCheckoutState(value: boolean){
    this.isPaid.next(value);
  }
  setFilterState(value: boolean){
    this.isFilterOptions.next(value);
  }
}
