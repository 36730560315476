import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tic-coupon-card',
  templateUrl: './coupon-card.component.html',
  styleUrls: ['./coupon-card.component.scss']
})
export class CouponCardComponent {
  @Input() code: string;
  @Input() backgroundColor:string;
  @Input() cardName:string='Gift Card';
  @Input() discountValue:string='25';
  @Input() discountType:boolean=true;
  @Input() isActive:boolean=true;
  @Output() EditCoupon = new EventEmitter();
  @Output() DeleteCoupon = new EventEmitter();


  Edit(){
    this.EditCoupon.emit();
  }

  Delete(){
    this.DeleteCoupon.emit();
  }
  
}
