import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'tic-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements OnInit {
  @Input() datesFormGroup!: FormGroup;
  minDate: Date;

  constructor() {}
  
  ngOnInit(): void {
    this.minDate = new Date();
  }
}
