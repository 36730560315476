import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel } from '@angular/forms';
import { DateRange, MatDateRangeInput } from '@angular/material/datepicker';
@Component({
  selector: 'tic-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss']
})
export class DateSelectorComponent {
  @ViewChild('startDateInput') startDateInput: ElementRef;
  @ViewChild('endDateInput') endDateInput: ElementRef;
  @Input() selectedFrom: any;
  @Input() selectedTo: any;
  @Output() range = new EventEmitter<string>();
  selectedOption: string = 'none';
  dateObj: any;
  dateOptions = [
    { value: 'none', viewValue: 'dashboard.filters.dateFilter.none' },
    { value: 'today', viewValue: 'dashboard.filters.dateFilter.today' },
    { value: 'yesterday', viewValue: 'dashboard.filters.dateFilter.yesterday' },
    { value: 'last7days', viewValue: 'dashboard.filters.dateFilter.last7Days' },
    { value: 'last30days', viewValue: 'dashboard.filters.dateFilter.last30Days' },
    { value: 'thisMonth', viewValue: 'dashboard.filters.dateFilter.thisMonth' },
    { value: 'lastMonth', viewValue: 'dashboard.filters.dateFilter.lastMonth' },
    { value: 'custom', viewValue: 'dashboard.filters.dateFilter.custom' }
  ];
  validRange: boolean = true;
  constructor(public datePipe: DatePipe) {}
  
  ngOnInit() {
    this.setDefaultFilters();    
  }

  setDefaultFilters() {
    if( this.selectedFrom && this.selectedTo ) {
      this.selectedOption = this.get_date_label(this.selectedFrom, this.selectedTo);
    }
  }

  ngAfterViewInit(){
    if( this.selectedFrom && this.selectedTo && this.selectedOption === 'custom' ) {
      this.startDateInput.nativeElement.value = this.selectedFrom;
      this.endDateInput.nativeElement.value = this.selectedTo;
    }
  }

  optionSelected(option: any) {
    if( option === 'none' ) {
      this.range.emit('none');
      return;
    }
    const today = new Date();
    switch (option) {
      case 'today':
        this.dateObj = {
          startDate: today,
          endDate: today
        }
        break;
      case 'yesterday':
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        this.dateObj = {
          startDate: yesterday,
          endDate: yesterday
        }
        break;
      case 'last7days':
        const lastWeekStart = new Date(today);
        lastWeekStart.setDate(today.getDate() - 6);
        this.dateObj = {
          startDate: lastWeekStart,
          endDate: today
        }
        break;
      case 'last30days':
        const lastMonthStart = new Date(today);
        lastMonthStart.setDate(today.getDate() - 29);
        this.dateObj = {
          startDate: lastMonthStart,
          endDate: today
        }
        break;
      case 'thisMonth':
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        this.dateObj = {
          startDate: startOfMonth,
          endDate: today
        }
        break;
      case 'lastMonth':
        const previousMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const previousMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
        this.dateObj = {
          startDate: previousMonthStart,
          endDate: previousMonthEnd
        }
        break;
      // Handle other predefined options similarly
      default:
        break;
    }
    if( option != 'custom' ) {
      this.dateObj.startDate = this.formatDate(this.dateObj.startDate);
      this.dateObj.endDate = this.formatDate(this.dateObj.endDate);
      this.range.emit(this.dateObj);
    }
  }

  get_date_label(from_date: Date, to_date: Date): string {
    const today: Date = new Date();
    today.setHours(0, 0, 0, 0);

    from_date = new Date(from_date);
    from_date.setHours(0, 0, 0, 0);

    to_date = new Date(to_date);
    to_date.setHours(0, 0, 0, 0);

    if (from_date.getTime() === today.getTime() && to_date.getTime() === today.getTime()) {
      return 'today';
    }

    const yesterday: Date = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    if (from_date.getTime() === yesterday.getTime() && to_date.getTime() === yesterday.getTime()) {
      return 'yesterday';
    }

    const lastWeekStart: Date = new Date(today);
    lastWeekStart.setDate(today.getDate() - 6);
    lastWeekStart.setHours(0, 0, 0, 0);

    if (from_date.getTime() === lastWeekStart.getTime() && to_date.getTime() === today.getTime()) {
      return 'last7days';
    }

    const lastMonthStart: Date = new Date(today);
    lastMonthStart.setDate(today.getDate() - 29);
    lastMonthStart.setHours(0, 0, 0, 0);

    if (from_date.getTime() === lastMonthStart.getTime() && to_date.getTime() === today.getTime()) {
      return 'last30days';
    }

    const startOfMonth: Date = new Date(today.getFullYear(), today.getMonth(), 1);
    startOfMonth.setHours(0, 0, 0, 0);

    if (from_date.getTime() === startOfMonth.getTime() && to_date.getTime() === today.getTime()) {
      return 'thisMonth';
    }

    const previousMonthStart: Date = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const previousMonthEnd: Date = new Date(today.getFullYear(), today.getMonth(), 0);
    previousMonthStart.setHours(0, 0, 0, 0);
    previousMonthEnd.setHours(0, 0, 0, 0);

    if (from_date.getTime() === previousMonthStart.getTime() && to_date.getTime() === previousMonthEnd.getTime()) {
      return 'lastMonth';
    }

    return 'custom';
  }

  onDateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement): void {
    const validStartDate = new Date(dateRangeStart.value);
    const validEndDate = new Date(dateRangeEnd.value);

    if( validStartDate > validEndDate  ) {
      this.validRange = false;
    } else {
      this.validRange = true;
      const startDate = this.datePipe.transform(dateRangeStart.value, 'yyyy-MM-dd');
      const endDate = this.datePipe.transform(dateRangeEnd.value, 'yyyy-MM-dd');
      this.dateObj = {
        startDate: startDate,
        endDate: endDate
      }
      this.range.emit(this.dateObj);
    }
  }

  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  resetInput(){
    this.selectedOption ='none';
    this.optionSelected('none');
  }
}
