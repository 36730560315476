import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartTypeComponent } from './chart-type.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { I18nModule } from 'src/app/core/languages-support/i18n.module';
import { AppSupportedLanguages } from 'src/app/core/languages-support/i18n-config.service';



@NgModule({
  declarations: [ChartTypeComponent],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatInputModule,
    MatSelectModule,
    I18nModule.forRoot(AppSupportedLanguages),
  ],
  exports: [
    ChartTypeComponent,
  ]
})
export class ChartTypeModule { }
