<div class="signup-section desktop">
    <div class="image-col">
        <img src="../../../../../assets/images/login.png">
    </div>
    <div class="signup-form">
        <div class="form-col">
            <h2>Sign Up for an Account</h2>
            <p>Welcome to the website</p>
            <tic-signup-form></tic-signup-form>
        </div>
    </div>
</div>
<div class="signup-section tablet">
    <h1 class="title">Book your next journey</h1>
    <p class="description">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
        when an unknown printer took a galley of type </p>
    <div class="form-col">
        <h2>Sign Up for an Account</h2>
        <p>Welcome to the website</p>
        <tic-signup-form></tic-signup-form>
    </div>
</div>