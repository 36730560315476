export enum PagesRoute {
  AdminDashboard = "dashboard",
  Signup = "register",
  LoginPage = "login",
  HomePage = "home-page",
  EventPage = "event-page",
  FlightsPage = "flights-page",
  HotelsPage = "hotels",
  PaymentPage = "payment-page",
  PageNotFound = "page-not-found",
  TripSummary = "trip-summary",
  TripCheckout = "trip-checkout",
  TeamPage = "team-page",
  ErrorPage = "error-page"
}
