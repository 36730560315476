import { Inject, Injectable } from '@angular/core';
import get from 'lodash-es/get';
import { i18nConfigService } from './i18n-config.service';
import { Language } from './config/enums/languages.enums';
import { AppSupportedLanguagesInterface } from './config/interfaces/app-supported-languages.interface';
@Injectable(
//   { providedIn: 'root' }
)
export class I18nService {

  currentLanguage: string | null = '';
  currentLanguageConfigFile!: Record<string, string>;

  constructor(
    @Inject(i18nConfigService) public langList: AppSupportedLanguagesInterface[],
  ) {
    !this.currentLanguageConfigFile && this.initLang();
  }

  initLang(): void {
    if (localStorage.getItem('lang')) {
      this.currentLanguage = localStorage.getItem('lang');
      var languageIndex = this.langList.findIndex((e: AppSupportedLanguagesInterface) => e.name === this.currentLanguage);
    } else {
      localStorage.setItem('lang', Language.English);
      this.currentLanguage = Language.English;
    }
    if (languageIndex !== -1) {  
      this.currentLanguageConfigFile = this.langList[this.langList
        .map((e: AppSupportedLanguagesInterface, i: any) => e.name === this.currentLanguage ? i : null)
        .filter((e: AppSupportedLanguagesInterface) => e !== null)[0]].file;
    }else{
      console.error(`Language not found in langList: ${this.currentLanguage}`);
    }
  }

  getString(key: string): string {
    this.initLang();
    const translatedString = get(this.currentLanguageConfigFile, key, '');
    !translatedString && console.error(`Failed to transfer: key - ${key}, language - ${this.currentLanguage}`);

    return translatedString;
  }

  languageChange(): void {
    this.initLang()
  }
}
