import { AppSupportedCurrencyInterface } from "../interfaces/currency-interface";

export const AppSupportedCurrencies: AppSupportedCurrencyInterface[] = [
    {
        name: "USD",
        isDefault: true,
        sign: "$",
        src: "../../../../../assets/images/usd.png",
        selected: false
      },
      {
        name: "GBP",
        isDefault: false,
        sign: "€",
        src: "../../../../../assets/images/gbp.png",
        selected: false
      },
      {
        name: "EUR",
        isDefault: false,
        sign: "$",
        src: "../../../../../assets/images/eur.png",
        selected: false
      },
      {
        name: "CAD",
        isDefault: false,
        sign: "$",
        src: "../../../../../assets/images/ca-flag.jpg",
        selected: false
      },
      {
        name: "ILS",
        isDefault: false,
        sign: "$",
        src: "../../../../../assets/images/ils.png",
        selected: false
      },
      {
        name: "NOK",
        isDefault: false,
        sign: "$",
        src: "../../../../../assets/images/nok.png",
        selected: false
      },
      {
        name: "JPY",
        isDefault: false,
        sign: "$",
        src: "../../../../../assets/images/japan.png",
        selected: false
      },
      {
        name: "CNY",
        isDefault: false,
        sign: "$",
        src: "../../../../../assets/images/china.webp",
        selected: false
      },
      {
        name: "KRW",
        isDefault: false,
        sign: "$",
        src: "../../../../../assets/images/sk.webp",
        selected: false
      }
  ];