import { Injectable } from "@angular/core";
import { Observable,Subject,filter,first, takeUntil} from "rxjs";
import { SortValueEnum } from "src/app/libs/ui/components/top-bar/enums/top-bar.enum";
import {
  FlightsViewModel,
  IFlightsFacade,
  makeFlightsViewModel,
} from "../model";
import { FlightsQuery } from "../services/flights.query";
import { IFilter } from "src/app/libs/ui/interfaces/filters.interface";
import { FlightsFiltersMetaData } from "../model/consts/flight.filters.const";
import { IFiltersMetadata } from "src/app/libs/data-access/store/shared";

import { Actions, ofType } from "@ngrx/effects";
import { ResetState } from "src/app/actions/global.actions";

@Injectable({
  providedIn: "root",
})

export class FlightsFacade implements IFlightsFacade {
  readonly flights$: Observable<any> = this.flightsQuery.flights$; //TODO: Change to Flights interface;
  readonly active$: Observable<any> = this.flightsQuery.active$; //TODO: Change to Flights interface;
 
  readonly offerRequestId$: Observable<string> =
    this.flightsQuery.offerRequestId$;

  readonly filtersMetadata$: Observable<IFiltersMetadata> = this.flightsQuery.filtersMetadata$;
  
  readonly requestMetadata$: Observable<any> =
    this.flightsQuery.requestMetadata$;

  readonly sort$: Observable<SortValueEnum> = this.flightsQuery.sort$;
  
  readonly totalRecordsLeft$: Observable<number> =
    this.flightsQuery.totalRecordsLeft$;

  readonly totalRecords$: Observable<number> =
    this.flightsQuery.totalRecords$;

  readonly selectedQuickFilter$: Observable<any> =
    this.flightsQuery.selectedQuickFilter$;

  readonly loadMoreActions$: Observable<any> =
    this.flightsQuery.loadMoreActions$;

  readonly loading$: Observable<boolean> = this.flightsQuery.loading$;
  readonly loaded$: Observable<boolean> = this.flightsQuery.loaded$;
  readonly errors$: Observable<any> = this.flightsQuery.errors$; //TODO: Change to Flights interface
  vm$!: Observable<FlightsViewModel>;
  constructor(private flightsQuery: FlightsQuery,) { 
    this.getFlightsViewModel(); 
    this.saveFilterConfiguration(FlightsFiltersMetaData);
  }

  ngOnInit() {
    this.saveFilterConfiguration(FlightsFiltersMetaData);
  }

  private getFlightsViewModel(){
    this.vm$ = makeFlightsViewModel([
      this.flights$,
      this.active$,
      this.offerRequestId$,
      this.filtersMetadata$,
      this.requestMetadata$,
      this.sort$,
      this.totalRecordsLeft$,
      this.totalRecords$,
      this.selectedQuickFilter$,
      this.loadMoreActions$,
      this.loading$,
      this.loaded$,
      this.errors$,
    ]);
  }
  
  loadFilterOptions(requestQuery: any): void {
    //TODO: complete type
    this.getFlightsViewModel(); 
    this.saveFilterConfiguration(FlightsFiltersMetaData);
    this.flightsQuery.loadFilterOptions(requestQuery);
  }

  saveFilterConfiguration(filtersMetadata: IFilter<any>[]): void {
    //TODO: complete type
    this.flightsQuery.saveFilterConfiguration(filtersMetadata);
  }

  loadInitialFlightData(requestQuery: any): void {
    //TODO: complete type
    this.flightsQuery.loadInitialFlightData(requestQuery);
  }

  loadMoreFlights(requestQuery: any): void {
    //TODO: Complete type
    this.flightsQuery.loadMoreFlights(requestQuery);
  }
  
  sortAndFilterFlights(sort: any, filter: any): void {
    let requestQuery: any = {};
    //TODO: Complete type
    this.requestMetadata$.pipe(first( res => res)).subscribe((res) => {
      requestQuery = JSON.parse(JSON.stringify(res));
      requestQuery.filters = filter;
      requestQuery.flightsCriteria.metadata.page = 1;
      requestQuery.flightsCriteria.searchParams.sort = sort;
    });
    this.flightsQuery.loadInitialFlightData(requestQuery);
    // this.flightsQuery.loadMoreFlights(requestQuery);
  }

  setQuickFilter(selectedQuickFilter: any): void {
    this.flightsQuery.setQuickFilter(selectedQuickFilter);
  }

}
