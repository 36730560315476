<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <p *ngIf="formError" class="text-danger">{{ formError }}</p>
    <div class="field">
        <input type="text" id="username" formControlName="username" placeholder="username">
        <div *ngIf="loginForm.get('username').invalid && (loginForm.get('username').dirty || loginForm.get('username').touched)" class="invalid-feedback">
            <small>Username is required.</small>
        </div>
    </div>
    <div class="field">
        <input type="password" id="password" formControlName="password" placeholder="Password">
        <div *ngIf="loginForm.get('password').invalid && (loginForm.get('password').dirty || loginForm.get('password').touched)" class="invalid-feedback">
            <small>Password is required.</small>
        </div>
    </div>
    <div class="btn-group">
        <button type="submit" [disabled]="!loginForm.valid">Sign in</button>
        <!-- <a href="#">Sign up</a> -->
    </div>
</form>