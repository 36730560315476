import { EntityState as NgrxEntityState } from "@ngrx/entity";
import { IFilter } from "src/app/libs/ui/interfaces/filters.interface";

export declare type ID = number | string;

export interface EntityState<T = any> extends NgrxEntityState<T> {
  loadMoreActions?: ILoadActions;
  loading: boolean;
  loaded: boolean;
  errorMessage?: string;
  error?: any;
}

export interface ActiveState<T = ID> {
  active: T | null;
}

export interface MultiActiveState<T = ID> {
  active: T[];
}

export interface ILoadActions {
  loading: boolean;
  loaded: boolean;
  error: any;
}

export interface ILoadActionsWithData<T = any> extends ILoadActions {
  data: T;
}

export type IFiltersMetadata = ILoadActionsWithData<IFilter<any>[] | null>;


export const InitialLoadActions: ILoadActions = {
  loading: false,
  loaded: false,
  error: null,
}

export const InitialLoadActionsWithData: ILoadActionsWithData<any> = {
  data: null,
  ...InitialLoadActions,
}
