import { Injectable } from "@angular/core";
import { Observable,first } from "rxjs";
import { SortValueEnum } from "src/app/libs/ui/components/top-bar/enums/top-bar.enum";
import { EventsViewModel, IEventsFacade, makeEventsViewModel } from "../model";
import { EventsQuery } from "./events.query";
import {
  IFilter,
} from "src/app/libs/ui/interfaces/filters.interface";
import { EventsFiltersMetaData } from "./events.filters.config";
import { IFiltersMetadata } from "src/app/libs/data-access/store/shared";


@Injectable({
  providedIn: "root",
})
export class EventsFacade implements IEventsFacade {

  readonly events$: Observable<any> = this.eventsQuery.events$; //TODO: Change to events interface;
  readonly flights$: Observable<any> = this.eventsQuery.flights$;
  readonly hotels$: Observable<any> = this.eventsQuery.hotels$;
  readonly ids$: Observable<any> = this.eventsQuery.ids$; //TODO: Change to events interface;
  readonly filtersMetadata$: Observable<IFiltersMetadata> = this.eventsQuery.filtersMetadata$;
  readonly totalLeftToFetch$: Observable<number> =
    this.eventsQuery.totalLeftToFetch$;
  readonly totalRecords$: Observable<number> =
    this.eventsQuery.totalRecords$;
  readonly selectedFilters$: Observable<any> =
    this.eventsQuery.selectedFilters$;
  readonly selectedQuickFilter$: Observable<any> =
    this.eventsQuery.selectedQuickFilter$;
  readonly sort$: Observable<SortValueEnum> = this.eventsQuery.sort$;
  readonly active$: Observable<any[]> = this.eventsQuery.active$;
  readonly loading$: Observable<boolean> = this.eventsQuery.loading$;
  readonly loaded$: Observable<boolean> = this.eventsQuery.loaded$;
  readonly loadMoreActions$: Observable<any> = this.eventsQuery.loadMoreAction$;
  readonly errors$: Observable<any> = this.eventsQuery.errors$; //TODO: Change to Events interface
  vm$!: Observable<EventsViewModel>;
  constructor(private eventsQuery: EventsQuery,
    ) {    
    this.getEventsViewModel();
    this.saveFilterConfiguration(EventsFiltersMetaData);
  }

private getEventsViewModel(){
    this.vm$ = makeEventsViewModel([
    this.events$,
    this.flights$,
    this.hotels$,
    this.active$,
    this.ids$,
    this.totalLeftToFetch$,
    this.totalRecords$,
    this.filtersMetadata$,
    this.sort$,
    this.selectedFilters$,
    this.selectedQuickFilter$,
    this.loadMoreActions$,
    this.loading$,
    this.loaded$,
    this.errors$,
  ]);
}

  loadFilterOptions(requestQuery: any): void {
    //TODO: complete type
    this.getEventsViewModel();
    this.saveFilterConfiguration(EventsFiltersMetaData);
    this.eventsQuery.loadFilterOptions(requestQuery);
  }

  saveFilterConfiguration(metadata: IFilter<any>[]): void {
    //TODO: complete type
    this.eventsQuery.saveFilterConfiguration(metadata);
  }

  setSelectedFilters(selectedFilters: any, reqQuery: any, flightHotelCriteria: any, sorting: any): void {
    //TODO: complete type
    this.eventsQuery.setSelectedFilters(selectedFilters);

    let eventRequest = JSON.parse(JSON.stringify(reqQuery));
    eventRequest.metadata.page = 1;

    this.selectedFilters$.pipe(first( res => !!res)).subscribe((response) => {
      this.loadFilteredEventData({
        eventsCriteria: eventRequest,
        flightsCriteria: flightHotelCriteria.flightsCriteria,
        hotelsCriteria: flightHotelCriteria.hotelsCriteria,
        packageMetadata: flightHotelCriteria.packageMetadata,
        sort: sorting,
        filters: { ...response },
        sportType: flightHotelCriteria.sportType,
      });
    });
  }

  loadInitialEventData(requestQuery: any): void {
    //TODO: complete type
    this.eventsQuery.loadInitialEventData({ requestQuery });
  }

  loadFilteredEventData(requestQuery: any): void {
    //TODO: complete type
    this.eventsQuery.loadFilteredEventData(requestQuery);
  }

  loadMoreEvents(requestQuery: any): void {
    //TODO: Complete type
    this.eventsQuery.loadMoreEvents(requestQuery);
  }

  setQuickFilter(selectedQuickFilter: any): void {
    this.eventsQuery.setQuickFilter(selectedQuickFilter);
  }  
  
}
