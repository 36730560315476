import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaveViewComponent } from './save-view.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppSupportedLanguages } from 'src/app/core/languages-support/i18n-config.service';
import { I18nModule } from 'src/app/core/languages-support/i18n.module';



@NgModule({
  declarations: [SaveViewComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule.forRoot(AppSupportedLanguages),
  ],
  exports: [
    SaveViewComponent
  ]
})
export class SaveViewModule { }
