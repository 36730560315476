import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DashboardFacade } from '../../../data-access/services/dashboard.facade';

@Component({
  selector: 'tic-event-table',
  templateUrl: './event-table.component.html',
  styleUrls: ['./event-table.component.scss']
})
export class EventTableComponent {
  @Output() close = new EventEmitter<boolean>();
  @Input() order :any;

  constructor(public dashboardFacade: DashboardFacade) {
  }

  closePopup() {
    this.close.emit(true);
  }

}
