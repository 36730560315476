import { FilterType, ValueType } from "src/app/libs/ui/enums/fiters.enums";
import {IFilter} from "src/app/libs/ui/interfaces/filters.interface";

export const FlightsFiltersMetaData: IFilter<any>[] = [
  {
    type: FilterType.Slider,
    displayName: "price",
    id: "price", // Assuming a unique identifier for this filter
    active: true,
    expanded: true,
    options: [
      {
        min: {
          defaultValue: 0,
          type: ValueType.Number,
          value: null,
          id: null,
        },
        max: {
          defaultValue: 5000,
          type: ValueType.Number,
          value: null,
        },
      },
    ],
  },
  {
    type: FilterType.Checkbox,
    displayName: "No of Stops",
    id: "stops", // Assuming a unique identifier for this filter
    active: true,
    expanded: false,
    options: [{ name: "", options: [] }],
  },
  {
    type: FilterType.Slider,
    displayName: "Outbound",
    id: "outbound", // Assuming a unique identifier for this filter
    active: true,
    expanded: false,
    options: [
      {
        min: {
          defaultValue: 0,
          type: ValueType.Time,
          value: null,
          displayName: "Departure",
          id: "departure",
        },
        max: {
          defaultValue: 50,
          type: ValueType.Time,
          value: null,
        },
      },
      {
        min: {
          defaultValue: 0,
          type: ValueType.Time,
          value: null,
          displayName: "Arrival",
          id: "arrival",
        },
        max: {
          defaultValue: 50,
          type: ValueType.Time,
          value: null,
        },
      },
    ],
  },
  {
    type: FilterType.Slider,
    displayName: "Inbound",
    id: "inbound", // Assuming a unique identifier for this filter
    active: true,
    expanded: false,
    options: [
      {
        min: {
          defaultValue: 0,
          type: ValueType.Time,
          value: null,
          displayName: "Departure",
          id: "departure",
        },
        max: {
          defaultValue: 50,
          type: ValueType.Time,
          value: null,
        },
      },
      {
        min: {
          defaultValue: 0,
          type: ValueType.Time,
          value: null,
          displayName: "Arrival",
          id: "arrival",
        },
        max: {
          defaultValue: 50,
          type: ValueType.Time,
          value: null,
        },
      },
    ],
  },
  {
    type: FilterType.Slider,
    displayName: "Duration",
    id: "duration", // Assuming a unique identifier for this filter
    active: true,
    expanded: false,
    options: [
      {
        min: {
          defaultValue: 0,
          type: ValueType.Duration,
          value: null,
          displayName: "",
          id: null,
        },
        max: {
          defaultValue: 50,
          type: ValueType.Duration,
          value: null,
        },
      },
    ],
  },
  {
    type: FilterType.Checkbox,
    displayName: "Airlines",
    id: "airline", // Assuming a unique identifier for this filter
    active: true,
    expanded: false,
    options: [{ name: "", options: [] }],
  },
];
