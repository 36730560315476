import { ChangeDetectorRef, Component, ElementRef, Inject, LOCALE_ID, OnDestroy } from "@angular/core";
import { CurrencyFacade } from "src/app/libs/data-access/services/currency/services/currency.facade";
import { LocalStorageUtil } from "../../utils/local-storage.utils";
import { CURRENT_CURRENCY_TYPE_LOCAL_STORAGE_KEY } from "src/app/libs/data-access/services/currency/consts/currency.consts";
import { Subject, takeUntil } from "rxjs";
import { AppSupportedCurrencies } from "src/app/libs/data-access/models/consts/app-supported-currencies.const";
@Component({
  selector: "tic-currency-panel",
  templateUrl: "./currency-panel.component.html",
  styleUrls: ["./currency-panel.component.scss"],
})
export class CurrencyPanelComponent implements OnDestroy {
  currenciesData = AppSupportedCurrencies;
  currencies: any;
  private readonly storageKey = 'currencyConverter';
  currentCurrency: any;
  previousCurrency: any;
  openCurr:boolean=false;
  private destroy$ = new Subject<void>();

  constructor(private currencyFacade: CurrencyFacade,private cdr: ChangeDetectorRef,private elementRef: ElementRef) {}

  ngOnInit() {
    this.setInitialState();
  }

  openCurrency(){
    this.openCurr=!this.openCurr
    if (this.openCurr) {
      document.addEventListener('click', this.closePopupOutside);
    } else {
      document.removeEventListener('click', this.closePopupOutside);
    }
  }

  setInitialState() {
    this.currentCurrency = this.currenciesData.find(currency => currency.isDefault);
    this.previousCurrency = this.currentCurrency;
    localStorage.setItem(this.storageKey, this.previousCurrency);
    const currentCurrencyType = LocalStorageUtil.getOption(CURRENT_CURRENCY_TYPE_LOCAL_STORAGE_KEY);
    if( currentCurrencyType === undefined ) {
    LocalStorageUtil.setOption(CURRENT_CURRENCY_TYPE_LOCAL_STORAGE_KEY, 'USD');
    }
    this.currencyFacade.getExchangeRate();
    this.currencyFacade.vm$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if ( data && data.exchangeRate ) {
        this.currencies = {
          USD: 1.0,
          ...data.exchangeRate
        }
      }
    });
    this.setSelectedCurrency();
  }

  setSelectedCurrency() {
    const selectedCurrency = localStorage.getItem('currentCurrencyType').replace(/"/g, '');
    this.currenciesData = this.currenciesData.map(currency => {
      const name = currency.name.toString();
      if (name.normalize() == selectedCurrency.normalize()) {
          return {
              ...currency,
              selected: true
          };
      } else {
        return {
          ...currency,
          selected: false
        };
      }
    });
  }

  currencySelected(currency: any){
    this.previousCurrency = this.currentCurrency;
    this.currentCurrency = currency;
    localStorage.setItem(this.storageKey, this.previousCurrency);
    LocalStorageUtil.setOption(CURRENT_CURRENCY_TYPE_LOCAL_STORAGE_KEY, this.currentCurrency.name);
    this.currencyFacade.setCurrentCurrency(this.currentCurrency.name);
    this.setSelectedCurrency();
    this.openCurr = false;
    this.cdr.markForCheck();
  }

  closePopup() {
    this.openCurr = false;
    document.removeEventListener('click', this.closePopupOutside);
  }

  closePopupOutside = (event: MouseEvent) => {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.closePopup();
    }
  }

  onDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }
}