import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { QueryBase } from "src/app/libs/data-access/store/shared/query-base.interface";
import { CheckoutState } from "../../../models";
import {
  CheckoutSelectors,
  checkoutFeatureSelector,
} from "../store/checkout.selectors";
import { CheckoutStateActions } from "../store/checkout.actions";
import { CheckoutService } from "./checkout.service";

@Injectable({
  providedIn: "root",
})
export class CheckoutQuery implements QueryBase {
  events$ = this.CheckoutStore.select(CheckoutSelectors.getEvents);
  flight$ = this.CheckoutStore.select(CheckoutSelectors.getFlight);
  hotel$ = this.CheckoutStore.select(CheckoutSelectors.getHotel);
  room$ = this.CheckoutStore.select(CheckoutSelectors.getRoom);
  passangerDetail$ = this.CheckoutStore.select(
    CheckoutSelectors.getPassangerDetail
  );
  orderInfo$ = this.CheckoutStore.select(CheckoutSelectors.getOrderInfo);
  ids$ = this.CheckoutStore.select(CheckoutSelectors.getIds);
  totalPrice$ = this.CheckoutStore.select(CheckoutSelectors.getTotalPrice);
  packagePrice$ = this.CheckoutStore.select(CheckoutSelectors.getPackagePrice);
  eventPrice$ = this.CheckoutStore.select(CheckoutSelectors.getEventPrice);
  flightPrice$ = this.CheckoutStore.select(CheckoutSelectors.getFlightPrice);
  hotelPrice$ = this.CheckoutStore.select(CheckoutSelectors.getHotelPrice);
  loading$ = this.CheckoutStore.select(CheckoutSelectors.getLoading);
  loaded$ = this.CheckoutStore.select(CheckoutSelectors.getLoaded);
  errors$ = this.CheckoutStore.select(CheckoutSelectors.getErrors);
  saveEventOrderInfo$ = this.CheckoutStore.select(
    CheckoutSelectors.getSaveEventOrderInfo
  );
  eventOrderInfo$ = this.CheckoutStore.select(CheckoutSelectors.getEventOrder);
  savHotelOrderInfo$ = this.CheckoutStore.select(
    CheckoutSelectors.getSaveHotelOrderInfo
  );
  hotelOrderInfo$ = this.CheckoutStore.select(CheckoutSelectors.getHotelOrder);
  saveFlightOrderInfo$ = this.CheckoutStore.select(
    CheckoutSelectors.getSaveFlightOrderInfo
  );
  flightOrderInfo$ = this.CheckoutStore.select(
    CheckoutSelectors.getFlightOrder
  );
  saveOrderInfo$ = this.CheckoutStore.select(
    CheckoutSelectors.getSaveOrderInfo
  );
  preSearchEvents$ = this.CheckoutStore.select(CheckoutSelectors.preSearchEvents);
  preSearchFlight$ = this.CheckoutStore.select(CheckoutSelectors.preSearchFlight);
  preSearchHotel$ = this.CheckoutStore.select(CheckoutSelectors.preSearchHotel);
  eventOrdersDetailInfo$ = this.CheckoutStore.select(CheckoutSelectors.getEventOrdersDetail);
  saveMultipleEventOrderInfo$ = this.CheckoutStore.select(CheckoutSelectors.getSaveMultipleEventOrderInfo);
  getCouponDetail$ = this.CheckoutStore.select(CheckoutSelectors.getCouponDetail);

  constructor(private CheckoutStore: Store<CheckoutState>, private checkoutService: CheckoutService) {}

  setEvents(events: any[]): void {
    this.CheckoutStore.dispatch(CheckoutStateActions.setEventData({ events }));
  }

  setFlight(flight: any): void {
    this.CheckoutStore.dispatch(CheckoutStateActions.setFlightData({ flight }));
  }

  setHotel(hotel: any): void {
    this.CheckoutStore.dispatch(CheckoutStateActions.setHotelData({ hotel }));
  }

  setTotalPrice(totalPrice: number): void {
    this.CheckoutStore.dispatch(
      CheckoutStateActions.setTotalPrice({ totalPrice })
    );
  }

  setRoom(room: any) {
    this.CheckoutStore.dispatch(CheckoutStateActions.setRoom({ room }));
  }

  setPackagePrice(packagePrice: number) {
    this.CheckoutStore.dispatch(
      CheckoutStateActions.setPackagePrice({ packagePrice })
    );
  }

  setEventPrice(eventPrice: number) {
    this.CheckoutStore.dispatch(
      CheckoutStateActions.setEventPrice({ eventPrice })
    );
  }

  setFlightPrice(flightPrice: number) {
    this.CheckoutStore.dispatch(
      CheckoutStateActions.setFlightPrice({ flightPrice })
    );
  }

  setHotelPrice(hotelPrice: number) {
    this.CheckoutStore.dispatch(
      CheckoutStateActions.setHotelPrice({ hotelPrice })
    );
  }
  setPassangerDetail(passangerDetail: any) {
    this.CheckoutStore.dispatch(
      CheckoutStateActions.setPassangerDetail({ passangerDetail })
    );
  }
  resetSaveOrderInfo(): void {
    this.CheckoutStore.dispatch(
      CheckoutStateActions.resetSaveOrderInfo()
    );
  }
  saveOrder(orderDetail: any): void {
    this.CheckoutStore.dispatch(
      CheckoutStateActions.saveOrder({ orderDetail })
    );
  }
  resetOrderInfo(): void {
    this.CheckoutStore.dispatch(
      CheckoutStateActions.resetOrderInfo()
    );
  }
  getOrder(orderRequest: any): void {
    this.CheckoutStore.dispatch(
      CheckoutStateActions.getOrder({ orderRequest })
    );
  }
  resetSaveEventOrderInfo(): void {
    this.CheckoutStore.dispatch(
      CheckoutStateActions.resetSaveEventOrderInfo()
    );
  }
  saveEventOrder(orderRequest: any): void {
    this.CheckoutStore.dispatch(
      CheckoutStateActions.saveEventOrder({ orderRequest })
    );
  }
  saveMultipleEventOrder(orderRequest: any): void {
    this.CheckoutStore.dispatch(
      CheckoutStateActions.saveMultipleEventsOrder({ orderRequest })
    );
  }
  getEventOrder(orderRequest: any): void {
    this.CheckoutStore.dispatch(
      CheckoutStateActions.getEventOrder({ orderRequest })
    );
  }
  resetSaveHotelOrderInfo(): void {
    this.CheckoutStore.dispatch(
      CheckoutStateActions.resetSaveHotelOrderInfo()
    );
  }
  saveHotelOrder(orderRequest: any): void {
    this.CheckoutStore.dispatch(
      CheckoutStateActions.saveHotelOrder({ orderRequest })
    );
  }
  getHotelOrder(orderRequest: any): void {
    this.CheckoutStore.dispatch(
      CheckoutStateActions.getHotelOrder({ orderRequest })
    );
  }
  resetSaveFlightOrderInfo(): void {
    this.CheckoutStore.dispatch(
      CheckoutStateActions.resetSaveFlightOrderInfo()
    );
  }
  saveFlightOrder(orderRequest: any): void {
    this.CheckoutStore.dispatch(
      CheckoutStateActions.saveFlightOrder({ orderRequest })
    );
  }
  getFlightOrder(orderRequest: any): void {
    this.CheckoutStore.dispatch(
      CheckoutStateActions.getFlightOrder({ orderRequest })
    );
  }

  getPreSearchedEvent(requestQuery: any):void{
    this.CheckoutStore.dispatch(
      CheckoutStateActions.getPreSearchedEvent({requestQuery})
    );
  }

  getPreSearchedFlight(requestQuery: any):void{
    this.CheckoutStore.dispatch(
      CheckoutStateActions.getPreSearchedFlight({requestQuery})
    );
  }

  getPreSearchedHotel(requestQuery: any):void{
    this.CheckoutStore.dispatch(
      CheckoutStateActions.getPreSearchedHotel({requestQuery})
    );
  }

  getEventOrdersDetail(requestQuery: any):void{
    this.CheckoutStore.dispatch(
      CheckoutStateActions.getEventOrdersDetail({requestQuery})
    );
  }
  getCouponDetail(requestQuery: any):void{
    this.CheckoutStore.dispatch(
      CheckoutStateActions.getCouponDetail({requestQuery})
    );
  }
  updateCouponCount(requestQuery: any):void{
    this.CheckoutStore.dispatch(
      CheckoutStateActions.updateCouponCount({requestQuery})
    );
  }
}
