import { Component } from '@angular/core';
import { DashboardFacade } from '../../../data-access/services/dashboard.facade';

@Component({
  selector: 'tic-single-hotel',
  templateUrl: './single-hotel.component.html',
  styleUrls: ['./single-hotel.component.scss']
})
export class SingleHotelComponent {
  
  constructor(public dashboardFacade: DashboardFacade){}
}
