import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, of } from "rxjs";
import { environment } from "src/app/core/environments/environment";
import { FlightsServiceRoutes } from "src/app/pages/flights-page/src/app/data-access/services/flights.routes";

@Injectable({
  providedIn: "root",
})
export class CheckoutService {
  constructor(private HttpClient: HttpClient) {}
  getOrder(orderRequest: any) {
    return this.HttpClient.post(
      `${environment.ms_orders_base_url}/orders/getOrder`,
      orderRequest
    ).pipe(map((res: any) => res));
  }
  createOrders(orderRequestInfo: any) {
    return this.HttpClient.post(
      `${environment.ms_orders_base_url}/orders/create`,
      orderRequestInfo
    ).pipe(map((res: any) => res));
  }

  //event order
  saveEventOrder(orderRequest: any) {
    return this.HttpClient.post(
      `${environment.ms_events_base_url}/book/orderTickets`,
      orderRequest
    ).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  saveAllEventOrder(orderRequest: any) {
    return this.HttpClient.post(
      `${environment.ms_events_base_url}/book/createEventsOrder`,
      orderRequest
    ).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getEventOrder(orderRequest: any) {
    return this.HttpClient.post(
      `${environment.ms_events_base_url}/book/getOrder`,
      orderRequest
    ).pipe(map((res: any) => res));
  }

  getEventOrdersDetail(orderRequest: any) {
    return this.HttpClient.post(
      `${environment.ms_events_base_url}/book/getOrdersByIds`,
      orderRequest
    ).pipe(map((res: any) => res));
  }

  // Hotel Order
  hotelBooking(orderRequest: any) {
    return this.HttpClient.post(
      `${environment.ms_hotels_base_url}/hotelBooking`,
      orderRequest
    ).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
  getHotelOrder(orderRequest: any) {
    return this.HttpClient.post(
      `${environment.ms_hotels_base_url}/get-booking`,
      orderRequest
    ).pipe(map((res: any) => res));
  }
  saveFlightOrder(orderRequest: any) {
    return this.HttpClient.post(
      `${environment.ms_flights_base_url}/${FlightsServiceRoutes.Flight_Search}/create-order`,
      orderRequest
    ).pipe(map((res: any) => res));
  }
  getFlightOrder(orderRequest: any) {
    return this.HttpClient.get(
      `${environment.ms_flights_base_url}/flight-search/get-order/${orderRequest.orderId}`
    ).pipe(map((res: any) => res));
  }

  getPreSearchedEvent(EventsRequest: any) {
    //TODO: complete types and get it as props
    return this.HttpClient.post(
      `${environment.ms_events_base_url}/search/pre-searched`,
      EventsRequest.requestQuery
    ).pipe(map((res: any) => res));
  }

  getPreSearchedFlight(FlightsRequest: any) {
    //TODO: complete types and get it as props
    return this.HttpClient.post(
      `${environment.ms_flights_base_url}/flight-search/search/pre-searched`,
      FlightsRequest.requestQuery
    ).pipe(map((res: any) => res));
  }

  getPreSearchedHotel(HotelsRequest: any) {
    //TODO: complete types and get it as props
    return this.HttpClient.post(
      `${environment.ms_hotels_base_url}/pre-searched-hotel`,
      HotelsRequest.requestQuery
    ).pipe(map((res: any) => res));
  }

  getCouponDetail(orderRequest: any) {
    return this.HttpClient.post(
      `${environment.ms_admin_base_url}/coupon/check`,
      orderRequest
    ).pipe(map((res: any) => res));
  }

  updateCouponCount(orderRequest: any) {
    return this.HttpClient.post(
     `${environment.ms_admin_base_url}/coupon/usage`,
      orderRequest
    ).pipe(map((res: any) => res));
  }
}
