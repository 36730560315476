import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CurrencyViewModel, ICurrencyFacade, makeCurrencyViewModel } from "../../../models";
import { CurrencyQuery } from "./currency.query";


@Injectable({
  providedIn: 'root'
})
export class CurrencyFacade implements ICurrencyFacade {
  exchangeRate$: Observable<any> = this.currencyQuery.exchangeRate$;
  currentCurrency$: Observable<any> = this.currencyQuery.currentCurrency$;
  to$: Observable<any> = this.currencyQuery.to$;
  from$: Observable<any> = this.currencyQuery.from$;
  loading$: Observable<boolean> = this.currencyQuery.loading$; 
  loaded$: Observable<boolean> = this.currencyQuery.loaded$;
  errors$: Observable<any> = this.currencyQuery.errors$;
  vm$!: Observable<CurrencyViewModel>;

  constructor(
    private currencyQuery: CurrencyQuery,
  ) {
    this.vm$ = makeCurrencyViewModel([
      this.exchangeRate$,
      this.to$,
      this.from$,
      this.loading$,
      this.loaded$,
      this.errors$
    ]);
    
  }

  getExchangeRate(): void {
    this.currencyQuery.getExchangeRate();
  }

  setCurrentCurrency(name: string): void {
    this.currencyQuery.setExchange(name);
  }
}
