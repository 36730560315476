<div class="evt-popup">
    <div class="evt-container">
        <div class="close-popup" (click)="closePopup()">
            <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g _ngcontent-nbx-c129="" opacity="0.5">
                <path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path _ngcontent-nbx-c129="" d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g>
            </svg>
        </div>
        <h3 class="font-weight-bold">Save View</h3>       
        <div class="popup-body">
            <div class="form">
                <form [formGroup]="myForm" (ngSubmit)="submitForm()">
                    <div>
                      <input placeholder="Enter View Name" type="text" id="title" name="title" formControlName="viewName" required>
                    </div>
                    <button type="submit" [disabled]="!myForm.valid">Save View</button>
                </form>
            </div>
        </div>
    </div>
</div>