import { Component } from '@angular/core';
import { DashboardFacade } from '../../../data-access/services/dashboard.facade';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'tic-single-order',
  templateUrl: './single-order.component.html',
  styleUrls: ['./single-order.component.scss']
})
export class SingleOrderComponent {
  
  constructor(public dashboardFacade: DashboardFacade,private route: ActivatedRoute) { 
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if( params['orderId'] ) {
        const id = params['orderId'];
        this.dashboardFacade.getOrderDetail(id);
      }
    });
  }

  isObjectNotEmpty(obj: any): boolean {
    return obj && Object.keys(obj).length > 0;
  }
}
