import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyDataAccessModule } from 'src/app/libs/data-access/services/currency';
import { CurrencyPanelComponent } from './currency-panel.component';



@NgModule({
  declarations: [CurrencyPanelComponent],
  imports: [
    CommonModule,
    CurrencyDataAccessModule
  ],
  exports: [
    CurrencyPanelComponent
  ]
})
export class CurrencyPanelModule { }
