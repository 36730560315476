import { Action, createReducer, on } from "@ngrx/store";
import { CheckoutState } from "../../../models";
import { CheckoutStateActions } from "./checkout.actions";
import { checkoutAdapter } from "./checkout.adapter";
import { ResetState } from "src/app/actions/global.actions";

export const initialCheckoutState: CheckoutState =
  checkoutAdapter.getInitialState({
    events: [],
    flight: {},
    hotel: {},
    room: {},
    entities: {},
    ids: [],
    totalPrice: 0,
    packagePrice: 0,
    eventPrice: 0,
    flightPrice: 0,
    hotelPrice: 0,
    loading: false,
    loaded: false,
    error: null,
    active: [],
    searchParams: {},
    passangerDetail: {},
    orderInfo: {},
    saveOrderInfo: {},
    saveEventOrderInfo: {},
    eventOrderInfo: {},
    eventOrdersDetailInfo:{},
    saveHotelOrderInfo: {},
    hotelOrderInfo: {},
    saveFlightOrderInfo: {},
    flightOrderInfo: {},
    preSearchEvents: [],
    preSearchFlight: {},
    preSearchHotel: {},
    saveMultipleEventOrderInfo:[],
    couponDetail:{}
  });

export const CheckoutStateFeatureKey = "CheckoutStateFeatureKey";

export const checkoutReducer = createReducer(
  initialCheckoutState,
  on(CheckoutStateActions.setEventData, (state, action) => {
    const { events } = action || {};
    return {
      ...state,
      events,
      loading: false,
      loaded: true,
      error: false,
    };
  }),
  on(CheckoutStateActions.setFlightData, (state, action) => {
    const { flight } = action || {};
    return {
      ...state,
      flight,
      loading: false,
      loaded: true,
      error: false,
    };
  }),
  on(CheckoutStateActions.setHotelData, (state, action) => {
    const { hotel } = action || {};
    return {
      ...state,
      hotel,
      loading: false,
      loaded: true,
      error: false,
    };
  }),
  on(CheckoutStateActions.setRoom, (state, action) => {
    const { room } = action || {};
    return {
      ...state,
      room,
      loading: false,
      loaded: true,
      error: false,
    };
  }),
  on(CheckoutStateActions.setTotalPrice, (state, action) => {
    const { totalPrice } = action || {};
    return {
      ...state,
      totalPrice,
      loading: false,
      loaded: true,
      error: false,
    };
  }),
  on(CheckoutStateActions.setPackagePrice, (state, action) => {
    const { packagePrice } = action || {};
    return {
      ...state,
      packagePrice,
      loading: false,
      loaded: true,
      error: false,
    };
  }),
  on(CheckoutStateActions.setEventPrice, (state, action) => {
    const { eventPrice } = action || {};
    return {
      ...state,
      eventPrice,
      loading: false,
      loaded: true,
      error: false,
    };
  }),
  on(CheckoutStateActions.setFlightPrice, (state, action) => {
    const { flightPrice } = action || {};
    return {
      ...state,
      flightPrice,
      loading: false,
      loaded: true,
      error: false,
    };
  }),
  on(CheckoutStateActions.setHotelPrice, (state, action) => {
    const { hotelPrice } = action || {};
    return {
      ...state,
      hotelPrice,
      loading: false,
      loaded: true,
      error: false,
    };
  }),
  on(CheckoutStateActions.setSearchParams, (state, action) => {
    const { searchParams } = action || {};
    return {
      ...state,
      searchParams,
      loading: false,
      loaded: true,
      error: false,
    };
  }),
  on(CheckoutStateActions.setPassangerDetail, (state, action) => {
    const { passangerDetail } = action || {};
    return {
      ...state,
      passangerDetail,
      loading: false,
      loaded: true,
      error: false,
    };
  }),
  on(CheckoutStateActions.resetSaveOrderInfo, (state, action) => {
    return {
      ...state,
      saveOrderInfo:{},
      loading: false,
      loaded: true,
      error: false,
    };
  }),
  on(CheckoutStateActions.saveOrder, (state, action) => {
    return {
      ...state,
      saveOrderInfo: state.saveOrderInfo,
      loading: true,
      loaded: false,
      error: null,
    };
  }),

  on(CheckoutStateActions.saveOrderSuccess, (state, action) => {
    const { saveOrderInfo } = action.data || {};
    return {
      ...state,
      saveOrderInfo: saveOrderInfo,
      loading: false,
      loaded: true,
      error: null,
    };
  }),

  on(CheckoutStateActions.saveOrderFailure, (state, action) => {
    const { error } = action || {};
    //TODO: add errors
    return {
      ...state,
      saveOrderInfo: null,
      loading: false,
      loaded: true,
      error,
    };
  }),

  on(CheckoutStateActions.resetOrderInfo, (state, action) => {
    return {
      ...state,
      orderInfo:{},
      loading: false,
      loaded: true,
      error: false,
    };
  }),

  on(CheckoutStateActions.getOrder, (state, action) => {
    return {
      ...state,
      orderInfo: state.orderInfo,
      loading: true,
      loaded: false,
      error: null,
    };
  }),

  on(CheckoutStateActions.getOrderSuccess, (state, action) => {
    const { orderInfo } = action.data || {};
    return {
      ...state,
      orderInfo: orderInfo,
      loading: false,
      loaded: true,
      error: null,
    };
  }),

  on(CheckoutStateActions.getOrderFailure, (state, action) => {
    const { error } = action || {};
    //TODO: add errors
    return {
      ...state,
      orderInfo: null,
      loading: false,
      loaded: true,
      error,
    };
  }),
  on(CheckoutStateActions.resetSaveEventOrderInfo, (state, action) => {
    return {
      ...state,
      saveMultipleEventOrderInfo:[],
      loading: false,
      loaded: true,
      error: false,
    };
  }),

  on(CheckoutStateActions.saveEventOrder, (state, action) => {
    return {
      ...state,
      saveEventOrderInfo: state.saveEventOrderInfo,
      loading: true,
      loaded: false,
      error: null,
    };
  }),

  on(CheckoutStateActions.saveEventOrderSuccess, (state, action) => {
    const { saveEventOrderInfo } = action.data || {};
    return {
      ...state,
      saveEventOrderInfo: saveEventOrderInfo,
      loading: false,
      loaded: true,
      error: null,
    };
  }),

  on(CheckoutStateActions.saveEventOrderFailure, (state, action) => {
    const { error } = action || {};
    //TODO: add errors
    return {
      ...state,
      saveOrderInfo: null,
      loading: false,
      loaded: true,
      error,
    };
  }),

  on(CheckoutStateActions.saveMultipleEventsOrder, (state, action) => {
    return {
      ...state,
      saveMultipleEventOrderInfo: state.saveMultipleEventOrderInfo,
      loading: true,
      loaded: false,
      error: null,
    };
  }),

  on(CheckoutStateActions.saveMultipleEventsOrderSuccess, (state, action) => {
    const { saveMultipleEventOrderInfo } = action.data || {};
    return {
      ...state,
      saveMultipleEventOrderInfo: saveMultipleEventOrderInfo,
      loading: false,
      loaded: true,
      error: null,
    };
  }),

  on(CheckoutStateActions.saveMultipleEventsOrderFailure, (state, action) => {
    const { error } = action || {};
    //TODO: add errors
    return {
      ...state,
      saveMultipleEventOrderInfo: [],
      loading: false,
      loaded: true,
      error,
    };
  }),


  on(CheckoutStateActions.getEventOrder, (state, action) => {
    return {
      ...state,
      eventOrderInfo: state.eventOrderInfo,
      loading: true,
      loaded: false,
      error: null,
    };
  }),

  on(CheckoutStateActions.getEventOrderSuccess, (state, action) => {
    const { eventOrderInfo } = action.data || {};
    return {
      ...state,
      eventOrderInfo: eventOrderInfo,
      loading: false,
      loaded: true,
      error: null,
    };
  }),

  on(CheckoutStateActions.getEventOrdersDetail, (state, action) => {
    return {
      ...state,
      eventOrdersDetailInfo: state.eventOrdersDetailInfo,
      loading: true,
      loaded: false,
      error: null,
    };
  }),

  on(CheckoutStateActions.getEventOrdersDetailSuccess, (state, action) => {
    const { eventOrdersDetailInfo } = action.data || {};
    return {
      ...state,
      eventOrdersDetailInfo: eventOrdersDetailInfo,
      loading: false,
      loaded: true,
      error: null,
    };
  }),

  on(CheckoutStateActions.getEventOrdersDetailFailure, (state, action) => {
    const { error } = action || {};
    //TODO: add errors
    return {
      ...state,
      saveOrderInfo: null,
      loading: false,
      loaded: true,
      error,
    };
  }),

  on(CheckoutStateActions.resetSaveHotelOrderInfo, (state, action) => {
    return {
      ...state,
      saveHotelOrderInfo:{},
      loading: false,
      loaded: true,
      error: false,
    };
  }),

  on(CheckoutStateActions.saveHotelOrder, (state, action) => {
    return {
      ...state,
      saveHotelOrderInfo: state.saveHotelOrderInfo,
      loading: true,
      loaded: false,
      error: null,
    };
  }),

  on(CheckoutStateActions.saveHotelOrderSuccess, (state, action) => {
    const { saveHotelOrderInfo } = action.data || {};
    return {
      ...state,
      saveHotelOrderInfo: saveHotelOrderInfo,
      loading: false,
      loaded: true,
      error: null,
    };
  }),

  on(CheckoutStateActions.saveHotelOrderFailure, (state, action) => {
    const { error } = action || {};
    //TODO: add errors
    return {
      ...state,
      orderInfo: null,
      loading: false,
      loaded: true,
      error,
    };
  }),
  
  on(CheckoutStateActions.getHotelOrder, (state, action) => {
    return {
      ...state,
      hotelOrderInfo: state.hotelOrderInfo,
      loading: true,
      loaded: false,
      error: null,
    };
  }),

  on(CheckoutStateActions.getHotelOrderSuccess, (state, action) => {
    const { hotelOrderInfo } = action.data || {};
    return {
      ...state,
      hotelOrderInfo: hotelOrderInfo,
      loading: false,
      loaded: true,
      error: null,
    };
  }),

  on(CheckoutStateActions.getHotelOrderFailure, (state, action) => {
    const { error } = action || {};
    //TODO: add errors
    return {
      ...state,
      hotelOrderInfo: null,
      loading: false,
      loaded: true,
      error,
    };
  }),
  on(CheckoutStateActions.resetSaveFlightOrderInfo, (state, action) => {
    return {
      ...state,
      saveFlightOrderInfo:{},
      loading: false,
      loaded: true,
      error: false,
    };
  }),
  on(CheckoutStateActions.saveFlightOrder, (state, action) => {
    return {
      ...state,
      saveFlightOrderInfo: state.saveFlightOrderInfo,
      loading: true,
      loaded: false,
      error: null,
    };
  }),

  on(CheckoutStateActions.saveFlightOrderSuccess, (state, action) => {
    const { saveFlightOrderInfo } = action.data || {};
    return {
      ...state,
      saveFlightOrderInfo: saveFlightOrderInfo,
      loading: false,
      loaded: true,
      error: null,
    };
  }),

  on(CheckoutStateActions.saveFlightOrderFailure, (state, action) => {
    const { error } = action || {};
    //TODO: add errors
    return {
      ...state,
      orderInfo: null,
      loading: false,
      loaded: true,
      error,
    };
  }),
  on(CheckoutStateActions.getFlightOrder, (state, action) => {
    return {
      ...state,
      flightOrderInfo: state.flightOrderInfo,
      loading: true,
      loaded: false,
      error: null,
    };
  }),

  on(CheckoutStateActions.getFlightOrderSuccess, (state, action) => {
    const { flightOrderInfo } = action.data || {};
    return {
      ...state,
      flightOrderInfo: flightOrderInfo,
      loading: false,
      loaded: true,
      error: null,
    };
  }),

  on(CheckoutStateActions.getFlightOrderFailure, (state, action) => {
    const { error } = action || {};
    //TODO: add errors
    return {
      ...state,
      flightOrderInfo: null,
      loading: false,
      loaded: true,
      error,
    };
  }),

  on(ResetState, (state:any,action:any) =>{
    
    return {
      ...initialCheckoutState,
      filtersMetadata: {
        data: [],
        loading: false,
        loaded: false,
        error: null,
      },
    }
  }),

  on(CheckoutStateActions.getPreSearchedEvent, (state, action) => {
    const { requestQuery } = action;
    return {
      ...state,
      requestMetadata: requestQuery,
      loadMoreActions: {
        loading: true,
        loaded: false,
        error: null,
      },
    };
  }),

  on(CheckoutStateActions.getPreSearchedEventSuccess, (state, action) => {
    const { events } = action.data;
    return {
      ...state,
      preSearchEvents: events,      
      loadMoreActions: {
        loading: false,
        loaded: true,
        error: null,
      },
    };
  }),

  on(CheckoutStateActions.getPreSearchedEventFailure, (state, action) => {
    //TODO: add errors
    const { error } = action;

    return {
      ...state,
      loadMoreActions: {
        loading: false,
        loaded: true,
        error,
      },
    };
  }),
  on(CheckoutStateActions.getPreSearchedFlight, (state, action) => {
    const { requestQuery } = action;

    return {
      ...state,
      requestMetadata: requestQuery,
      loadMoreActions: {
        loading: true,
        loaded: false,
        error: null,
      },
    };
  }),

  on(CheckoutStateActions.getPreSearchedFlightSuccess, (state, action) => {
    const { flight } = action.data;
    return {
      ...state,
      preSearchFlight: flight,      
      loadMoreActions: {
        loading: false,
        loaded: true,
        error: null,
      },
    };
  }),

  on(CheckoutStateActions.getPreSearchedFlightFailure, (state, action) => {
    //TODO: add errors
    const { error } = action;

    return {
      ...state,
      loadMoreActions: {
        loading: false,
        loaded: true,
        error,
      },
    };
  }),
  on(CheckoutStateActions.getPreSearchedHotel, (state, action) => {
    const { requestQuery } = action;

    return {
      ...state,
      requestMetadata: requestQuery,
      loadMoreActions: {
        loading: true,
        loaded: false,
        error: null,
      },
    };
  }),

  on(CheckoutStateActions.getPreSearchedHotelSuccess, (state, action) => {
    const { hotel } = action.data;
    return {
      ...state,
      preSearchHotel: hotel,      
      loadMoreActions: {
        loading: false,
        loaded: true,
        error: null,
      },
    };
  }),

  on(CheckoutStateActions.getPreSearchedHotelFailure, (state, action) => {
    //TODO: add errors
    const { error } = action;

    return {
      ...state,
      loadMoreActions: {
        loading: false,
        loaded: true,
        error,
      },
    };
  }),

  on(CheckoutStateActions.getCouponDetail, (state, action) => {
    const { requestQuery } = action;

    return {
      ...state,
      requestMetadata: requestQuery,
      loadMoreActions: {
        loading: true,
        loaded: false,
        error: null,
      },
    };
  }),

  on(CheckoutStateActions.getCouponDetailSuccess, (state, action) => {
    const { couponDetail } = action.data;
    return {
      ...state,
      couponDetail: couponDetail,      
      loadMoreActions: {
        loading: false,
        loaded: true,
        error: null,
      },
    };
  }),

  on(CheckoutStateActions.getCouponDetailFailure, (state, action) => {
    //TODO: add errors
    const { error } = action;

    return {
      ...state,
      loadMoreActions: {
        loading: false,
        loaded: true,
        error,
      },
    };
  }),

  on(CheckoutStateActions.updateCouponCount, (state, action) => {
    const { requestQuery } = action;

    return {
      ...state,
      requestMetadata: requestQuery,
      loadMoreActions: {
        loading: true,
        loaded: false,
        error: null,
      },
    };
  }),

  on(CheckoutStateActions.updateCouponCountSuccess, (state, action) => {
    return {
      ...state,    
      loadMoreActions: {
        loading: false,
        loaded: true,
        error: null,
      },
    };
  }),

  on(CheckoutStateActions.updateCouponCountFailure, (state, action) => {
    //TODO: add errors
    const { error } = action;

    return {
      ...state,
      loadMoreActions: {
        loading: false,
        loaded: true,
        error,
      },
    };
  }),
);

export function CheckoutReducer(
  state: CheckoutState | undefined,
  action: Action
) {
  return checkoutReducer(state, action);
}
