import { Action, createReducer, on } from '@ngrx/store';
import { CurrencyState } from '../../../models';
import { currencyStateActions } from './currency.actions';
import { CurrencyAdapter } from './currency.adapter';
import { LocalStorageUtil } from 'src/app/libs/ui/utils/local-storage.utils';
import { CURRENCY_RATES_LOCAL_STORAGE_KEY, CURRENT_CURRENCY_TYPE_LOCAL_STORAGE_KEY } from '../consts/currency.consts';

export const initialCurrencyState: CurrencyState = CurrencyAdapter.getInitialState({
  exchangeRate: {},
  to: {},
  from: {},
  loading: false,
  loaded: false,
  error: null,
  criteria: {},
  currentCurrency: '',
});

export const CurrencyStateFeatureKey = 'CurrencyStateFeatureKey';

export const currencyReducer = createReducer(
  initialCurrencyState,
  on(currencyStateActions.getExchangeRate, (state, action) => {
    return {
      ...state
    };
  }),
  on(currencyStateActions.exchangeRateSuccess, (state, action) => {
    const { exchangeRate, to, from } = action.data || {};
    const data = action.data;

    LocalStorageUtil.setOption(CURRENCY_RATES_LOCAL_STORAGE_KEY, data);

    return {
      ...state,
      ...data,
    };
  }),

  on(currencyStateActions.setCurrentCurrency, (state, action) => {
    const { currency } = action || {};

    LocalStorageUtil.setOption(CURRENT_CURRENCY_TYPE_LOCAL_STORAGE_KEY, currency);

    return {
      ...state,
      currentCurrency: currency,
    };
  }),
);

export function CurrencyReducer(state: CurrencyState | undefined, action: Action) {
  return currencyReducer(state, action);
}
