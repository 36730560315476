import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DashboardFacade } from '../../../data-access/services/dashboard.facade';

@Component({
  selector: 'tic-flight-table',
  templateUrl: './flight-table.component.html',
  styleUrls: ['./flight-table.component.scss']
})
export class FlightTableComponent {
  @Output() close = new EventEmitter<boolean>();
  @Input() order :any;
  constructor(public dashboardFacade: DashboardFacade) {
  }

  closePopup() {
    this.close.emit(true);
  }
}
