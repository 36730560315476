import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DashboardFacade } from 'src/app/pages/admin-dashboard/data-access/services/dashboard.facade';

@Component({
  selector: 'tic-save-view',
  templateUrl: './save-view.component.html',
  styleUrls: ['./save-view.component.scss']
})
export class SaveViewComponent {
  @Output() close = new EventEmitter<boolean>();
  @Output() saveView = new EventEmitter<boolean>();
  pushSuccess = false;
  myForm: FormGroup;
  pushMessage: any;

  constructor(private formBuilder: FormBuilder,public dashboardFacade: DashboardFacade) { }

  ngOnInit(): void {
    this.myForm = this.formBuilder.group({
      viewName: ['', [Validators.required]]
    });
  }

  isObjectNotEmpty(obj: any): boolean {
    return obj && Object.keys(obj).length > 0;
  }
  
  closePopup() {
    this.close.emit(true);
  }

  submitForm() {
    if (this.myForm.valid) {
      this.saveView.emit(this.myForm.value);
    } else {
      console.error('Form is invalid.');
    }
  }
}
