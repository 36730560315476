import { NgModule } from '@angular/core';
import { ButtonComponent } from './button.component';
import { I18nModule } from 'src/app/core/languages-support/i18n.module';
import { AppSupportedLanguages } from 'src/app/core/languages-support/i18n-config.service';

@NgModule({
  declarations: [
    ButtonComponent,
  ],
  imports: [
    I18nModule.forRoot(AppSupportedLanguages),
  ],
  exports: [
    ButtonComponent,

  ]
})
export class ButtonModule { }
